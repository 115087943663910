import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {INotificationItem} from '@interfaces/notification/notification.interface';

@Component({
  selector: 'alm-root-header-notification-menu-organization-note-card',
  templateUrl: './header-notification-menu-organization-note-card.component.html',
  styleUrls: ['./header-notification-menu-organization-note-card.component.scss']
})
export class HeaderNotificationMenuOrganizationNoteCardComponent implements OnInit {

  @Input() notification?: INotificationItem;
  @Output() markAsRead: EventEmitter<boolean> = new EventEmitter<boolean>();
  isSelected: boolean = false;
  dateToday = new Date();

  constructor() {
  }

  ngOnInit(): void {
  }

  onNotificationMenuClosing() {
    this.isSelected = false;
  }

  markNotificationAsRead(): void {
    this.markAsRead.emit(true);
  }
}
