import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'alm-root-sub-footer',
  templateUrl: './sub-footer.component.html',
  styleUrls: ['./sub-footer.component.scss']
})
export class SubFooterComponent implements OnInit {

  @Input() showSocial: boolean = true;
  @Input() isB2bUser: boolean = false;
  today: Date = new Date();

  constructor() { }

  ngOnInit(): void {
  }

}
