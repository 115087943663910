import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {TranslateModule} from '@ngx-translate/core';
import {AlmTranslateCutModule} from '@alm-translate-cut/alm-translate-cut.module';
import { SubscriptionCardComponent } from './subscription-card.component';


@NgModule({
  declarations: [SubscriptionCardComponent],
    exports: [
        SubscriptionCardComponent
    ],
  imports: [
    CommonModule,
    TranslateModule,
    AlmTranslateCutModule
  ]
})
export class SubscriptionCardModule {
}
