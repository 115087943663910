import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HorizontalCourseCardSwiperComponent } from './horizontal-course-card-swiper.component';
import {HorizontalCourseCardModule} from "@components/horizontal-course-card/horizontal-course-card.module";
import SwiperCore, {Controller, Lazy, Navigation, Pagination} from "swiper";

SwiperCore.use([Navigation, Lazy, Controller, Pagination]);

@NgModule({
  declarations: [HorizontalCourseCardSwiperComponent],
  exports: [
    HorizontalCourseCardSwiperComponent
  ],
  imports: [
    CommonModule,
    HorizontalCourseCardModule
  ]
})
export class HorizontalCourseCardSwiperModule { }
