<div class="header-nav-menu">
  <nav class="col d-none d-lg-block p-0">
    <ul>
      <!-- CUSTOM CONTENT: {{customContentService.getCustomContent()}} -->
      <li *ngIf="!(customContentService.hasCustomContent$ | async)" #coursesLink (click)="$event.stopPropagation();$event.preventDefault();openCoursesMenu(coursesMenuTrigger)"
          (mouseenter)="onButtonEnter(coursesMenuTrigger, coursesLink)"
          (mouseleave)="onButtonLeave(coursesMenuTrigger, coursesLink)" style="z-index:1050">
        <!--      <li #coursesLink (click)="$event.stopPropagation();$event.preventDefault();openCoursesMenu(coursesMenuTrigger)">-->
        <a [matMenuTriggerFor]="coursesMenu" #coursesMenuTrigger="matMenuTrigger" [dir]="menuDirection"
           (menuClosed)="onCoursesMenuClosed()" (menuOpened)="onCoursesMenuOpen()">
          {{'header_nav_menu.item' | translate | translateCut: 0}}
          <svg>
            <use href="assets/images/sprite.svg#arrow-down"/>
          </svg>
        </a>
        <mat-menu  #coursesMenu="matMenu" yPosition="below" [xPosition]="menuPosition"
                  [backdropClass]="userInfo && userInfo?.subscribed && userInfo?.subscriptionStatus === 3 ? 'courses-menu__ribbon' : 'courses-menu'">
          <span (mouseenter)="menuMouseEnter()" (mouseleave)="menuMouseLeave(coursesMenuTrigger, coursesLink)">
            <ng-template matMenuContent>
              <alm-root-courses-menu-v2 [isB2bUser]="isB2BUser" [currentLang]="currentLang"></alm-root-courses-menu-v2>
<!--            <alm-root-courses-menu [isB2bUser]="isB2BUser"></alm-root-courses-menu>-->
          </ng-template>
          </span>
        </mat-menu>
      </li>

      
      <li *ngIf="!(customContentService.hasCustomContent$ | async)">
        <a routerLink="/mentors">{{'header_nav_menu.item' | translate | translateCut: 3}}</a></li>
      <!--      <li *ngIf="userSubscribe && !isB2BUser">-->
      <!--        <a routerLink="/profile">{{'header_nav_menu.item' | translate | translateCut: 4}}</a>-->
      <!--      </li>-->

      <!--? custom content only  -->
      <li *ngIf="customContentService.hasCustomContent$ | async">
        <a routerLink="/courses">{{'header_nav_menu.item' | translate | translateCut: 0}}</a>
      </li>

      <li *ngIf="(userInfo && userInfo.subscribed) || isB2BUser">
        <a routerLink="/my-progress">{{'header_nav_menu.item' | translate | translateCut: 5}}</a>
      </li>
      <li [ngStyle]="{ display: ((!userInfo || (userInfo && !userInfo.subscribed)) && !isB2BUser) ? 'block' : 'none'}">
        <a class="btn btn-alm-outline header-nav-menu-subscribe" routerLink="/subscription-plans">
          {{'header_nav_menu.item' | translate | translateCut: 2}}</a>
      </li>
    </ul>
  </nav>
</div>
