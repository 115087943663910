<div class="auth-content">
  <div class="auth-header">
    <h5 class="auth-title text-center text-capitalize">
      {{'verify_email.page_title' | translate}}
    </h5>
  </div>
  <div class="auth-body">
    <div class="w-100 text-center">
      <div class="verify-email-img">
      </div>
    </div>
    <p class="text-center verify-body-color">
      {{'verify_email.description' | translate}}
    </p>
  </div>
  <div class="auth-footer">
    <p class="text-center verify-body-color">
      {{'verify_email.no_response' | translate | translateCut: 0}}
      <button *ngIf="canSend" (click)="resendActivation()" class="font-weight-bold btn btn-link link-underline resend-email-button">
        {{'verify_email.no_response' | translate | translateCut: 1}}</button>
      <span *ngIf="!canSend">{{timer}}</span>
    </p>
  </div>
</div>
