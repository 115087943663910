import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SubscriptionExpiryDialogContainerComponent } from './subscription-expiry-dialog-container.component';
import { SubscriptionExpiryComponent } from './subscription-expiry/subscription-expiry.component';
import {MatDialogModule} from "@angular/material/dialog";
import {RouterModule} from "@angular/router";
import {TranslateModule} from "@ngx-translate/core";
import {AlmTranslateCutModule} from '@alm-translate-cut/alm-translate-cut.module';



@NgModule({
    declarations: [SubscriptionExpiryDialogContainerComponent, SubscriptionExpiryComponent],
    exports: [
        SubscriptionExpiryDialogContainerComponent
    ],
  imports: [
    CommonModule,
    MatDialogModule,
    RouterModule,
    TranslateModule,
    AlmTranslateCutModule
  ]
})
export class SubscriptionExpiryDialogContainerModule { }
