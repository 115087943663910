<div class="footer-app">
  <div class="footer-app_upper">
    <h5>{{'footer.footer_app_download' | translate}}</h5>
    <div class="store-links">
      <a href="https://play.google.com/store/apps/details?id=com.almentor.app">
      <img
        loading="lazy"
        src="assets/images/play-store.svg"
        alt="Download on the App Store"
      /></a>
      <a href="https://apps.apple.com/eg/app/almentor/id1645104637">
      <img loading="lazy" alt="Get it on Google Play" src="assets/images/app-store.svg"
      /></a>
    </div>
  </div>
</div>
