<div class="header-mobile-menu">
  <div class="header-mobile-menu__auth-link"  *ngIf="!userInfo">
    <div class="header-mobile-menu__signup-wrapper" *ngIf="!userInfo">
      <a class="signup-link" *ngIf="!external"
         [routerLink]="signupLink"
         [trackingClickEvent]="{ name: TrackingEventName.SIGNUP_BUTTON_CLICKED }"
         [navigationEvent]="true"
         (click)="itemClicked()">
        <svg class=" signup-icon">
          <use xlink:href="assets/images/sprite.svg#add-user"/>
        </svg>
        {{'header_mobile_menu.login_links' | translate | translateCut:0}}
      </a>
      <a class="signup-link" *ngIf="external"
         [trackingClickEvent]="{ name: TrackingEventName.SIGNUP_BUTTON_CLICKED }"
         [navigationEvent]="true"
         (action)="onNavigationToSignUp()"
         (click)="itemClicked()">
        <svg class="arrow signup-icon">
          <use xlink:href="assets/images/sprite.svg#add-user"/>
        </svg>
        {{'header_mobile_menu.login_links' | translate | translateCut:0}}
      </a>
    </div>
    <div class="header-mobile-menu__vertical-separator"></div>
    <div class="header-mobile-menu__signup-wrapper" *ngIf="!userInfo">
<!--      <span>-->
<!--        {{'header_mobile_menu.login_links' | translate | translateCut:1}}-->
<!--      </span>-->
      <a class="signup-link" *ngIf="!external" [routerLink]="loginLink"
         [trackingClickEvent]="{ name: TrackingEventName.LOGIN_BUTTON_CLICKED }"
         [navigationEvent]="true"
         (click)="itemClicked()">
        <svg class="arrow">
          <use xlink:href="assets/images/sprite.svg#login"/>
        </svg>
        {{'header_mobile_menu.login_links' | translate | translateCut:2}}
      </a>
      <a class="signup-link"
         *ngIf="external"
         [trackingClickEvent]="{ name: TrackingEventName.LOGIN_BUTTON_CLICKED }"
         [navigationEvent]="true"
         (action)="navigateToLogin()"
         (click)="itemClicked()">
        <svg class="arrow">
          <use xlink:href="assets/images/sprite.svg#login"/>
        </svg>
        {{'header_mobile_menu.login_links' | translate | translateCut:2}}
      </a>
    </div>
  </div>
  <div class="header-mobile-menu__top-section-wrapper" *ngIf="currentView == 1">
    <div class="header-mobile-menu__upper-navigation-links">
      <a class="header-mobile-menu__upper-navigation-links-link" *ngIf="!(customContentService.hasCustomContent$ | async)" (click)="switchToCoursesListView()">
        <span>{{'header_mobile_menu.navigation_links' | translate | translateCut:0}}</span>
        <svg class="arrow">
          <use href="assets/images/sprite.svg#arrow-down"/>
        </svg>
      </a>
      <a class="header-mobile-menu__upper-navigation-links-link" *ngIf="customContentService.hasCustomContent$ | async" routerLink="/courses">
        <span>{{'header_mobile_menu.navigation_links' | translate | translateCut:0}}</span>
  
      </a>
      <a class="header-mobile-menu__upper-navigation-links-link b2b-link" (click)="switchToB2bCategoriesView()" *ngIf="isB2bUser && currentLanguage && organizationNames">
        <span>
          <span *ngIf="currentLanguage.code === 'ar'">
            {{'header_mobile_menu.navigation_links' | translate | translateCut:7}}
            {{organizationNames.nameAr}}
          </span>
          <span *ngIf="currentLanguage.code === 'en'">
            {{organizationNames.nameEn}}
            {{'header_mobile_menu.navigation_links' | translate | translateCut:7}}</span>
        </span>
        <svg class="arrow">
          <use href="assets/images/sprite.svg#arrow-down"/>
        </svg>
      </a>
      <a class="header-mobile-menu__upper-navigation-links-link popular-link" *ngIf="!(customContentService.hasCustomContent$ | async)"  routerLink="/courses/popular" (click)="itemClicked()">
        {{'header_mobile_menu.navigation_links' | translate | translateCut:2}}
      </a>
      <a class="header-mobile-menu__upper-navigation-links-link new-courses-link" *ngIf="!(customContentService.hasCustomContent$ | async)"   routerLink="/courses/new" (click)="itemClicked()">
        {{'header_mobile_menu.navigation_links' | translate | translateCut:8}}
      </a>
      <hr>
      <a class="header-mobile-menu__upper-navigation-links-link" *ngIf="!(customContentService.hasCustomContent$ | async)" routerLink="/mentors"
         (click)="itemClicked()">{{'header_nav_menu.item' | translate | translateCut: 3}}</a>

      <hr>
      <!--      <a class="header-mobile-menu__upper-navigation-links-link" routerLink="/offers" (click)="itemClicked()"-->
      <!--         *ngIf="(userInfo && !userInfo.subscribed && userInfo.userType !== 2) || !userInfo">-->
      <!--        {{'header_mobile_menu.navigation_links' | translate | translateCut:3}}-->
      <!--      </a>-->
      <a class="header-mobile-menu__upper-navigation-links-link section-last-item" routerLink="/subscription-plans"
         (click)="itemClicked()"
         *ngIf="(userInfo && !userInfo.subscribed && userInfo.userType !== 2) || !userInfo && !isB2bUser">
        {{'header_mobile_menu.navigation_links' | translate | translateCut:4}}
      </a>
      <hr *ngIf="(userInfo && !userInfo.subscribed && userInfo.userType !== 2) || !userInfo && !isB2bUser">
    </div>
    <div class="header-mobile-menu__switch-themes-buttons">
      <button class="btn btn-outline-light light-theme-btn" [class.selected-theme]="currentTheme == 1"
              (click)="switchToLightTheme();itemClicked()">
        <svg class="arrow">
          <use href="assets/images/sprite.svg#sun"/>
        </svg>
        {{'header_mobile_menu.navigation_links' | translate | translateCut:5}}
      </button>
      <button class="btn btn-outline-light dark-theme-btn selected-theme" [class.selected-theme]="currentTheme == 0"
              (click)="switchToDarkTheme();itemClicked()">
        <svg class="arrow">
          <use href="assets/images/sprite.svg#dark"/>
        </svg>
        {{'header_mobile_menu.navigation_links' | translate | translateCut:6}}
      </button>
    </div>
  </div>
  <div class="header-mobile-menu__bottom-section-wrapper" *ngIf="currentView == 1">
    <div class="header-mobile-menu__switch-languages-link" *ngIf="nextLanguage">
      <a class="switch-language-link" (click)="onLanguageChange(nextLanguage.code);itemClicked()">
        <svg class="arrow">
          <use href="assets/images/sprite.svg#website"/>
        </svg>
        {{nextLanguage.name}}
      </a>
    </div>
<!--    <div class="header-mobile-menu__quick-links-wrapper" [class.no-bottom-border]="userInfo">-->
<!--      <a class="menu-quick-link" (click)="itemClicked()" routerLink="/become-mentor">-->
<!--        {{'header_mobile_menu.quick_links' | translate | translateCut: 0}}-->
<!--      </a>-->
<!--      <a class="menu-quick-link" (click)="itemClicked()">-->
<!--        {{'header_mobile_menu.quick_links' | translate | translateCut: 1}}-->
<!--        <svg class="arrow">-->
<!--          <use href="assets/images/sprite.svg#arrow-forward"/>-->
<!--        </svg>-->
<!--      </a>-->
<!--    </div>-->


  </div>
  <div class="header-mobile-menu__courses-list-wrappers" *ngIf="currentView == 2">
    <a class="header-mobile-menu__courses-list-wrappers__back-link" (click)="switchToMainView()">
      <svg class="arrow">
        <use href="assets/images/sprite.svg#arrow-next"/>
      </svg>
      <span>{{'header_mobile_menu.courses_list' | translate | translateCut:0}}</span>
    </a>
    <div class="header-mobile-menu__courses-list">
      <div class="header-mobile-menu__courses-list__item" routerLink="/courses" (click)="itemClicked()">
<!--        <p>{{'header_mobile_menu.courses_list' | translate | translateCut:1}}</p>-->
        <a class="btn btn-danger" routerLink="/courses" (click)="itemClicked()">
          {{'header_mobile_menu.courses_list' | translate | translateCut:1}}
        </a>
      </div>
        <div class="header-mobile-menu__courses-list__item" *ngFor="let category of allCategories; last as last"
             [class.no-bottom-border]="last" (click)="handleNavigationToCategory(category)">
          <p>{{category.name}}</p>
        </div>
    </div>
  </div>

  <div class="header-mobile-menu__courses-list-wrappers" *ngIf="currentView == 3">
    <a class="header-mobile-menu__courses-list-wrappers__back-link" (click)="switchToMainView()">
      <svg class="arrow">
        <use href="assets/images/sprite.svg#arrow-next"/>
      </svg>
      <span>{{'header_mobile_menu.courses_list' | translate | translateCut:0}}</span>
    </a>
    <div class="header-mobile-menu__courses-list">
        <div class="header-mobile-menu__courses-list__item" *ngFor="let category of allB2bCategories; last as last"
             [class.no-bottom-border]="last" (click)="handleNavigationToCategory(category)">
          <p>{{category.name}}</p>
        </div>
    </div>
  </div>
</div>
