import {Injectable} from '@angular/core';
import {ICourseEnrollRequest, ISubscriptionPlan} from "@interfaces/subscription/subscription.interface";
import {CustomHttpService} from "@services/request-token/custom-http.service";
import {ClusterNames} from '@interfaces/common/http.interface';
import {environment} from '@environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SubscriptionHttpService {

  constructor(private customHttpService: CustomHttpService) {
  }

  getAllSubscriptionPlans(languageId: number): Promise<ISubscriptionPlan[]> {
    return this.customHttpService.sendRequest({
      clusterName: ClusterNames.NewOrch,
      endpoint: 'getSubPlan/api/subscriptions/plans?includeQuarterPlan=true',
      sender: 'subscription-plan',
      receiver: 'plans',
      skipOnSSR: true,
      body: {
        languageId: languageId
      }
    })
  }

  enrollInCourse(sender: string, course: ICourseEnrollRequest): Promise<{ result: boolean }> {
    return this.customHttpService.sendRequest({
      clusterName: ClusterNames.NewOrch,
      endpoint: 'getSubPlan/api/subscriptions/course/enroll',
      sender: sender,
      receiver: 'enroll',
      body: course
    })
  }

  enrollInCourse_new(courseId: number): Promise<{ result: any }> {
     return this.customHttpService.sendRequest({
      clusterName: ClusterNames.NewOrch,
      endpoint: 'progress/api/course/enroll',
      sender: 'enroll',
      receiver: 'enroll',
      body: {
        courseId: courseId
      }
    })
  }

  updateFutureXEnrollment(sender: string, courseId: number): void {
    this.customHttpService.sendRequest({
      clusterName: ClusterNames.NewOrch,
      endpoint: 'futureX/api/userReporting/enrollmentProgress',
      sender: sender,
      receiver: 'enroll',
      body: {courseId}
    })
  }

  cancelPlan(planId: number, reason: string): Promise<boolean> {
    return this.customHttpService.sendRequest({
      clusterName: ClusterNames.NewOrch,
      endpoint: 'updatSubscription/api/subscriptionMainUser/Cancel',
      sender: 'settings',
      receiver: 'cancel',
      body: {
        countryPlanId: planId,
        reason: reason
      }
    })
  }

  changePlan(planId: number): Promise<{result: boolean}> {
    return this.customHttpService.sendRequest({
      clusterName: ClusterNames.NewOrch,
      endpoint: 'updatSubscription/api/subscriptionMainUser/Change/Plan',
      sender: 'settings',
      receiver: 'plan',
      body: {
        entityId: planId
      }
    })
  }
}
