import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TimeFromSecondsPipe } from './time-from-seconds.pipe';



@NgModule({
  declarations: [TimeFromSecondsPipe],
  exports: [
    TimeFromSecondsPipe
  ],
  imports: [
    CommonModule
  ]
})
export class TimeFromSecondsModule { }
