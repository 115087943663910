import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {INotificationItem} from '@interfaces/notification/notification.interface';
import {Router} from "@angular/router";

@Component({
  selector: 'alm-root-header-notification-menu-system-card',
  templateUrl: './header-notification-menu-system-card.component.html',
  styleUrls: ['./header-notification-menu-system-card.component.scss']
})
export class HeaderNotificationMenuSystemCardComponent implements OnInit {

  @Input() notification?: INotificationItem;
  @Input() templateId: number = 0;
  @Output() markAsRead: EventEmitter<boolean> = new EventEmitter<boolean>();
  isSelected: boolean = false;
  dateToday = new Date();

  constructor(private router: Router) {
  }

  ngOnInit(): void {
  }

  onNotificationMenuClosing() {
    this.isSelected = false;
  }

  markNotificationAsRead(): void {
    this.markAsRead.emit(true);
  }

  onClickNotification() {
    if(this.templateId === 7) {
      this.router.navigate(['/my-progress']);
      this.markAsRead.emit(true);
    }
  }
}
