import {
  Component,
  EventEmitter,
  HostListener,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
  PLATFORM_ID,
  Renderer2
} from '@angular/core';
import {LanguageControlService} from '@services/language/language-control.service';
import ar from './i18n/ar.json';
import en from './i18n/en.json';
import {MatMenuTrigger} from '@angular/material/menu';
import {Subscription} from 'rxjs';
import {UserService} from '@services/integrations/user/user.service';
import {IUserInfo} from '@interfaces/authorized-user/user.interface';
import {isPlatformBrowser} from '@angular/common';
import {FocusMonitor} from '@angular/cdk/a11y';
import { CustomContentService } from '../../../services/custom-content/custom-content.service';

@Component({
  selector: 'alm-root-header-nav-menu',
  templateUrl: './header-nav-menu.component.html',
  styleUrls: ['./header-nav-menu.component.scss']
})
export class HeaderNavMenuComponent implements OnInit, OnDestroy {

  @Input() userInfo?: IUserInfo | null;
  @Input() isB2BUser?: boolean | null;
  @Output() onMenuToggle: EventEmitter<boolean> = new EventEmitter();
  menuDirection: 'rtl' | 'ltr' = 'ltr';
  menuPosition: 'before' | 'after' = 'after';
  currentLang: string = 'en';
  private menuTrigger?: MatMenuTrigger;
  private subscriptions: Subscription[] = [];
  isLinkEntered = false;
  isMatMenuOpen = false;

  constructor(private languageControl: LanguageControlService, private userService: UserService,
              @Inject(PLATFORM_ID) private platformId: any, private renderer: Renderer2, public customContentService: CustomContentService,
              private _focusMonitor: FocusMonitor) {
    this.languageControl.setTranslations('ar', ar);
    this.languageControl.setTranslations('en', en);
    let langSub = this.languageControl.currentLanguage.subscribe((lang) => {
      this.menuDirection = lang.direction;
      this.currentLang = lang.code;
    });
    this.subscriptions.push(langSub);
  }

  ngOnInit(): void {
    this.controlMenuOnWindowResize();
  }

  controlMenuOnWindowResize() {
    if (isPlatformBrowser(this.platformId)) {
      let windowWidth = window.innerWidth;
      if (this.menuTrigger) {
        this.menuTrigger.closeMenu();
      }
      if (windowWidth > 991 && windowWidth < 1300) {
        if (this.menuPosition != 'before') {
          this.menuPosition = 'before';
        }
      } else {
        if (this.menuPosition != 'after') {
          this.menuPosition = 'after';
        }
      }
    }
  }

  @HostListener('window:resize')
  onWindowResize() {
    this.controlMenuOnWindowResize();
  }

  openCoursesMenu(menu: MatMenuTrigger) {
    this.menuTrigger = menu;
    menu.openMenu();
  }

  closeCoursesMenu(menu: MatMenuTrigger) {
    this.menuTrigger = menu;
    menu.closeMenu();
  }

  onCoursesMenuOpen() {
    this.onMenuToggle.emit(true);
  }

  onCoursesMenuClosed() {
    this.onMenuToggle.emit(false);
  }

  ngOnDestroy(): void {
    this.subscriptions.map(sub => {
      sub.unsubscribe();
    });
  }

  onButtonEnter(coursesMenuTrigger: MatMenuTrigger, coursesLink: HTMLElement) {
    setTimeout(() => {
      if (!this.isMatMenuOpen) {
        this.isLinkEntered = true;
        coursesMenuTrigger.openMenu();
      } else {
        this.isLinkEntered = true;
      }
    });
  }

  onButtonLeave(coursesMenuTrigger: MatMenuTrigger, coursesLink: HTMLElement) {
    setTimeout(() => {
      if (!this.isMatMenuOpen) {
        coursesMenuTrigger.closeMenu();
      }
      this.isLinkEntered = false;
    }, 100);
  }

  menuMouseEnter() {
    this.isMatMenuOpen = true;
  }

  menuMouseLeave(coursesMenuTrigger: MatMenuTrigger, coursesLink: HTMLElement) {
    setTimeout(() => {
      if (!this.isLinkEntered) {
        this.isMatMenuOpen = false;
        coursesMenuTrigger.closeMenu();
      } else {
        this.isMatMenuOpen = false;
      }
    }, 100)
  }
}
