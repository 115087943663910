import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TopRibbonDangerComponent } from './top-ribbon-danger/top-ribbon-danger.component';
import { TopRibbonInfoComponent } from './top-ribbon-info/top-ribbon-info.component';
import {RouterModule} from "@angular/router";
import {TranslateModule} from '@ngx-translate/core';
import { TopRibbonAppAnnouncementComponent } from './top-ribbon-app-announcement/top-ribbon-app-announcement.component';



@NgModule({
    declarations: [TopRibbonDangerComponent, TopRibbonInfoComponent, TopRibbonAppAnnouncementComponent],
  exports: [
    TopRibbonInfoComponent,
    TopRibbonDangerComponent,
    TopRibbonAppAnnouncementComponent,
  ],
    imports: [
        CommonModule,
        RouterModule,
        TranslateModule
    ]
})
export class TopRibbonModule { }
