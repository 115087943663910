import {Injectable} from '@angular/core';
import {NgxUiLoaderService, Time} from 'ngx-ui-loader';

@Injectable({
  providedIn: 'root'
})
export class UiLoaderService {

  constructor(
    private ngxService: NgxUiLoaderService
  ) {
  }

  startUiLoader(taskId?: string, time: Time = {minTime: 0}) {
    this.ngxService.start(taskId, time);
  }

  stopUiLoader(taskId?: string) {
    this.ngxService.stop(taskId);
  }

  stopAllUiLoader() {
    this.ngxService.stopAll();
  }

  startAddCartUILoader(taskId?: string) {
    this.ngxService.startLoader('add-to-cart-loader', taskId);
  }

  stopAddCartUILoader(taskId?: string) {
    this.ngxService.stopLoader('add-to-cart-loader', taskId);
  }
}
