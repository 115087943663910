import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthDialogContainerComponent } from './auth-dialog-container.component';
import {LoginContainerModule} from "@components/login-container/login-container.module";
import {SignUpContainerModule} from "@components/sign-up-container/sign-up-container.module";
import {MatDialogModule} from "@angular/material/dialog";
import {AuthContainerModule} from "@components/auth-container/auth-container.module";
import {VerifyEmailModule} from "@components/verify-email/verify-email.module";
import {DemographicsModule} from "@components/demographics/demographics.module";
import {InterestsContainerModule} from "@components/interests-container/interests-container.module";
import {TranslateModule} from "@ngx-translate/core";
import {AlmTranslateCutModule} from '@alm-translate-cut/alm-translate-cut.module';


@NgModule({
  declarations: [AuthDialogContainerComponent],
  imports: [
    CommonModule,
    LoginContainerModule,
    SignUpContainerModule,
    MatDialogModule,
    AuthContainerModule,
    VerifyEmailModule,
    DemographicsModule,
    InterestsContainerModule,
    TranslateModule,
    AlmTranslateCutModule
  ]
})
export class AuthDialogContainerModule { }
