import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HorizontalCourseCardNewComponent } from './horizontal-course-card-new.component';
import { HorizontalCourseCardNewNoProgressComponent } from './horizontal-course-card-new-no-progress/horizontal-course-card-new-no-progress.component';
import { HorizontalCourseCardNewProgressComponent } from './horizontal-course-card-new-progress/horizontal-course-card-new-progress.component';
import {ImageLoaderModule} from "@directives/image-loader/image-loader.module";
import {TranslateModule} from "@ngx-translate/core";
import {AlmTranslateCutModule} from '@alm-translate-cut/alm-translate-cut.module';
import {TimeFromSecondsModule} from "@pipes/time-from-seconds/time-from-seconds.module";



@NgModule({
  declarations: [HorizontalCourseCardNewComponent, HorizontalCourseCardNewNoProgressComponent, HorizontalCourseCardNewProgressComponent],
  exports: [
    HorizontalCourseCardNewComponent
  ],
  imports: [
    CommonModule,
    ImageLoaderModule,
    TranslateModule,
    AlmTranslateCutModule,
    TimeFromSecondsModule
  ]
})
export class HorizontalCourseCardNewModule { }
