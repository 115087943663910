import {Injectable} from '@angular/core';
import {DynamicLoadService} from '@services/dynamic-load/dynamic-load.service';
import {NavigationEnd, Router} from '@angular/router';
import {IPaymentMethod} from '@interfaces/common/payment.interface';
import {ISignupData} from "@interfaces/user/user-common.interface";
import {EncryptionService} from "@services/request-token/encryption.service";
import {environment} from "@environments/environment";
import {CookieService} from "ngx-cookie";
import {timer} from "rxjs";
import {take} from "rxjs/operators";
import {ELoginTypes} from "@interfaces/authorized-user/user.interface";

const GTM_GLOBAL_PROP_KEY: string = 'alm-gtm-global-prop';
const GTM_DELAY_MS: number = 2000;

@Injectable({
  providedIn: 'root'
})
export class GoogleTagManagerService {

  private dataLayer?: any[];
  private globalProperties: {[key: string]: any} = {}
  private eventQueue: {eventName: string, options?: {}}[] = []; // An array to hold event data before GTM gets initialized

  constructor(private dynamicLoad: DynamicLoadService, private router: Router,
              private encryptionService: EncryptionService,
              private cookieService: CookieService,
              ) {
    const globalProperties = this.getStoredGlobalProperties();
    if (globalProperties) this.setGlobalProperties(globalProperties);
    this.trackPageView();
  }

  loadGtm(id: string): void {
    // delay GTM initialization waiting for plans endpoint if it is gets called
    /*TODO remove the delay once we have dedicated endpoint for obtain the country code
    *  then we can directly get the country code before init
    *  instead of waiting for plans endpoint to get the country code if called
    *  as we don't need to call plans in some pages that doesn't have pricing
    */
    timer(GTM_DELAY_MS).pipe(take(1)).subscribe(() => {
      this.dynamicLoad.externalScript(
        'gtm-script',
        `https://www.googletagmanager.com/gtm.js?id=${id}&l=dataLayer`
      ).then((loaded) => {
        if (loaded) {
          (<any> window).dataLayer = (<any> window).dataLayer || [];
          this.dataLayer = (<any> window).dataLayer;
          this.sendCustomEvent('gtm.js', {'gtm.start': new Date().getTime()});

          // Process all pending events from the queue
          for( let eventData of this.eventQueue) {
            this.sendCustomEvent(eventData.eventName, eventData.options);
          }
          this.eventQueue = [];
        }
      });
    });
  }

  sendCustomEvent(eventName: string, options?: {}) {
    if (eventName && this.dataLayer) {
      this.dataLayer.push({ecommerce: null});
      this.dataLayer.push({
        event: eventName,
        ...this.globalProperties,
        ...options
      });
    } else {
      // if datalayer not available, queue the events
      this.eventQueue.push({
        eventName: eventName,
        options: options
      });
    }
  }

  trackPageView(): void {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.sendCustomEvent('page', {'pageName': event.url});
      }
    });
  }

  sendLoginEvent(options: { type: 'CLICK' | 'RESPONSE', status: 'success' | 'failed', loginType?: ELoginTypes }) {
    this.sendCustomEvent('LOGIN', options);
  }

  sendSignupEvent(options: { type: 'CLICK' | 'RESPONSE', status: 'success' | 'failed', loginType?: ELoginTypes }) {
    this.sendCustomEvent('SIGN-UP', options);
  }

  sendUserUUID(userUUID: string | null) {
    this.sendCustomEvent('SET-USER-ID', {userId: userUUID});
  }

  addToCartEvent(options: { from: string, item: { id: number, name: string, price: number, currency: string } }) {
    this.sendCustomEvent('ADD-TO-CART', {
      ecommerce: {
        currencyCode: options.item.currency,
        add: {
          products: [{
            id: options.item.id,
            name: options.item.name,
            list_name: options.from,
            quantity: 1,
            price: options.item.price,
          }]
        }
      }
    });
  }

  removeFromCartEvent(options: { item: { id: number, name: string, price: number, currency: string } }) {
    this.sendCustomEvent('REMOVE-FROM-CART', {
      ecommerce: {
        currencyCode: options.item.currency,
        remove: {
          products: [{
            id: options.item.id,
            name: options.item.name,
            quantity: 1,
            price: options.item.price,
          }]
        }
      }
    });
  }

  addToSaveListEvent(options: { from: string, item: { id: number, name: string } }) {
    this.sendCustomEvent('ADD-TO-SAVE-LIST', options);
  }

  removeFromSaveListEvent(options: { item: { id: number, name: string } }) {
    this.sendCustomEvent('REMOVE-FROM-SAVE-LIST', options);
  }

  subscriptionCheckOutEvent(options: {
    method: IPaymentMethod,
    item: { id: number, name: string, price: number, coupon?: string }
  }) {
    let items: any[] = this.prepareCheckoutItems(options.item);
    this.sendCustomEvent('CHECKOUT-SUBSCRIPTION', {
      paymentMethod: options.method,
      subscriptionPlan: options.item.name,
      couponCode: options.item.coupon,
      ecommerce: {
        checkout: {
          actionField: {step: 1, options: options.method},
          products: items
        }
      }
    });
  }

  successfulPaymentStatus(options: {
    trn: string,
    price: number,
    currency: string
    discount: number,
    item: { id: number, name: string, coupon?: string },
    method: IPaymentMethod
  }) {
    let items: any[] = this.prepareCheckoutItems(options.item);
    this.sendCustomEvent('PAYMENT-STATUS-SUBSCRIPTION-SUCCESS', {
      paymentMethod: options.method,
      subscriptionPlan: options.item.name,
      couponCode: options.item.coupon,
      category: 'Success Subscription',
      action: options.trn,
      label: options.item.name,
      value: options.price,
      // Google ecommerce details
      ecommerce: {
        transaction_id: options.trn,
        value: options.price,
        currency: "USD",
        coupon: options.item.coupon,
        items: [{
          item_id: options.item.id,
          item_name: options.item.name,
          price: options.price,
          coupon: options.item.coupon,
          discount: options.discount,
        }]
      }
    });
  }

  pendingPaymentStatus(options: {
    method: IPaymentMethod,
    trn: string
    item: { id: number, name: string, coupon?: string }
  }) {
    let items: any[] = this.prepareCheckoutItems(options.item);
    this.sendCustomEvent('PAYMENT-STATUS-SUBSCRIPTION-PENDING', {
      paymentMethod: options.method,
      subscriptionPlan: options.item.name,
      couponCode: options.item.coupon,
      category: 'Pending Subscription',
      action: options.method,
      label: options.trn
    });
  }

  failedPaymentStatus(options: {
    method: IPaymentMethod,
    trn: string
    item: { id: number, name: string, coupon?: string }
  }) {
    let items: any[] = this.prepareCheckoutItems(options.item);
    this.sendCustomEvent('PAYMENT-STATUS-SUBSCRIPTION-FAILED', {
      paymentMethod: options.method,
      subscriptionPlan: options.item.name,
      couponCode: options.item.coupon,
      category: 'Failed Subscriptions',
      action: options.method,
      label: options.trn
    });
  }

  goToSubscriptionPageEvent(options: { from: string, type: 'HOT' | 'COLD' }) {
    this.sendCustomEvent('GO-SUBSCRIPTION', options);
  }

  subscriptionStatusChangeEvent(options: { type: 'CHANGE' | 'CANCEL', oldPlan: number, newPlan: number }) {
    this.sendCustomEvent('SUBSCRIPTION-CHANGE', options);
  }

  courseBehavioursEvent(options: { type: 'PROMO' | 'INTRO' | 'SHARE', item: { id: number, name: string } }) {
    this.sendCustomEvent(`COURSE-${options.type}`, options.item);
  }

  private prepareCheckoutItems(item: { id: number, name: string, price?: number, coupon?: string }): any {
    let items: any[] = [];
    items.push({
      id: item.id,
      name: item.name,
      list_name: 'checkout-subscription',
      quantity: 1,
      price: item.price,
      coupon: item.coupon ? item.coupon : ''
    });
    return items;
  }

  sendSocialLoginEvent(activateData: ISignupData) {
    const normalizeAndHash = (string?: string) => {
      if (string) {
        return this.encryptionService.hashStringWithSHA256(string.trim().toLowerCase())
      }
      return undefined;
    }
    this.sendCustomEvent('SOCIAL-LOGIN', {
      name: normalizeAndHash(activateData.userName),
      email: normalizeAndHash(activateData.email),
    });
  }

  public setGlobalProperties(properties: {[key: string]: any}) {
      this.globalProperties = {
        ...this.globalProperties,
        ...properties
      };
      this.sendCustomEvent('GLOBAL-PROPERTIES', this.globalProperties);
      this.persistGlobalProperties(this.globalProperties);
  }

  private persistGlobalProperties(GlobalProperties: object) {
    const encodedGlobalProperties = this.encryptionService.encodeStringToBase64(JSON.stringify(GlobalProperties));
    let cookieDate = new Date();
    cookieDate.setFullYear(cookieDate.getFullYear() + 1);
    this.cookieService.put(GTM_GLOBAL_PROP_KEY, encodedGlobalProperties, {
      domain: environment.platforms.base.link,
      path: '/',
      expires: cookieDate,
      sameSite: false,
      secure: true,
    });
  }
  private getStoredGlobalProperties(): object | undefined {
    const encodedGlobalProperties = this.cookieService.get(GTM_GLOBAL_PROP_KEY);
    if (!encodedGlobalProperties) return undefined;
    const decodedGlobalProperties = this.encryptionService.decodeBase64ToString(encodedGlobalProperties);
    return JSON.parse(decodedGlobalProperties);
  }

  public removeGlobalProperties() {
    this.cookieService.remove(GTM_GLOBAL_PROP_KEY);
  }
}
