import {Component, EventEmitter, OnInit, Output, ViewEncapsulation} from '@angular/core';
import ar from "@components/top-ribbon/i18n/ar.json";
import en from "@components/top-ribbon/i18n/en.json";
import {LanguageControlService} from '@services/language/language-control.service';
import {UAParser} from 'ua-parser-js';

@Component({
  selector: 'alm-root-top-ribbon-app-announcement',
  templateUrl: './top-ribbon-app-announcement.component.html',
  styleUrls: ['./top-ribbon-app-announcement.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class TopRibbonAppAnnouncementComponent implements OnInit {

  @Output() closeAppAnnouncement: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(private languageControl: LanguageControlService) {
    this.languageControl.setTranslations('ar', ar);
    this.languageControl.setTranslations('en', en);
  }

  ngOnInit(): void {
  }

  closeAnnouncement(): void {
    this.closeAppAnnouncement.emit(true);
  }

  navigateToDownloadLink(): void {
    let ua = new UAParser();
    let os = ua.getOS();
    if (os.name?.toLowerCase() == 'android') {
      window.location.href = "https://play.google.com/store/apps/details?id=com.almentor.app";
    } else if ( os.name?.toLowerCase() == 'ios') {
    }
  }
}
