export enum ConversionStrings{
  google = 'gclid',
  facebook = 'fbclid',
  tiktok = 'ttclid',
  utmOnlyCampaign = 'utm_campaign',
}

export type IAdSourceType = keyof typeof ConversionStrings;

export interface IAdSourceContents {
  clickId?: string;
  utmParams: string;
}

export interface IConversionSource {
  adSourceType: IAdSourceType;
  adSourceId: IAdSourceContents | string;
  createdAt: string;
}

export interface IConversionSourceHttp {
  adSourceType: IAdSourceType;
  adSourceId: string;
  createdAt: string;
}
export interface IHeroBanner {
  title:             string;
  subtitle:          string;
  callToActionLabel: string;
  desktopImage:      string;
  callToActionUrl:   string;
  mobileImage:       string;
}

