import {Injectable} from '@angular/core';
import {AngularFireMessaging} from "@angular/fire/compat/messaging";
import {filter, map, mergeMap} from "rxjs/operators";
import {lastValueFrom, Observable, Subscription} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class FirebaseNotificationsService {

  constructor(private afMessaging: AngularFireMessaging) {
  }

  requestNotificationPermissions(): Promise<string> {
    let requestTokenSub: Subscription | undefined;
    return new Promise<string>(resolve => {
      requestTokenSub = this.afMessaging.requestToken.subscribe((token) => {
        if (token) {
          resolve(token);
        }
      });
    }).then((token) => {
      if(requestTokenSub){
        requestTokenSub.unsubscribe();
      }
      return token;
    });
  }

  removeNotificationTokenForUser(): Promise<string | null> {
    return new Promise<string | null>((resolve, reject) => {
      lastValueFrom(this.afMessaging.getToken).then(token => {
        if (token) {
          lastValueFrom(this.afMessaging.deleteToken(token)).finally(() => {
            resolve(token);
          });
        } else {
          reject('Token Not Found');
        }
      }).catch(reject);
    });
  }

  onMessaging(): Observable<any> {
    return this.afMessaging.messages;
  }
}
