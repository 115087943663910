import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {INotificationItem} from '@interfaces/notification/notification.interface';
import {Router} from "@angular/router";
import {FilesManagerService} from "@services/files/files-manager.service";
import {NotificationService} from "@services/integrations/notification/notification.service";
import {UiLoaderService} from "@services/ui-loader/ui-loader.service";

@Component({
  selector: 'alm-root-header-notification-menu-announcement-card',
  templateUrl: './header-notification-menu-announcement-card.component.html',
  styleUrls: ['./header-notification-menu-announcement-card.component.scss']
})
export class HeaderNotificationMenuAnnouncementCardComponent implements OnInit {

  @Input() isInNotificationMenu: boolean = false;
  @Input() notification?: INotificationItem;
  @Output() markAsRead: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() closeMenu: EventEmitter<boolean> = new EventEmitter<boolean>();
  isSelected: boolean = false;
  dateToday = new Date();

  constructor(private router: Router, private filesManager: FilesManagerService, private notificationService: NotificationService,
              private uiLoader: UiLoaderService) {
  }

  ngOnInit(): void {
  }

  onNotificationMenuClosing() {
    this.isSelected = false;
  }

  getMentorImage(uuid: string | undefined) {
    return this.filesManager.getHashedImage(`user-${uuid}-profile`)
  }

  getAnnouncementCover(id: number | undefined) {
    return this.filesManager.getHashedImage(`announcement-${id}-cover`);
  }

  markNotificationAsRead(): void {
    this.markAsRead.emit(true);
  }

  goToAnnouncement($event: MouseEvent) {
    $event.stopPropagation();
    if (this.notification && this.notification.courseId && this.notification.announcementId) {
      this.uiLoader.startUiLoader('get-permanentLink');
      this.notificationService.getCoursePermanentLinkById(this.notification.courseId).then(({permanentLink}) => {
        if (this.notification) {
          this.router.navigate([`/viewer/${permanentLink}`], {
            state: {
              announcement: true,
              announcementId: this.notification.announcementId
            }
          });
          this.markAsRead.emit(true);
          this.closeMenu.emit();
        }
      }).finally(() => {
        this.uiLoader.stopUiLoader('get-permanentLink');
      });
    }
  }
}
