import {Component, Inject, OnInit} from '@angular/core';
import {ISubscriptionPlan} from '@interfaces/subscription/subscription.interface';
import {IUserInfo} from '@interfaces/authorized-user/user.interface';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {UiLoaderService} from '@services/ui-loader/ui-loader.service';
import {SubscriptionService} from '@services/integrations/subscription/subscription.service';
import {DialogService} from '@services/custom-dialogs/dialog.service';
import {AmplitudeService} from '@services/marketing/amplitude.service';
import {UserManagerService} from '@services/user/user-manager.service';
import {ConfirmationChangePlanDialogComponent} from '@components/confirmation-change-plan-dialog/confirmation-change-plan-dialog.component';
import {lastValueFrom} from "rxjs";

@Component({
  selector: 'alm-root-user-settings-change-plan-dialog',
  templateUrl: './user-settings-change-plan-dialog.component.html',
  styleUrls: ['./user-settings-change-plan-dialog.component.scss']
})
export class UserSettingsChangePlanDialogComponent implements OnInit {

  currentPlan?: ISubscriptionPlan;
  nextPlan?: ISubscriptionPlan;
  plans: ISubscriptionPlan[] = [];
  userInfo: IUserInfo | null = null;
  date = '';
  discountPercentage = '30%';

  constructor(@Inject(MAT_DIALOG_DATA) private subData: { plan: ISubscriptionPlan, date: string }, private uiLoader: UiLoaderService,
              private subscriptionService: SubscriptionService, private dialogService: DialogService,
              private userManagerService: UserManagerService, private amplitudeService: AmplitudeService) {
  }

  ngOnInit(): void {
    this.userInfo = this.userManagerService.userInfo.getValue();
    if (this.subData.plan?.id) {
      this.subscriptionService.getAllSubscriptionPlans().then((plans) => {
        this.plans = plans;
        this.plans.map((plan) => {
          if (plan.id != this.subData.plan.id) {
            this.nextPlan = plan;
          }
        });
      }).catch(() => {
        this.closeDialog();
      });
      this.currentPlan = this.subData.plan;
    } else {
      this.closeDialog();
    }

    this.date = this.subData.date;

    this.getDiscountPercentage();
  }

  closeDialog() {
    this.dialogService.closeDialog('change-subscription-plan', false);
  }

  changePlan() {
    if (this.nextPlan) {
      this.uiLoader.startUiLoader('plans');
      this.subscriptionService.changePlan(this.nextPlan.id).then(() => {
        this.dialogService.closeDialog('change-subscription-plan', true);
        lastValueFrom(this.dialogService.openDialog(ConfirmationChangePlanDialogComponent, 'confirm-change-subscription-plan', {
          plan: this.nextPlan,
          date: this.date
        })
          .afterClosed()).then((result: boolean) => {
          console.log(this.nextPlan);
        });


      }).finally(() => {
        this.uiLoader.stopUiLoader('plans');
        let currentPlanType = this.currentPlan?.id === 1 ? 'Monthly EGP' : this.currentPlan?.id === 2 ? 'Annual EGP' : '';
        let newPlanType = this.nextPlan?.id === 1 ? 'Monthly EGP' : this.nextPlan?.id === 2 ? 'Annual EGP' : '';
        this.amplitudeService.subscriptionPlanChangedEvent({
          Subscription_Plan_Changed_Current_Plan_Type: currentPlanType,
          Subscription_Plan_Changed_New_Plan_Type: newPlanType
        });
      });
    }
  }


  private getDiscountPercentage(): void {
    this.subscriptionService.getAllSubscriptionPlans().then((plans) => {
        const annualPlan = plans.find(p => p.isAnnualPlan);
        if (annualPlan?.annualFeesForMonthlyPlan) {
          const discount = (annualPlan.annualFeesForMonthlyPlan - annualPlan.finalPrice) / annualPlan.annualFeesForMonthlyPlan;
          this.discountPercentage = Math.round(discount * 100) + '%';
        }
      }
    );
  }

}
