import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {HttpClientModule} from "@angular/common/http";
import {EncryptionService} from "@services/request-token/encryption.service";
import {TokenService} from "@services/request-token/token.service";
import {ConnectorV2Service} from "@services/connector/connector-v2.service";



@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    HttpClientModule
  ],
  providers: [EncryptionService, TokenService, ConnectorV2Service]
})
export class RequestTokenModule { }
