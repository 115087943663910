import {
  Component, EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {LanguageControlService} from '@services/language/language-control.service';
import ar from './i18n/ar.json';
import en from './i18n/en.json';
import {UntypedFormControl} from '@angular/forms';
import {AlgoliaSearchService} from '@services/search/algolia-search.service';
import {SearchClient} from 'algoliasearch';
import {Subscription} from 'rxjs';
import {MatAutocomplete, MatAutocompleteSelectedEvent, MatAutocompleteTrigger} from '@angular/material/autocomplete';
import {ICourse, ISimplifiedCourse} from '@interfaces/course/course.interface';
import {ISimplifiedBundle} from '@interfaces/course/bundle.interface';
import {ISimplifiedMentor} from '@interfaces/course/mentor.interface';
import {Router} from '@angular/router';
import {environment} from "@environments/environment";
import {MultipleQueriesResponse, SearchForFacetValuesResponse, SearchResponse} from "@algolia/client-search";

@Component({
  selector: 'alm-root-header-search',
  templateUrl: './header-search.component.html',
  styleUrls: ['./header-search.component.scss']
})
export class HeaderSearchComponent implements OnInit, OnDestroy {

  @Input() autoFocus: boolean = false;
  @Output() closeSearchInput: EventEmitter<any> = new EventEmitter<any>();
  openSearchBoxHistory: boolean = false;
  openSearchBoxSuggestions: boolean = false;
  searchInput: UntypedFormControl;
  focusedIn: boolean = false;
  searchHistory: string[] = [];
  searchSuggestions: {
    words: { objectID: string }[],
    courses: { id: number, permanentLink: string, arName: string, enName: string }[],
    bundles: { id: number, name: string }[],
    mentors: { id: number, permanentLink: string, mentorEnName?: string, mentorArName: string, partnerEnName?: string, partnerArName: string, }[]
  } = this.resettedSearchSuggestions();
  currentLang: string;
  private algoliaSearchClient?: SearchClient;
  private autocompleteClassAdded: boolean = false;
  private subscriptions: Subscription[] = [];

  searchAutocomplete?: MatAutocomplete;

  constructor(private languageControl: LanguageControlService, private algoliaSearchService: AlgoliaSearchService,
              private router: Router, private renderer2: Renderer2) {
    this.languageControl.setTranslations('ar', ar);
    this.languageControl.setTranslations('en', en);
    this.currentLang = this.languageControl.currentLanguage.getValue().code;
    this.searchInput = new UntypedFormControl('');
  }

  ngOnInit(): void {
    this.algoliaSearchClient = this.algoliaSearchService.initAlgolia(environment.algolia.appId,
      environment.algolia.apiKey);
    let searchValueSub = this.searchInput.valueChanges.subscribe((value) => {
      if (value.length < 3) {
        this.getHistorySearchResults(value);
      } else {
        this.getAlgoliaSuggestions(value);
      }
    });
    let langSub = this.languageControl.currentLanguage.subscribe((lang) => {
      this.currentLang = lang.code;
    })
    this.subscriptions.push(searchValueSub);
    this.subscriptions.push(langSub);
  }

  private getHistorySearchResults(value: string) {
    this.searchSuggestions = this.resettedSearchSuggestions();
    this.algoliaSearchService.searchInLocalHistory(value).then((res) => {
      if (res) {
        this.searchHistory = res.hits;
      } else {
        this.searchHistory = [];
      }
    });
  }

  private getAlgoliaSuggestions(value: string) {
    this.searchHistory = [];
    if (this.algoliaSearchClient) {
      this.algoliaSearchService.searchInAlgolia<any>(value, this.algoliaSearchClient)
        .then((res) => {
          let results = <Array<SearchResponse<any>>>res.results;
          this.searchSuggestions = {
            words: results[0].hits,
            bundles: results[1].hits,
            courses: results[2].hits,
            mentors: results[3].hits
          };
        });
    }
  }

  private resettedSearchSuggestions() {
    return {
      words: [],
      courses: [],
      bundles: [],
      mentors: []
    };
  }

  ngOnDestroy(): void {
    this.subscriptions.map((sub) => {
      sub.unsubscribe();
    });
    this.searchSuggestions = this.resettedSearchSuggestions();
    this.searchHistory = [];
  }

  onHistorySearchDelete(value: string, $event: MouseEvent) {
    $event.preventDefault();
    $event.stopPropagation();
    this.algoliaSearchService.deleteValueFromLocalSearchHistory(value);
    if (this.searchInput.value.length < 3) {
      this.getHistorySearchResults(this.searchInput.value);
    }
  }

  initSearchHistory() {
    if (this.searchInput.value.length < 3) {
      this.getHistorySearchResults(this.searchInput.value);
    }
  }

  onAutocompleteOpened(searchAutocomplete: MatAutocomplete) {
    this.searchAutocomplete = searchAutocomplete;
    if (searchAutocomplete.panel?.nativeElement?.parentElement) {
      if (!this.autocompleteClassAdded) {
        searchAutocomplete.panel.nativeElement.parentElement.classList.add('header-search__autocomplete-container');
        this.autocompleteClassAdded = true;
      }
    }
  }

  private afterSearchSubmit(searchAutocompleteTrigger: MatAutocompleteTrigger) {
    if (searchAutocompleteTrigger) {
      searchAutocompleteTrigger.closePanel();
    }
    this.closeSearchInput.emit();
    this.searchInput.setValue('');
  }

  onSearchSubmit(searchAutocompleteTrigger: MatAutocompleteTrigger) {
    if (this.searchInput.value) {
      let query = this.searchInput.value;
      this.router.navigate(['/search'], {queryParams: {'q': query}});
      this.afterSearchSubmit(searchAutocompleteTrigger);
    }
  }

  goCourse(searchAutocompleteTrigger: MatAutocompleteTrigger, permanentLink: string) {
    if (permanentLink) {
      this.router.navigate([`/courses/${permanentLink}`]);
      this.afterSearchSubmit(searchAutocompleteTrigger);
    }
  }

  goBundle(searchAutocompleteTrigger: MatAutocompleteTrigger, id: number) {
    if (id) {
      this.router.navigate([`/bundles/${id}`]);
      this.afterSearchSubmit(searchAutocompleteTrigger);
    }
  }

  goMentor(searchAutocompleteTrigger: MatAutocompleteTrigger, permanentLink: string) {
    if (permanentLink) {
      this.router.navigate([`/mentors/${permanentLink}`]);
      this.afterSearchSubmit(searchAutocompleteTrigger);
    }
  }
}
