import {Inject, Injectable, PLATFORM_ID} from '@angular/core';
import algoliasearch, {SearchClient} from "algoliasearch";
import {MultipleQueriesResponse, SearchResponse} from "@algolia/client-search";
import aa from 'search-insights';
import {isPlatformBrowser} from "@angular/common";
import {environment} from "@environments/environment";
import {UserManagerService} from "@services/user/user-manager.service";
import {IUserType} from "@interfaces/authorized-user/user.interface";

@Injectable({
  providedIn: 'root'
})
export class AlgoliaSearchService {

  private localSearchHistory: string[] = [];
  private algoliaSearchClient?: SearchClient;
  private userPlatform:any;

  constructor(private userManagerService: UserManagerService, @Inject(PLATFORM_ID) private platformId: any) {
    this.getLsSearchHistory();
    this.userPlatform = userManagerService.userPlatform;
  }

  initAlgolia(appId: string, apiKey: string): SearchClient {
    if (this.algoliaSearchClient) {
      return this.algoliaSearchClient;
    } else {
      aa('init', {
        appId: appId,
        apiKey: apiKey,
      });
      return algoliasearch(appId, apiKey)
    }
  }

  searchInAlgolia<T>(queryString: string, searchClient: SearchClient): Readonly<Promise<MultipleQueriesResponse<T>>> {
    const isB2BUser = this.userPlatform != null && this.userPlatform != environment.platforms.b2c.code;
    let queries = [
      {
        query: queryString,
        indexName: environment.algolia.indices.keywords,
        params: {
          hitsPerPage: 3,
          enablePersonalization: false,
          filters:''
        },
      },
      {
        query: queryString,
        indexName: environment.algolia.indices.bundles,
        params: {
          hitsPerPage: 0,
          enablePersonalization: false
        }
      }
    ];
    if(isB2BUser){
      queries.push(
        {
          query: queryString,
          indexName: environment.algolia.indices.publicAndPrivateCourses,
          params: {
            hitsPerPage: 3,
            enablePersonalization: false,
            filters:`organizationPlatform:${this.userPlatform} OR organizationPlatform:0`

          }
        });
        queries.push(
        {
          query: queryString,
          indexName:  environment.algolia.indices.publicAndPrivateCoursesMentors,
          params: {
            hitsPerPage: 3,
            enablePersonalization: false,
            filters:`courses.organizationPlatform:${this.userPlatform} OR courses.organizationPlatform:0`
          }
        });
    }else{
      queries.push(
      {
        query: queryString,
        indexName: environment.algolia.indices.courses,
        params: {
          hitsPerPage: 3,
          enablePersonalization: false
        }
      });
      queries.push(
      {
        query: queryString,
        indexName:  environment.algolia.indices.mentors,
        params: {
          hitsPerPage: 3,
          enablePersonalization: false
        }
      });
    }
    return searchClient.search<T>(queries);
  }

  searchInLocalHistory(query: string): Promise<SearchResponse<any> | undefined> {
    return new Promise<SearchResponse<any> | undefined>(resolve => {
      if (this.localSearchHistory.length > 0) {
        let hits = this.localSearchHistory.filter(value => value.includes(query.toLowerCase()));
        resolve({
          query: query,
          hits: hits.slice(0, 6),
          params: '',
          hitsPerPage: 6,
          nbHits: hits.length,
          nbPages: 1,
          processingTimeMS: 1,
          page: 0,
          exhaustiveNbHits: false
        })
      } else {
        resolve(undefined);
      }
    });
  }

  addValueToLocalSearchHistory(value: string) {
    let localSearchHistorySet = new Set(this.localSearchHistory);
    if (!localSearchHistorySet.has(value.toLowerCase())) {
      this.localSearchHistory.unshift(value.toLowerCase());
      if(isPlatformBrowser(this.platformId)){
        localStorage.setItem('Alm-LSH', JSON.stringify(this.localSearchHistory));
      }
    }
  }

  deleteValueFromLocalSearchHistory(value: string) {
    let localSearchHistorySet = new Set(this.localSearchHistory);
    if (localSearchHistorySet.has(value.toLowerCase())) {
      localSearchHistorySet.delete(value.toLowerCase());
      this.localSearchHistory = Array.from(localSearchHistorySet);
      if(isPlatformBrowser(this.platformId)){
        localStorage.setItem('Alm-LSH', JSON.stringify(this.localSearchHistory));
      }
    }
  }

  sendEventClickAfterSearch(object: { objectId: string, eventName: string, index: string, position: number, queryID: string }) {
    if (this.algoliaSearchClient) {
      let userInfo = this.userManagerService.userInfo.getValue();
      if (userInfo?.uuid) {
        aa('clickedObjectIDsAfterSearch', {
          userToken: userInfo.uuid,
          eventName: object.eventName,
          index: object.index,
          queryID: object.queryID,
          positions: [object.position],
          objectIDs: [object.objectId.toString()]
        });
      }
    }
  }

  sendEventClick(object: { objectId: string, eventName: string, index: string }) {
    if (this.algoliaSearchClient) {
      let userInfo = this.userManagerService.userInfo.getValue();
      if (userInfo?.uuid) {
        aa('clickedObjectIDs', {
          userToken: userInfo.uuid,
          eventName: object.eventName,
          index: object.index,
          objectIDs: [object.objectId.toString()]
        });
      }
    }
  }

  private getLsSearchHistory() {
    const lsSearchHistory = isPlatformBrowser(this.platformId) ? localStorage.getItem('Alm-LSH') : null;
    if (lsSearchHistory) {
      try {
        let parsedLsSearchHistory: string[] = JSON.parse(lsSearchHistory);
        this.localSearchHistory = Array.from(new Set(parsedLsSearchHistory));
      } catch (e) {
      }
    }
  }
}
