import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {CourseCardNewProgressComponent} from '@components/course-card-new/course-card-new-progress/course-card-new-progress.component';
import {CourseCardNewComponent} from '@components/course-card-new/course-card-new.component';
import {ImageLoaderModule} from '@directives/image-loader/image-loader.module';
import {TranslateModule} from '@ngx-translate/core';
import {AlmTranslateCutModule} from '@alm-translate-cut/alm-translate-cut.module';
import {TimeFromSecondsModule} from '@pipes/time-from-seconds/time-from-seconds.module';
import {RouterModule} from '@angular/router';
import {CustomHrefDirective} from "@directives/custom-href/custom-href.directive";


@NgModule({
  declarations: [CourseCardNewComponent, CourseCardNewProgressComponent],
  exports: [CourseCardNewComponent],
    imports: [
        CommonModule,
        ImageLoaderModule,
        TranslateModule,
        AlmTranslateCutModule,
        TimeFromSecondsModule,
        RouterModule,
        CustomHrefDirective,
    ]
})
export class CourseCardNewModule {
}
