import {Component, Inject, OnInit} from '@angular/core';
import {ISubscriptionPlan} from '@interfaces/subscription/subscription.interface';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {DialogService} from '@services/custom-dialogs/dialog.service';

@Component({
  selector: 'alm-root-confirmation-change-plan-dialog',
  templateUrl: './confirmation-change-plan-dialog.component.html',
  styleUrls: ['./confirmation-change-plan-dialog.component.scss']
})
export class ConfirmationChangePlanDialogComponent implements OnInit {
  newSelectedPlan?: ISubscriptionPlan;
  date = '';

  constructor(@Inject(MAT_DIALOG_DATA) private subData: { plan: ISubscriptionPlan, date: string },
              private dialogService: DialogService) {
  }

  ngOnInit(): void {
    this.newSelectedPlan = this.subData.plan;
    this.date = this.subData.date;
  }

  closeDialog(): void {
    this.dialogService.closeDialog('confirm-change-subscription-plan', true);
  }
}
