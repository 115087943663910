import {Injectable} from '@angular/core';
import {AuthDialogContainerComponent} from "@components/auth-dialog-container/auth-dialog-container.component";
import {UserManagerService} from "@services/user/user-manager.service";
import {DialogService} from "@services/custom-dialogs/dialog.service";
import {Subscription} from "rxjs";
import {BrazeUserEventsService} from "@services/marketing/braze/events/braze-user-events.service";

@Injectable({
  providedIn: 'root'
})
export class UserAfterLoginActionsService {

  constructor(private userManagerService: UserManagerService, private dialogService: DialogService, private brazeUserEventsService: BrazeUserEventsService) {
  }

  isFirstLogin(): Promise<boolean> {
    let isFirstLoginSub: Subscription | undefined;
    return new Promise<boolean>(resolve => {
      isFirstLoginSub = this.userManagerService.isFirstLogin.subscribe((firstLogin) => {
        if (firstLogin.loaded) {
          resolve(firstLogin.isFirstLogin);
        }
      });
    }).then((isFirstLogin) => {
      if (isFirstLoginSub) {
        isFirstLoginSub.unsubscribe();
      }
      return isFirstLogin;
    });
  }

  handleDemographicsPopup(): void {
    this.isFirstLogin().then((isFirstLogin) => {
      let userDetails = this.userManagerService.userInfo.getValue();
      if (isFirstLogin && userDetails && userDetails.loginCountDown < 5) {
        if (!userDetails.hasDemographicData || !userDetails.interests) {
          this.dialogService.openDialog(AuthDialogContainerComponent, 'auth',
            {
              type: userDetails.hasDemographicData ? 2 : 1,
              loginInfo: userDetails
            }, {disableClose: true});
        }
        if ( userDetails?.loginCountDown === 1) {
            this.brazeUserEventsService.logUserinfo(userDetails);
        }
      }
    });
  }
}
