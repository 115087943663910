import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ErrorSncakBarComponent } from './error-sncak-bar.component';
import {TranslateModule} from "@ngx-translate/core";



@NgModule({
  declarations: [ErrorSncakBarComponent],
    imports: [
        CommonModule,
        TranslateModule
    ]
})
export class ErrorSncakBarModule { }
