import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CertificateDialogContainerComponent } from './certificate-dialog-container.component';
import {MatDialogModule} from "@angular/material/dialog";
import {ImageLoaderModule} from "@directives/image-loader/image-loader.module";



@NgModule({
  declarations: [CertificateDialogContainerComponent],
  exports: [
    CertificateDialogContainerComponent
  ],
  imports: [
    CommonModule,
    MatDialogModule,
    ImageLoaderModule
  ]
})
export class CertificateDialogContainerModule { }
