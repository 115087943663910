<div class="user-profile__container" (click)="$event.stopPropagation()" *ngIf="userInfo">
  <div class="user-profile-card">
    <div class="user-profile-card__card-container"></div>
    <div class="user-profile-card__user-card-normal">
      <div class="user-profile-card__header-wrapper">
        <div class="user-profile-card__avatar-container" [routerLink]="'/settings'" (click)="itemClicked()">
          <img *ngIf="userAvatar" class="profile-image-dark user-profile-card_img" [imageLoader]="userAvatar"
               [isLazyLoadedImage]="false"
               errorImage="assets/images/default-avatar-placeholder-dark.jpg" alt="user-avatar"/>
          <img *ngIf="userAvatar" class="profile-image-light user-profile-card_img" [imageLoader]="userAvatar"
               [isLazyLoadedImage]="false"
               errorImage="assets/images/default-avatar-placeholder-light.jpg" alt="user-avatar"/>
        </div>
        <div class="user-profile-card__info-details-container">
          <h6 [routerLink]="'/settings'" (click)="itemClicked()">{{userInfo.name}}</h6>
          <p [routerLink]="'/settings'" (click)="itemClicked()">{{userInfo.email}}</p>
        </div>
      </div>
      <div *ngIf="!userInfo.subscribed && userInfo.userType !== 2" class="user-profile-card__subscribed-user">
        <button class="btn btn-outline-light user-profile-card__subscribe-btn"
                routerLink="/subscription-plans"
                (click)="itemClicked()">{{'header_user_menu.subscribe' | translate}}
        </button>
      </div>
      <div *ngIf="userInfo.subscribed" class="user-profile-card__subscribed-user">
        <div class="user-profile-card__subscribed-user_item plan" *ngIf="!isB2bUser && plan">
          <a [routerLink]="'/settings/subscription-plan'"
             (click)="itemClicked()">
            {{plan.name}}
          </a>
          <!--This section is kept hidden as the plan upgrade is not included in the ongoing subscription service migration-->
          <!--<a [routerLink]="'/settings/subscription-plan'" *ngIf="plan.duration <= 30 && !plan.isCancel && !plan.isChanged"
             (click)="itemClicked();onUpgradeClick()" class="upgrade-plan-link">
            {{'header_user_menu.upgrade' | translate}}</a>-->
        </div>
        <a class="user-profile-card__subscribed-user_item devices" [routerLink]="'/settings/devices'"
           (click)="itemClicked()" [class.b2b-user-devices]="isB2bUser">
          <svg>
            <use href="assets/images/sprite.svg#desktop"/>
          </svg>
          <span>{{'header_user_menu.devices' | translate}}</span>
        </a>
      </div>
    </div>
    <div class="user-profile-card__options-menu-list-container">
      <div class="user-profile-card__user-options-menu-list">
<!--        <a *ngIf="!isB2bUser" class="user-profile-card__user-option-item" [routerLink]="'/profile/my-courses'" (click)="itemClicked()">-->
<!--          <svg>-->
<!--            <use href="assets/images/sprite.svg#courses"/>-->
<!--          </svg>-->
<!--          <p>{{'header_user_menu.my_courses' | translate}}</p>-->
<!--        </a>-->
        <a class="user-profile-card__user-option-item" [routerLink]="'/my-progress'" (click)="itemClicked()">
          <svg>
            <use href="assets/images/sprite.svg#courses"/>
          </svg>
          <p>{{'header_user_menu.my_progress' | translate}}</p>
        </a>
        <a class="user-profile-card__user-option-item" [routerLink]="'/saved-list'" (click)="itemClicked()">
          <svg class="wishlist-icon">
            <use href="assets/images/sprite.svg#bookmark"/>
          </svg>
          <p>{{'header_user_menu.saved_courses' | translate}}</p>
        </a>
        <a class="user-profile-card__user-option-item user-option-item-responsive" [routerLink]="'/notifications'" (click)="itemClicked()">
          <svg class="wishlist-icon">
            <use href="assets/images/sprite.svg#bell"/>
          </svg>
          <p>{{'header_user_menu.notifications' | translate}}</p>
        </a>
        <a class="user-profile-card__user-option-item" [routerLink]="'my-certificates'" (click)="itemClicked()"
           [state]="{selectedIndex: 2}">
          <svg>
            <use href="assets/images/sprite.svg#certificate-badge"/>
          </svg>
          <p>{{'header_user_menu.certificate' | translate}}</p>
        </a>
        <a class="user-profile-card__user-option-item" [routerLink]="'/settings/personal'" (click)="itemClicked()" *ngIf="!isB2bUser">
          <svg>
            <use href="assets/images/sprite.svg#gears"/>
          </svg>
          <p>{{'header_user_menu.account_settings' | translate}}</p>
        </a>
        <a class="user-profile-card__user-option-item" [routerLink]="'/messages'" (click)="itemClicked()" *ngIf="!isB2bUser">
          <svg>
            <use href="assets/images/sprite.svg#messages"/>
          </svg>
          <p>{{'header_user_menu.messages' | translate | translateCut:0}}</p>
          <span class="user-profile-card__item-notification"
                *ngIf="numberOfUnreadMessages && numberOfUnreadMessages > 0"> {{numberOfUnreadMessages}} {{'header_user_menu.messages' | translate | translateCut:1}}</span>
        </a>
      </div>
      <a class="user-profile-card__purchase-log user-profile-card__user-option-item" (click)="itemClicked()"
         [routerLink]="'/settings/purchase-log'" *ngIf="!isB2bUser">
        <svg>
          <use href="assets/images/sprite.svg#log"/>
        </svg>
        <p>
          {{'header_user_menu.purchase_log' | translate}}
        </p>
      </a>
      <div class="user-profile-card__b2b-options-list" *ngIf="isB2bUser">
        <a class="user-profile-card__user-option-item" [routerLink]="'/messages'" (click)="itemClicked()">
          <svg>
            <use href="assets/images/sprite.svg#messages"/>
          </svg>
          <p>{{'header_user_menu.messages' | translate | translateCut:0}}</p>
          <span class="user-profile-card__item-notification"
                *ngIf="numberOfUnreadMessages && numberOfUnreadMessages > 0"> {{numberOfUnreadMessages}} {{'header_user_menu.messages' | translate | translateCut:1}}</span>
        </a>
        <a class="user-profile-card__user-option-item" [routerLink]="'/settings/personal'" (click)="itemClicked()">
          <svg>
            <use href="assets/images/sprite.svg#gears"/>
          </svg>
          <p>{{'header_user_menu.account_settings' | translate}}</p>
        </a>
      </div>

    </div>
    <div class="user-profile-card__menu-quick-links" *ngIf="!isB2bUser">
      <a class="user-profile-card__link-underline" (click)="itemClicked()" routerLink="/become-mentor"
         *ngIf="!userInfo.isMentor">
        {{'header_user_menu.become_mentor' | translate | translateCut:0}}</a>
      <a class="user-profile-card__link-underline" (click)="switchToMentorLogin()" *ngIf="userInfo.isMentor">
        {{'header_user_menu.become_mentor' | translate | translateCut:1}}</a>
      <!--      <a class="user-profile-card__link-underline" (click)="itemClicked()">-->
      <!--        {{'header_user_menu.almentor_business' | translate}}-->
      <!--        <svg>-->
      <!--          <use href="assets/images/sprite.svg#arrow-forward"/>-->
      <!--        </svg>-->
      <!--      </a>-->
    </div>
    <a class="btn btn-link user-profile-card__btn-logout"
       [class.subscribed-user-logout-btn]="userInfo.subscribed"
       (click)="logOutUser();itemClicked()">
      <svg>
        <use href="assets/images/sprite.svg#logout"/>
      </svg>
      {{'header_user_menu.logout' | translate}}</a>
  </div>

</div>
