import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BundleCardComponent } from './bundle-card.component';
import {TranslateModule} from '@ngx-translate/core';
import {ImageLoaderModule} from "@directives/image-loader/image-loader.module";
import {AlmTranslateCutModule} from '@alm-translate-cut/alm-translate-cut.module';



@NgModule({
    declarations: [BundleCardComponent],
    exports: [
        BundleCardComponent
    ],
    imports: [
        CommonModule,
        TranslateModule,
        ImageLoaderModule,
        AlmTranslateCutModule
    ]
})
export class BundleCardModule { }
