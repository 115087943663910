import { Injectable } from '@angular/core';
import {LanguageControlService} from '@services/language/language-control.service';
import {BrazeService} from '@services/marketing/braze/braze.service';
import {ThemeControlService, THEMES} from '@services/theme/theme-control.service';

export enum BrazeGenders {
  MALE = 'm',
  FEMALE = 'f',
  OTHER = 'o',
  UNKNOWN = 'u',
  NOT_APPLICABLE = 'n',
  PREFER_NOT_TO_SAY = 'p',
}

export interface IBrazeDemographics {
  gender: BrazeGenders;
  birthDate?: Date;
  country: string;
  city: string;
  mobileNumber?: string;
}
@Injectable({
  providedIn: 'root'
})
export class BrazeUserAttributesService {

  constructor(
    private brazeService: BrazeService,
    private languageControl: LanguageControlService,
    private themeControl: ThemeControlService
  ) { }

  setUserLanguage(languageCode: string): void {
    this.brazeService.setCustomUserAttribute('user_lang', languageCode);
  }

  setUserTheme(theme: THEMES): void {
    this.brazeService.setCustomUserAttribute('user_theme', theme === THEMES.LIGHT ? 'LIGHT' : 'DARK');
  }

  setUserDemographics(demographics: IBrazeDemographics): void {
    this.brazeService.braze?.getUser()?.setGender(demographics.gender);
    this.brazeService.setCustomUserAttribute('selected_country', demographics.country);
    this.brazeService.setCustomUserAttribute('selected_city', demographics.city);
    this.setUserPhone(demographics.mobileNumber);
    if (demographics.birthDate) {
      this.brazeService.braze?.getUser()?.setDateOfBirth(
        demographics.birthDate.getFullYear(),
        demographics.birthDate.getMonth() + 1,
        demographics.birthDate.getDate()
      );
    }
  }

  setUserPhone(mobileNumber?: string): void {
    if (mobileNumber) {
      this.brazeService.braze?.getUser()?.setPhoneNumber(mobileNumber);
    }
  }
}
