import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MainHeaderComponent } from './main-header.component';
import {RouterModule} from "@angular/router";
import {HeaderModule} from "@components/header/header.module";
import {ImageLoaderModule} from "@directives/image-loader/image-loader.module";
import {MatMenuModule} from "@angular/material/menu";
import {TopRibbonModule} from "@components/top-ribbon/top-ribbon.module";
import {CampaignRibbonModule} from "@components/campaign-ribbon/campaign-ribbon.module";
import {GtmRibbonModule} from "@components/gtm-ribbon/gtm-ribbon.module";



@NgModule({
  declarations: [MainHeaderComponent],
  exports: [
    MainHeaderComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    HeaderModule,
    ImageLoaderModule,
    MatMenuModule,
    TopRibbonModule,
    CampaignRibbonModule,
    GtmRibbonModule
  ]
})
export class MainHeaderModule { }
