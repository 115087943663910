import { Injectable } from '@angular/core';
import {SHA256, HmacSHA256, enc as ENC} from 'crypto-js';

/**
 * An encryption service to provide the needed encryption all over the system
 */
@Injectable({
  providedIn: 'root'
})
export class EncryptionService {

  constructor() {
  }

  /**
   * Hash a string with SHA256 algorithm
   * @param string
   */
  hashStringWithSHA256(string: string): string {
    return SHA256(string).toString();
  }

  /**
   * Hash a string with a salt key with HmacSHA256 algorithm
   * @param string
   * @param key
   */
  hashStringWithHmacSHA256(string: string, key: string): string {
    return HmacSHA256(string, key).toString();
  }

  /**
   * Encode a string to Base64
   * @param string
   */
  encodeStringToBase64(string: string): string{
    return ENC.Base64.stringify(ENC.Utf8.parse(string));
  }

  /**
   * check wither string is Base64
   * @param string
   */
  isValidBase64(string: string): boolean {
    const base64Pattern = /^([A-Za-z0-9+/]{4})*([A-Za-z0-9+/]{3}=|[A-Za-z0-9+/]{2}==)?$/;
    return base64Pattern.test(string);
  }

  /**
   * Decode a base64 to a string
   * @param base64String
   */
  decodeBase64ToString(base64String: string): string{
    return ENC.Utf8.stringify(ENC.Base64.parse(base64String));
  }

  /**
   * Normalizes and hashes a given string.
   *
   * @param inputString - The string to be normalized and hashed.
   * @returns The normalized and hashed string, or undefined if inputString is undefined or empty.
   */
  normalizeAndHashString(inputString?: string): string | undefined {
    return inputString ? this.hashStringWithSHA256(inputString.trim().toLowerCase()) : undefined;
  }
}
