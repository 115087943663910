import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AlmShareButtonsComponent } from './alm-share-buttons.component';
import {MatDialogModule} from "@angular/material/dialog";
import { AlmShareButtonComponent } from './alm-share-button/alm-share-button.component';
import {MatButtonModule} from "@angular/material/button";
import {ShareButtonModule} from "ngx-sharebuttons/button";
import {TranslateModule} from "@ngx-translate/core";
import {AlmTranslateCutModule} from '@alm-translate-cut/alm-translate-cut.module';



@NgModule({
  declarations: [AlmShareButtonsComponent, AlmShareButtonComponent],
  exports: [
    AlmShareButtonsComponent,
    AlmShareButtonComponent
  ],
  imports: [
    CommonModule,
    MatDialogModule,
    MatButtonModule,
    ShareButtonModule,
    TranslateModule,
    AlmTranslateCutModule,
  ]
})
export class AlmShareButtonsModule { }
