import {Injectable} from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import {Observable} from 'rxjs';
import {UserService} from "@services/integrations/user/user.service";
import {AppRedirectsService} from "@services/app-redirects/app-redirects.service";
import {UiLoaderService} from "@services/ui-loader/ui-loader.service";
import {ConnectorV2Service} from "@services/connector/connector-v2.service";
import {UserManagerService} from "@services/user/user-manager.service";

@Injectable({
  providedIn: 'root'
})
export class DevicesGuard  {

  constructor(private userManagerService: UserManagerService, private router: Router, private appRedirects: AppRedirectsService,
              private uiLoader: UiLoaderService, private connectorService: ConnectorV2Service) {
  }

  private checkUserAuth(state: RouterStateSnapshot, route: ActivatedRouteSnapshot): Promise<boolean> {
    return new Promise<boolean>(resolve => {
      this.userManagerService.checkUserAuthorization(route, this.userManagerService.userPlatform)
        .then(({
                 authorized,
                 reachedDevicesLimit
               }) => {
          if (authorized) {
            if (reachedDevicesLimit) {
              this.connectorService.connectorLoaded.next(true);
              this.connectorService.connectorLoaded.complete();
              resolve(true);
            } else {
              this.connectorService.connectorLoaded.next(true);
              this.connectorService.connectorLoaded.complete();
              resolve(false);
              this.router.navigate(['/home']);
            }
          } else {
            if (reachedDevicesLimit) {
              this.connectorService.connectorLoaded.next(true);
              this.connectorService.connectorLoaded.complete();
              resolve(true);
            } else {
              resolve(false);
              this.redirectToRedirectCenter(this.userManagerService.userPlatform, state.url, 'prevErr=001');
            }
          }
        }).catch((e) => {
        resolve(false);
        this.redirectToRedirectCenter(this.userManagerService.userPlatform, state.url, 'prevErr=001');
      });
    });
  }

  private redirectToRedirectCenter(platform: string | null, redirectPath: string, extraParams?: string) {
    this.uiLoader.startUiLoader('outside-loader');
    this.appRedirects.redirectToSSO(platform, redirectPath, 'redirect-center', undefined, extraParams);
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.checkUserAuth(state, route);
  }

}
