import {Inject, Injectable, PLATFORM_ID} from '@angular/core';
import {EncryptionService} from "@services/request-token/encryption.service";

/**
 * A service to provide the request token for every request to the backend
 */
@Injectable({
  providedIn: 'root'
})
export class TokenService {

  constructor(private encryptionService: EncryptionService, @Inject(PLATFORM_ID) private platformId: any) {
  }

  /**
   * Build The Request Token Header Base64 and the header object
   * @param args: { sender: string, receiver: string, timestamp: string, uuid?: string }
   * @private
   */
  private buildRequestHeader(args: { sender: string, receiver: string, timestamp: string, platformCode: string | null, uuid?: string }): { header: Object, base64: string } {
    let header = {
      sender: args.sender,
      receiver: args.receiver,
      timestamp: args.timestamp,
      platform: args.platformCode,
      uuid: args.uuid ? args.uuid : '0000-0000-0000-0000'
    };
    let headerBase64 = this.encryptionService.encodeStringToBase64(JSON.stringify(header));
    return {
      header: header,
      base64: headerBase64
    }
  }

  /**
   * Get the first four characters of a string with padding
   * @param string
   */
  private getFirstFourCharsFromStringWithPadding = (string: string): string => {
    let newString = '';
    for (let i = 0; i < 4; i++) {
      let indexChar = string[i];
      newString = newString + (indexChar ? indexChar : '0');
    }
    return newString;
  }

  /**
   * Build the secret for the request token
   * @param args: { sender: string, receiver: string, timestamp: string }
   * @private
   */
  private buildRequestSecret(args: { sender: string, receiver: string, timestamp: string }): string {
    let senderFirstFourChars = this.getFirstFourCharsFromStringWithPadding(args.sender);
    let receiverFirstFourChars = this.getFirstFourCharsFromStringWithPadding(args.receiver);
    let key = senderFirstFourChars + receiverFirstFourChars;
    return this.encryptionService.hashStringWithHmacSHA256(args.timestamp, key);
  }

  /**
   * Build the auth base64 for the request token
   * "Auth comes from cookie"
   * @private
   */
  private buildRequestAuth(): string {
    // let authLocalStorage = isPlatformBrowser(this.platformId) ? localStorage.getItem('auth') : null;
    return this.encryptionService.encodeStringToBase64(JSON.stringify({}));
  }

  /**
   * Build the payload of the request token
   * @param payload: Object
   * @private
   */
  private buildRequestPayload(payload: Object): { payload: Object, base64: string } {
    let payloadBase64 = this.encryptionService.encodeStringToBase64(JSON.stringify(payload));
    return {
      payload: payload,
      base64: payloadBase64
    };
  }

  /**
   * Build the request token signature to sign the token
   * @param args: { headerBase64: string, payloadBase64: string, authBase64: string, secret: string }
   * @private
   */
  private buildRequestSignature(args: { headerBase64: string, payloadBase64: string, authBase64: string, secret: string }): string {
    let signatureString = `${args.headerBase64}.${args.payloadBase64}.${args.authBase64}.${args.secret}`;
    return this.encryptionService.hashStringWithSHA256(signatureString);
  }

  /**
   * Build the request token to be sent to the backend
   * @param request: { sender: string, receiver: string, body: Object, uuid?: string }
   */
  buildRequestToken(request: { sender: string, receiver: string, body: Object, platform: string | null, uuid?: string }): string {
    let timestamp = Date.now().toString();
    let requestHeader = this.buildRequestHeader({
      sender: request.sender,
      receiver: request.receiver,
      timestamp: timestamp,
      uuid: request.uuid,
      platformCode: request.platform
    });
    let requestSecret = this.buildRequestSecret({
      sender: request.sender,
      receiver: request.receiver,
      timestamp: timestamp
    })
    let requestPayload = this.buildRequestPayload(request.body);
    let requestAuth = this.buildRequestAuth();
    let requestSignature = this.buildRequestSignature({
      headerBase64: requestHeader.base64,
      authBase64: requestAuth,
      payloadBase64: requestPayload.base64,
      secret: requestSecret
    });
    return `${requestHeader.base64}.${requestPayload.base64}.${requestAuth}.${requestSignature}`;
  }
}
