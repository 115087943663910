import {Injectable} from '@angular/core';
import {CustomHttpService} from '@services/request-token/custom-http.service';
import {ICity, ICountry} from '@interfaces/common/auth.interface';
import {ISimplifiedCategory} from '@interfaces/course/category.interface';
import {ClusterNames} from '@interfaces/common/http.interface';

@Injectable({
  providedIn: 'root'
})
export class LookupsHttpService {

  constructor(private customHttpService: CustomHttpService) {
  }

  getAllCountriesByLanguageId(sender: string, languageId: number): Promise<ICountry[]> {
    return this.customHttpService.sendRequest({
      clusterName: ClusterNames.JavaOrch,
      endpoint: 'api/common/GetAllCountriesByLanguageID',
      sender: sender,
      receiver: 'GetAllCountriesByLanguageID',
      body: {languageId: languageId}
    });
  }

  getAllMentorsCountriesByLanguageId(sender: string, languageId: number): Promise<ICountry[]> {
    return this.customHttpService.sendRequest({
      clusterName: ClusterNames.NewOrch,
      endpoint: 'getCountry/api/common/mentor/getCountries',
      sender: sender,
      receiver: 'getCountries',
      body: {languageId: languageId}
    });
  }

  getAllCitiesByCountryId(sender: string, entityId: number, languageId: number): Promise<ICity[]> {
    return this.customHttpService.sendRequest({
      clusterName: ClusterNames.JavaOrch,
      endpoint: 'api/common/GetAllCitiesByCountryID',
      sender: sender,
      receiver: 'GetAllCitiesByCountryID',
      body: {entityId: entityId, languageId: languageId}
    });
  }

  getAllCategoryByLanguageId(sender: string, languageId: number, categoryTypeId: number): Promise<ISimplifiedCategory[]> {
    return this.customHttpService.sendRequest({
      clusterName: ClusterNames.NewOrch,
      endpoint: 'getCategory/api/categories/list',
      sender: sender,
      receiver: 'list',
      body: {
        languageId: languageId,
        categoryTypeId: categoryTypeId
      }
    });
  }

  getPhoneCode(sender: string): Promise<{phoneCode: string}> {
    return  this.customHttpService.sendRequest({
      clusterName: ClusterNames.NewOrch,
      endpoint: 'SSO/api/sso/phonecode',
      sender: sender,
      receiver: 'phoneCode',
      body: {}
    });
  }

}
