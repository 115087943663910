import {Injectable} from '@angular/core';
import {MatSnackBar} from "@angular/material/snack-bar";
import {ErrorSncakBarComponent} from "@components/error-sncak-bar/error-sncak-bar.component";
import {TranslateService} from "@ngx-translate/core";
import {InfoSnackBarComponent} from "@components/info-snack-bar/info-snack-bar.component";
import {lastValueFrom} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class SnackBarControlService {

  constructor(private snackBarService: MatSnackBar, private translateService: TranslateService) {
  }

  openHttpErrorSnackbar(code: number, action?: {text: string, callback: () => void}) {
    let httpKey = `http_error.${code}`;
    let translation = this.translateService.instant(httpKey);
    if (translation == httpKey || translation == '') {
      httpKey = `http_error.0`;
    }
    return this.openErrorSnackbar(httpKey, action);
  }

  openErrorSnackbar(error: string, action?: {text: string, callback: () => void}) {
    return lastValueFrom(this.snackBarService.openFromComponent(ErrorSncakBarComponent, {
      data: {error: error, action},
      panelClass: 'error-snackbar',
      horizontalPosition: 'center',
      verticalPosition: 'top',
      direction: 'ltr',
      duration: 5000,
    }).afterDismissed());
  }

  openInfoSnackbar(message: string) {
    return lastValueFrom(this.snackBarService.openFromComponent(InfoSnackBarComponent, {
      data: {info: message},
      panelClass: 'info-snackbar',
      horizontalPosition: 'center',
      verticalPosition: 'top',
      direction: 'ltr',
      duration: 5000,
    }).afterDismissed());
  }

  closeAllSnackBars() {
    this.snackBarService.dismiss();
  }
}
