<div class="footer-explore" [class.b2b-user]="isB2BUser">
  <h5>{{'footer.footer_explore_title' | translate}}</h5>
  <ul>
    <li><a routerLink="/courses">
      {{'footer.footer_explore_items' | translate | translateCut: 0}}
    </a></li>
    <li *ngIf="!(customContentService.hasCustomContent$ | async)"><a routerLink="/courses/popular">
      {{'footer.footer_explore_items' | translate | translateCut: 6}}
    </a></li>
<!--    <li><a routerLink="/bundles" *ngIf="!userSubscribed && !isB2BUser">-->
<!--      {{'footer.footer_explore_items' | translate | translateCut: 7}}-->
<!--    </a></li>-->
<!--    <li *ngIf="!userSubscribed && !isB2BUser"><a routerLink="/offers">-->
<!--      {{'footer.footer_explore_items' | translate | translateCut: 1}}-->
<!--    </a></li>-->
    <li *ngIf="!userSubscribed && !isB2BUser"><a routerLink="/subscription-plans">
      {{'footer.footer_explore_items' | translate | translateCut: 5}}
    </a></li>
<!--    <li><a routerLink="/courses">-->
<!--      {{'footer.footer_explore_items' | translate | translateCut: 2}}-->
<!--    </a></li>-->
    <li *ngIf="!(customContentService.hasCustomContent$ | async)"><a routerLink="/mentors">
      {{'footer.footer_explore_items' | translate | translateCut: 3}}
    </a></li>
    <li *ngIf="!(customContentService.hasCustomContent$ | async)"><a routerLink="/partners">
      {{'footer.footer_explore_items' | translate | translateCut: 4}}
    </a></li>
  </ul>
</div>
