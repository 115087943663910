<div class="main-footer__container">
  <div class="main-footer__container_logo_wrapper">
    <svg>
      <use href="assets/images/sprite.svg#logo-small" />
    </svg>
    <p class="main-footer__container_logo_desc">
      {{'footer.b2b_footer_desc_text' | translate}}
    </p>
  </div>

  <div class="main-footer__container_almentor-links">
    <alm-root-footer-almentor-v2></alm-root-footer-almentor-v2>
  </div>
  <div class="main-footer__container_explore-links">
    <alm-root-footer-explore [isB2BUser]="true"></alm-root-footer-explore>
  </div>
</div>
