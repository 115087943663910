import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {VideoPlayerPreviewComponent} from './video-player-preview.component';
import {MatDialogModule} from "@angular/material/dialog";
import {VideoPlayerModule} from "@components/video-player/video-player.module";


@NgModule({
  declarations: [VideoPlayerPreviewComponent],
    imports: [
        CommonModule,
        MatDialogModule,
        VideoPlayerModule,
    ]
})
export class VideoPlayerPreviewModule { }
