import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {VerificationInputDialogComponent} from './verification-input-dialog.component';
import {OtpInputModule} from "@components/otp-input/otp-input.module";
import {TranslateModule} from "@ngx-translate/core";
import {MatDialogModule} from "@angular/material/dialog";
import {AlmTranslateCutModule} from '@alm-translate-cut/alm-translate-cut.module';


@NgModule({
  declarations: [VerificationInputDialogComponent],
  imports: [
    CommonModule,
    OtpInputModule,
    TranslateModule,
    MatDialogModule,
    AlmTranslateCutModule,
  ]
})
export class VerificationInputDialogModule {
}
