import {Injectable} from '@angular/core';
import {LanguageControlService} from "@services/language/language-control.service";
import {UserCartHttpService} from "@services/integrations/user-cart/user-cart-http.service";
import {BehaviorSubject} from "rxjs";
import {IShoppingCartInfo} from "@interfaces/shopping-cart/shopping-cart-list.interface";

@Injectable({
  providedIn: 'root'
})
export class UserCartService {

  cartUpdated: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(private userCartHttpService: UserCartHttpService, private languageControl: LanguageControlService) {
  }

  getAllItemsInShoppingCart(sender: string): Promise<IShoppingCartInfo> {
    let languageId = this.languageControl.currentLanguage.getValue().id;
    return this.userCartHttpService.getAllItemsInShoppingCart(sender, languageId);
  }

  addItemToShoppingCart(sender: string, itemId: number, cartItemType: number): Promise<{ result: true }> {
    return this.userCartHttpService.addItemToShoppingCart(sender, itemId, cartItemType).then((res) => {
      this.cartUpdated.next(true);
      return res;
    });
  }

  deleteItemFromShoppingCart(sender: string, id: number, cartItemType: number): Promise<{ result: true }> {
    return this.userCartHttpService.deleteItemFromShoppingCart(sender, id, cartItemType).then((res) => {
      this.cartUpdated.next(true);
      return res;
    });
  }

  getCartItemNumber(): Promise<{ numberOfCart: number }> {
    return this.userCartHttpService.getCartItemNumber();
  }

  transferToWishlist(sender: string, itemId: number, cartItemType: number): Promise<{ result: true }> {
    return this.userCartHttpService.transferToWishlist(sender, itemId, cartItemType).then((res) => {
      this.cartUpdated.next(true);
      return res;
    });
  }
}
