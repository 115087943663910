import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {DeviceManagementComponent} from './device-management.component';
import {TranslateModule} from "@ngx-translate/core";
import {AlmTranslateCutModule} from '@alm-translate-cut/alm-translate-cut.module';


@NgModule({
  declarations: [DeviceManagementComponent],
  exports: [
    DeviceManagementComponent
  ],
  imports: [
    CommonModule,
    TranslateModule,
    AlmTranslateCutModule
  ]
})
export class DeviceManagementModule {
}
