<div class="info-snackbar-container" *ngIf="message">
  <div class="info-snackbar-container__message">
    <p>{{message | translate }}</p>
  </div>
  <div class="info-snackbar-container__close">
    <button class="btn btn-icon" (click)="dismiss()">
      <svg aria-hidden="true">
        <use href="assets/images/sprite.svg#times"/>
      </svg>
    </button>
  </div>
</div>
