import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppAnnouncementBottomSheetComponent } from './app-announcement-bottom-sheet.component';
import {MatBottomSheetModule} from "@angular/material/bottom-sheet";
import {TranslateModule} from '@ngx-translate/core';


@NgModule({
  declarations: [AppAnnouncementBottomSheetComponent],
  imports: [
    CommonModule,
    MatBottomSheetModule,
    TranslateModule,
  ]
})
export class AppAnnouncementBottomSheetModule { }
