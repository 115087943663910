import { Injectable } from '@angular/core';
import {DynamicLoadService} from "@services/dynamic-load/dynamic-load.service";

declare var Userback: any;

@Injectable({
  providedIn: 'root'
})
export class UserBackService {

  ub: any;

  constructor(private dynamicLoadService: DynamicLoadService) { }

  loadUBScript(): Promise<any> {
    return new Promise<any>(resolve => {
      if (this.ub)
        resolve(true);
      this.dynamicLoadService.externalScript('userBack-jssdk',
        'https://static.userback.io/widget/v1.js')
        .then((loaded: boolean) => {
          Userback = Userback || {};
          Userback.access_token = '29216|73526|bo6DrOq0WgEnZqXqTUxN0nPKY';
          resolve(Userback);
        });
    });
  }
}
