import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { VideoPlayerComponent } from './video-player.component';
import {AppModule} from '../../app.module';
import {VideoPlayerControlsComponent} from '@components/video-player-controls/video-player-controls.component';
import {MatSliderModule} from '@angular/material/slider';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {MatMenuModule} from '@angular/material/menu';
import {FullscreenOverlayContainer, OverlayContainer} from '@angular/cdk/overlay';
import {MatListModule} from "@angular/material/list";
import {TranslateModule} from "@ngx-translate/core";
import {LangBasedDirectionDirective} from "@services/language/lang-based-direction.directive";



@NgModule({
    declarations: [
    VideoPlayerComponent,
    VideoPlayerControlsComponent
    ],
    exports: [
        VideoPlayerComponent,
        VideoPlayerControlsComponent,
    ],
  imports: [
    CommonModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatButtonModule,
    MatIconModule,
    MatMenuModule,
    MatListModule,
    TranslateModule,
    LangBasedDirectionDirective,
  ],
    providers: [
    ]
})
export class VideoPlayerModule { }
