<div class="header-search form-container">
  <div class="header-search_form-input">
    <div class="search-input-field">
      <mat-form-field (keyup.enter)="onSearchSubmit(searchAutocompleteTrigger)"
                      (focusin)="initSearchHistory()" class="form-group w-100" appearance="outline">
        <input matInput class="form-control" #searchInputElement #searchAutocompleteTrigger="matAutocompleteTrigger"
               [almMatInputAutofocus]="autoFocus"
               placeholder="{{'header_search.placeholder' | translate}}" [matAutocomplete]="searchAutocomplete"
               name="searchInput" type="text" aria-label="Search" [formControl]="searchInput"/>
      </mat-form-field>
      <button type="button" (click)="onSearchSubmit(searchAutocompleteTrigger)">
        <span class="sr-only">{{'header_search.btn' | translate}}</span>
        <svg>
          <use href="assets/images/sprite.svg#search"/>
        </svg>
      </button>
    </div>
  </div>
  <mat-autocomplete [disableRipple]="true" (opened)="onAutocompleteOpened(searchAutocomplete)"
                    [class]="'header-search__autocomplete'"
                    #searchAutocomplete="matAutocomplete">
    <div class="header-search__autocomplete-options-container"
         *ngIf="searchInput.value.length < 4 && searchHistory.length > 0">
      <p class="header-search__autocomplete-options-title">
        {{'header_search.recent_search' | translate}}</p>
      <mat-option *ngFor="let searchItem of searchHistory" [value]="searchItem"
                  (click)="onSearchSubmit(searchAutocompleteTrigger)">
        <div class="header-search__autocomplete-option-item">
          <svg>
            <use href="assets/images/sprite.svg#timer"/>
          </svg>
          <p>{{searchItem}}</p>
          <svg class="header-search__autocomplete-option-item-delete"
               (click)="onHistorySearchDelete(searchItem, $event)">
            <use href="assets/images/sprite.svg#times"/>
          </svg>
        </div>
      </mat-option>
    </div>
    <div class="header-search__autocomplete-options-container" *ngIf="searchInput.value.length > 2 &&
     (searchSuggestions.words.length > 0 || searchSuggestions.courses.length > 0 || searchSuggestions.bundles.length > 0 || searchSuggestions.mentors.length > 0)">
      <p class="header-search__autocomplete-options-title">{{'header_search.suggested' | translate}}</p>
      <div *ngIf="searchSuggestions.words.length > 0">
        <mat-option *ngFor="let searchWord of searchSuggestions.words" [value]="searchWord.objectID"
                    (click)="onSearchSubmit(searchAutocompleteTrigger)">
          <div class="header-search__autocomplete-option-item">
            <svg>
              <use href="assets/images/sprite.svg#search"/>
            </svg>
            <p>{{searchWord.objectID}}</p>
          </div>
        </mat-option>
      </div>
      <div *ngIf="searchSuggestions.courses.length > 0">
        <mat-option *ngFor="let searchCourse of searchSuggestions.courses" [value]="currentLang == 'ar' ? searchCourse.arName : searchCourse.enName"
                    (click)="goCourse(searchAutocompleteTrigger, searchCourse.permanentLink)">
          <div class="header-search__autocomplete-option-item">
            <svg>
              <use href="assets/images/sprite.svg#courses"/>
            </svg>
            <p>{{currentLang == 'ar' ? searchCourse.arName : searchCourse.enName}}</p>
          </div>
        </mat-option>
      </div>
<!--      <div *ngIf="searchSuggestions.bundles.length > 0">-->
<!--        <mat-option *ngFor="let searchBundle of searchSuggestions.bundles" [value]="searchBundle.name"-->
<!--                    (click)="goBundle(searchAutocompleteTrigger, searchBundle.id)">-->
<!--          <div class="header-search__autocomplete-option-item">-->
<!--            <svg>-->
<!--              <use href="assets/images/sprite.svg#bundle"/>-->
<!--            </svg>-->
<!--            <p>{{searchBundle.name}}</p>-->
<!--          </div>-->
<!--        </mat-option>-->
<!--      </div>-->
      <div *ngIf="searchSuggestions.mentors.length > 0">
        <mat-option *ngFor="let searchMentor of searchSuggestions.mentors" [value]="searchMentor.mentorEnName ? (currentLang == 'ar' ? searchMentor.mentorArName : searchMentor.mentorEnName) : (currentLang == 'ar' ? searchMentor.partnerArName : searchMentor.partnerEnName)"
                    (click)="goMentor(searchAutocompleteTrigger, searchMentor.permanentLink)">
          <div class="header-search__autocomplete-option-item">
            <svg>
              <use href="assets/images/sprite.svg#learner"/>
            </svg>
            <p>{{searchMentor.mentorEnName ? (currentLang == 'ar' ? searchMentor.mentorArName : searchMentor.mentorEnName) : (currentLang == 'ar' ? searchMentor.partnerArName : searchMentor.partnerEnName)}}</p>
          </div>
        </mat-option>
      </div>
    </div>
  </mat-autocomplete>
</div>
