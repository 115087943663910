import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HorizontalCourseCardComponent } from './horizontal-course-card.component';
import { HorizontalCourseCardNormalComponent } from './horizontal-course-card-normal/horizontal-course-card-normal.component';
import { HorizontalCourseCardPremiumComponent } from './horizontal-course-card-premium/horizontal-course-card-premium.component';
import { HorizontalCourseCardProgressComponent } from './horizontal-course-card-progress/horizontal-course-card-progress.component';
import { HorizontalCourseCardStartLearningComponent } from './horizontal-course-card-start-learning/horizontal-course-card-start-learning.component';
import {ImageLoaderModule} from '@directives/image-loader/image-loader.module';
import {TranslateModule} from '@ngx-translate/core';
import {AlmTranslateCutModule} from '@alm-translate-cut/alm-translate-cut.module';
import {TimeFromSecondsModule} from "@pipes/time-from-seconds/time-from-seconds.module";
import {RouterModule} from "@angular/router";



@NgModule({
    declarations: [HorizontalCourseCardComponent, HorizontalCourseCardNormalComponent, HorizontalCourseCardPremiumComponent, HorizontalCourseCardProgressComponent, HorizontalCourseCardStartLearningComponent],
    exports: [
        HorizontalCourseCardComponent
    ],
    imports: [
        CommonModule,
        ImageLoaderModule,
        TranslateModule,
        AlmTranslateCutModule,
        TimeFromSecondsModule,
        RouterModule,
    ]
})
export class HorizontalCourseCardModule { }
