import {Component, Input, OnInit, ViewEncapsulation} from '@angular/core';
import ar from "@components/courses-menu-v2/i18n/ar.json";
import en from "@components/courses-menu-v2/i18n/en.json";
import {LanguageControlService} from "@services/language/language-control.service";
import {LookupsService} from "@services/integrations/lookups/lookups.service";
import {ISimplifiedCategory} from "@interfaces/course/category.interface";
import {UserManagerService} from "@services/user/user-manager.service";
import {CoursesService} from "@services/integrations/courses/courses.service";
import {B2bHomeBannerService} from "@services/integrations/b2b-home-banner/b2b-home-banner.service";

@Component({
  selector: 'alm-root-courses-menu-v2',
  templateUrl: './courses-menu-v2.component.html',
  styleUrls: ['./courses-menu-v2.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CoursesMenuV2Component implements OnInit {
  @Input() isB2bUser?: boolean | null;
  @Input() currentLang: string = 'en'
  allCategories: ISimplifiedCategory [] = [];
  organizationCategories: ISimplifiedCategory [] = [];
  selectedCategoryId: number | undefined;
  selectedCategoryPermanentLink: string | undefined;
  isB2bCategoriesSelected: boolean = false;
  userSubscribed?: boolean;
  b2bOrganizationName: string = '';

  constructor(private languageControl:LanguageControlService,
              private lookupsService: LookupsService,
              private userManagerService: UserManagerService,
              private coursesService: CoursesService,
              private b2bHomeBannerService: B2bHomeBannerService) {
    this.languageControl.setTranslations('ar', ar);
    this.languageControl.setTranslations('en', en);
  }

  ngOnInit(): void {
    this.userSubscribed = this.userManagerService.userInfo.getValue()?.subscribed;
    if(this.isB2bUser) {
      this.getRemoteOrganizationCategories().finally(() => {
        this.getAllCategories().finally(() => {
          this.b2bHomeBannerService.getB2bOrganizationInfo("courses-menu").then((organization) => {
            this.b2bOrganizationName = this.currentLang === 'ar' ? organization.nameAr : organization.nameEn;
          });
          });
      });
    } else {
      this.getAllCategories().finally(() => {
      });
    }
  }

  private getAllCategories() {
    return this.lookupsService.getAllCategories('header',1).then((categories) => {
      this.allCategories = categories;
      return categories;
    }).catch(() => [])
  }

  private getRemoteOrganizationCategories() {
    return this.coursesService.getB2BCategories("header", {
      page:0,
      size: 1000
    }).then((organizationCategories) => {
      this.organizationCategories = organizationCategories.items;
      return organizationCategories;
    }).catch(() => [])
  }

  getCoursesByCategoryId(categoryId: number): void {
    this.isB2bCategoriesSelected = false;
    this.selectedCategoryId = categoryId;
  }

  getCategoryPermanentLink(permanentLink: string): void {
    this.selectedCategoryPermanentLink = permanentLink;
  }

  viewOrganizationCategories(mouseEntered: boolean): void {
    if(mouseEntered) {
      this.selectedCategoryId = undefined;
    }
    this.isB2bCategoriesSelected = mouseEntered;
  }
}
