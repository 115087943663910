import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatInputAutofocusDirective } from './mat-input-autofocus.directive';



@NgModule({
  declarations: [MatInputAutofocusDirective],
  exports: [
    MatInputAutofocusDirective
  ],
  imports: [
    CommonModule
  ]
})
export class MatInputAutofocusModule { }
