import {AfterViewInit, Directive, Input, OnChanges, OnInit} from '@angular/core';
import {MatInput} from '@angular/material/input';

@Directive({
  selector: '[almMatInputAutofocus]'
})
export class MatInputAutofocusDirective implements OnChanges {

  @Input('almMatInputAutofocus') autoFocus: boolean = false;

  constructor(private matInput: MatInput) {
  }

  ngOnChanges(): void {
    if (this.autoFocus) {
      setTimeout(() => {
        this.matInput.focus();
      }, 100);
    }
  }
}
