import {Component, Input, OnInit, SimpleChanges, ViewEncapsulation} from '@angular/core';
import {ISimplifiedCategory} from "@interfaces/course/category.interface";
import {ISimplifiedCourse} from "@interfaces/course/course.interface";
import {CoursesService} from "@services/integrations/courses/courses.service";
import {FilesManagerService} from "@services/files/files-manager.service";
import { debounceTime } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'alm-root-courses-menu-sub-menu',
  templateUrl: './courses-menu-sub-menu.component.html',
  styleUrls: ['./courses-menu-sub-menu.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CoursesMenuSubMenuComponent {

  @Input() categoryId!: number;
  @Input() categoryPermanentLink!: string;
  @Input() userSubscribed?: boolean;
  @Input() isB2bUser?: boolean | null;
  @Input() allB2bCategories: ISimplifiedCategory[] | undefined = [];
  @Input() isB2bCategoriesSelected: boolean = false;
  courses: ISimplifiedCourse[] = [];
  private coursesCache: Record<number, ISimplifiedCourse[]> = {}; // Cache for courses
  private categoryIdChangeSubject = new Subject<number>();
  
  constructor(private coursesService: CoursesService, private filesManager: FilesManagerService) { 
    this.categoryIdChangeSubject.pipe(
      debounceTime(200)
    ).subscribe(newCategoryId => {
      this.handleCategoryIdChange(newCategoryId);
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.categoryId && changes.categoryId.currentValue !== changes.categoryId.previousValue) {
      this.categoryIdChangeSubject.next(this.categoryId);
    }
  }

  private handleCategoryIdChange(categoryId: number) {
    if (this.coursesCache[categoryId]) {
      this.courses = this.coursesCache[categoryId];

    } else {
      this.fetchCourses(categoryId);
    }
  }

  private fetchCourses(categoryId: number) {
    this.coursesService.getMenuCoursesByCategoryId(categoryId).then((courses) => {
      this.updateCoursesCache(categoryId, courses.items);
    });
  }

  private updateCoursesCache(categoryId: number, courses: ISimplifiedCourse[]) {
    this.coursesCache[categoryId] = courses;
    this.courses = courses;
  }

  getCardImage(id: number): string {
    return this.filesManager.getCourseSmallImageURL(id);
  }

}
