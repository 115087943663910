import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ConfirmationChangePlanDialogComponent} from './confirmation-change-plan-dialog.component';
import {MatDialogModule} from '@angular/material/dialog';
import {AlmTranslateCutModule} from '@alm-translate-cut/alm-translate-cut.module';
import {TranslateModule} from '@ngx-translate/core';
import {MatButtonModule} from '@angular/material/button';


@NgModule({
  declarations: [ConfirmationChangePlanDialogComponent],
  imports: [
    CommonModule,
    MatDialogModule,
    AlmTranslateCutModule,
    TranslateModule,
    MatButtonModule
  ],
  exports: [ConfirmationChangePlanDialogComponent]
})
export class ConfirmationChangePlanDialogModule {
}
