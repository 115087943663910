import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OtpInputComponent } from './otp-input.component';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatInputAutofocusModule} from '@directives/mat-input-autofocus/mat-input-autofocus.module';
import {ReactiveFormsModule} from '@angular/forms';
import {TranslateModule} from '@ngx-translate/core';
import {AlmTranslateCutModule} from '@alm-translate-cut/alm-translate-cut.module';



@NgModule({
    declarations: [OtpInputComponent],
    exports: [
        OtpInputComponent
    ],
  imports: [
    CommonModule,
    MatFormFieldModule,
    MatInputModule,
    MatInputAutofocusModule,
    ReactiveFormsModule,
    TranslateModule,
    AlmTranslateCutModule
  ]
})
export class OtpInputModule { }
