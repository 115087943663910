import {Component, Input, OnInit} from '@angular/core';
import { CustomContentService } from '@services/custom-content/custom-content.service';

@Component({
  selector: 'alm-root-footer-explore',
  templateUrl: './footer-explore.component.html',
  styleUrls: ['./footer-explore.component.scss']
})
export class FooterExploreComponent implements OnInit {

  @Input() userSubscribed?: boolean;
  @Input() isB2BUser?: boolean;

  constructor(public customContentService: CustomContentService) { }

  ngOnInit(): void {
  }

}
