import { Injectable } from '@angular/core';
import {IOrderDetails} from '@interfaces/shopping-cart/shopping-cart-list.interface';
import {BrazeService} from '@services/marketing/braze/braze.service';
import {SubscriptionMarketingService} from '@services/marketing/subscription-marketing.service';
import {IPaymentMethod, PaymentMethods} from '@interfaces/common/payment.interface';
import {IPaymentBase} from "@interfaces/payment/payment.interface";

@Injectable({
  providedIn: 'root'
})
export class BrazeSubscriptionEventsService {

  constructor(
    private brazeService: BrazeService,
    private subscriptionMarketingService: SubscriptionMarketingService,
  ) { }

  logSubscriptionCheckout({ paymentObject, couponCode }: { paymentObject: IPaymentBase, couponCode: string | undefined }) {
    this.brazeService.logEvent('subscription_checkout', {
      plan: this.subscriptionMarketingService.getSubscriptionPlanName(paymentObject.items[0].itemId),
      payment_method: PaymentMethods[paymentObject.paymentMethod as PaymentMethods],
      discount_code: couponCode,
      amount_after_discount: paymentObject.finalAmountUSD,
    });
  }
}
