import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SubscriptionBannerComponent } from './subscription-banner.component';
import {SubscriptionCardModule} from '@components/subscription-card/subscription-card.module';
import {TranslateModule} from '@ngx-translate/core';
import {AlmTranslateCutModule} from '@alm-translate-cut/alm-translate-cut.module';



@NgModule({
    declarations: [SubscriptionBannerComponent],
    exports: [
        SubscriptionBannerComponent
    ],
  imports: [
    CommonModule,
    SubscriptionCardModule,
    TranslateModule,
    AlmTranslateCutModule
  ]
})
export class SubscriptionBannerModule { }
