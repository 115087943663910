import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CourseNotAllowedDialogContainerComponent } from './course-not-allowed-dialog-container.component';
import { CourseNotAllowedComponent } from './course-not-allowed/course-not-allowed.component';
import {MatDialogModule} from "@angular/material/dialog";
import {RouterModule} from "@angular/router";
import {TranslateModule} from "@ngx-translate/core";
import {AlmTranslateCutModule} from '@alm-translate-cut/alm-translate-cut.module';



@NgModule({
    declarations: [CourseNotAllowedDialogContainerComponent, CourseNotAllowedComponent],
    exports: [
        CourseNotAllowedDialogContainerComponent
    ],
  imports: [
    CommonModule,
    MatDialogModule,
    RouterModule,
    TranslateModule,
    AlmTranslateCutModule
  ]
})
export class CourseNotAllowedDialogContainerModule { }
