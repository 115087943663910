import {Injectable} from '@angular/core';
import {B2bHomeBannerHttpService} from '@services/integrations/b2b-home-banner/b2b-home-banner-http.service';
import {IB2BBanner, IOrganizationInfo} from '@interfaces/b2b-home-banner/b2b-home-banner.interface';

@Injectable({
  providedIn: 'root'
})
export class B2bHomeBannerService {

  constructor(private b2bHomeBannerHttp: B2bHomeBannerHttpService) {
  }

  getB2BBannerData(): Promise<IB2BBanner> {
    return this.b2bHomeBannerHttp.getB2BBannerData();
  }
  getB2bOrganizationInfo(sender: string): Promise<IOrganizationInfo> {
    return this.b2bHomeBannerHttp.getB2bOrganizationInfo(sender);
  }
}
