import {
  AfterViewInit,
  Directive,
  ElementRef,
  Input,
  OnDestroy,
  Renderer2,
  Output,
  EventEmitter,
  Inject, PLATFORM_ID
} from '@angular/core';
import {isPlatformBrowser} from "@angular/common";

@Directive({
  selector: '[imageLoader]'
})
export class ImageLoaderDirective implements AfterViewInit, OnDestroy {

  @Input('imageLoader') image?: string;
  @Input() isBackgroundImage: boolean = false;
  @Input() isLazyLoadedImage: boolean = true;
  @Input() errorImage: string = 'assets/images/placeholder.png';
  @Output() onError: EventEmitter<any> = new EventEmitter<any>();

  private readonly imageElement: HTMLImageElement;
  private loaded: boolean = false;
  private intersectionObserver?: IntersectionObserver;

  constructor(private el: ElementRef, private renderer: Renderer2, @Inject(PLATFORM_ID) private platformId: any) {
    this.imageElement = el.nativeElement;
  }

  ngAfterViewInit(): void {
    // disable lazy loading until implement it natively because it effects LCP speed measurement
    this.loadImage();
    /*if (this.isLazyLoadedImage) {
      this.loadImage();
    } else {
      this.loadImage();
    }*/
  }

  private addLoadingBackground() {
    this.imageElement.style.backgroundImage = "url(assets/images/images-logo-placeholder.svg)";
    this.imageElement.style.backgroundPosition = 'center';
    this.imageElement.style.backgroundRepeat = 'no-repeat';
    this.imageElement.style.backgroundSize = '40px';
  }

  private loadImage(): void {
    if (this.isBackgroundImage) {
      this.loadBackgroundImage();
    } else {
      if (!this.imageElement.classList.contains('mobile-menu__profile-image')
        && !this.imageElement.classList.contains('user-menu__profile-image')
        && !this.imageElement.classList.contains('user-profile-details_image')
        && !this.imageElement.classList.contains('user-profile-card_img')
        && !this.imageElement.classList.contains('user-settings-personal-info_img')
        && !this.imageElement.classList.contains('course-viewer-discussion__user-image')
        && !this.imageElement.classList.contains('course-viewer-discussion__comment-editor_commenter-info_avatar')
        && !this.imageElement.classList.contains('header-notification-menu-discussion-card__image')
        && !this.imageElement.classList.contains('viewer-discussion-reply__container__reply-image')) {
        this.addLoadingBackground();
      }
      this.loadImageSrc();
    }
  }

  private loadImageSrc(): void {
    this.imageElement.src = this.image ? this.image : this.errorImage;
    let imageError = this.renderer.listen(this.imageElement, 'error', () => {
      this.imageElement.src = this.errorImage;
      this.onError.emit();
      imageError();
    })
  }

  private loadBackgroundImage(): void {
   if(this.imageElement.classList.contains("category-banner__backBanner-container")) {
     this.imageElement.style.backgroundImage = `url(${this.image}), url(${this.errorImage})`;
   } else {
     this.imageElement.style.backgroundImage = `url(${this.image}), url(assets/images/images-logo-placeholder.svg)`;
   }
  }

  private lazyLoadImage(): void {
    if (!this.loaded && isPlatformBrowser(this.platformId)) {
      this.intersectionObserver = new IntersectionObserver((entries) => {
        let entry = entries[0];
        this.onObserve(entry);
      }, {
        root: null,
        threshold: 0,
        rootMargin: '0px'
      });
      this.intersectionObserver.observe(this.imageElement)
    }
  }

  private onObserve(entry: IntersectionObserverEntry) {
    if (entry) {
      if (entry.isIntersecting) {
        this.loadImage();
        this.disconnectObserver();
      }
    }
  }

  private disconnectObserver() {
    if (this.intersectionObserver) {
      this.intersectionObserver.unobserve(this.imageElement);
      this.intersectionObserver.disconnect();
    }
  }

  ngOnDestroy(): void {
    this.disconnectObserver();
  }
}
