import { Injectable } from '@angular/core';
import {BrazeService} from '@services/marketing/braze/braze.service';

interface ICategoryEvents {
  [key: number]: string;
}

enum Categories {
  mental_health_wellness = 1,
  art_design,
  soft_skills,
  media_photography_media,
  business_management,
  sales_marketing,
  tech_science_productivity,
  parenting_relationship,
  kids_development,
  lifestyle_health,
  entrepreneurship,
  languages,
  education,
}

@Injectable({
  providedIn: 'root'
})
export class BrazeCoursesEventsService {

  constructor(
    private brazeService: BrazeService,
  ) { }

  categoryClick(categoryId: number): void {
    const categoryEventName = `tap_cat_${Categories[categoryId]}`;
    if (categoryEventName) {
      this.brazeService.logEvent(categoryEventName);
    }
  }

  categoryEnrolled(categoryIds: number[] , courseName: string ): void {
    categoryIds?.forEach(categoryId => {
      const categoryEventName = `enrolled_${Categories[categoryId]}`;
      if (categoryEventName) {
        this.brazeService.logEvent(categoryEventName,{'course name': courseName});
      }
    });
  }

  categoryCompleted(categoryIds: number[], courseName: string): void {
    categoryIds.forEach(categoryId => {
      const categoryEventName = `completed_${Categories[categoryId]}`;
      if (categoryEventName) {
        this.brazeService.logEvent(categoryEventName,{'course name': courseName});
      }
    });
  }
}
