<div class="social-links">
  <ul class="list-group list-group-horizontal">
    <li>
      <a href="https://www.linkedin.com/company/almentornet" target="_blank">
        <svg><use href="assets/images/sprite.svg#linkedin" /></svg>
      </a>
    </li>
    <li>
      <a href="https://www.facebook.com/almentornet/" target="_blank">
        <svg><use href="assets/images/sprite.svg#facebook" /></svg>
      </a>
    </li>
    <li>
      <a href="https://www.instagram.com/almentornet/" target="_blank">
        <svg><use href="assets/images/sprite.svg#instagram" /></svg>
      </a>
    </li>
    <li>
      <a href="https://www.youtube.com/channel/UCqI0BoVM1i5ZTqWZtkSFtVA" target="_blank">
        <svg><use href="assets/images/sprite.svg#youtube" /></svg>
      </a>
    </li>
    <li>
      <a href="https://twitter.com/almentornet" target="_blank">
        <svg><use href="assets/images/sprite.svg#twitter" /></svg>
      </a>
    </li>
  </ul>
</div>
