import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {TrackingClickEventDirective} from "@services/mixpanel/tracking-click-event.directive";



@NgModule({
  declarations: [
      TrackingClickEventDirective,
  ],
  imports: [
    CommonModule,
  ],
  exports: [
    TrackingClickEventDirective
  ]
})
export class TrackingModule { }
