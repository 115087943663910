import {Injectable} from '@angular/core';
import {NotificationHttpService} from '@services/integrations/notification/notification-http.service';
import {INotificationList} from '@interfaces/notification/notification.interface';
import {BehaviorSubject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  onNotificationUpdate:BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(private notificationHttp: NotificationHttpService) {
  }

  getAllNotifications(sender: string): Promise<INotificationList> {
    return this.notificationHttp.getAllNotifications(sender);
  }

  markNotificationAsRead(sender: string, notificationId?: number): Promise<{ result: boolean }> {
    let notificationIdObject = {};
    if (notificationId) {
      notificationIdObject = {
        notificationId: notificationId
      }
    }
    return this.notificationHttp.markNotificationAsRead(sender, notificationIdObject).then((value) => {
      this.onNotificationUpdate.next(true);
      return value;
    });
  }

  getUnreadNotificationsCount(sender: string): Promise<{ count: number }> {
    return this.notificationHttp.getUnreadNotificationsCount(sender);
  }

  getCoursePermanentLinkById(id: number): Promise<{permanentLink: string}>{
    return this.notificationHttp.getCoursePermanentLinkById(id);
  }
}
