import {BrowserModule} from '@angular/platform-browser';
import {APP_INITIALIZER, ErrorHandler, NgModule} from '@angular/core';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {TranslateModule} from '@ngx-translate/core';
import {AlmTranslateCutModule} from '@alm-translate-cut/alm-translate-cut.module';
import {TranslateConfig} from '@services/language/ngx-translate.config';
import {FooterModule} from '@components/footer/footer.module';
import {RequestTokenModule} from '@services/request-token/request-token.module';
import {CustomHttpService} from '@services/request-token/custom-http.service';
import {environment} from '@environments/environment';
import {CategoryPageModule} from '@pages/category-page/category-page.module';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MainHeaderModule} from '@components/main-header/main-header.module';
import {AppContainerModule} from '@components/app-container/app-container.module';
import {VideoPlayerPreviewModule} from '@components/video-player-preview/video-player-preview.module';
import {LessonPreviewModule} from '@components/lesson-preview/lesson-preview.module';
import {FiltersModule} from '@components/filters/filters.module';
import {QuillModule} from 'ngx-quill';
import {UiLoaderModule} from '@components/ui-loader/ui-loader.module';
import {AngularFireModule} from "@angular/fire/compat";
import {AngularFireMessagingModule} from "@angular/fire/compat/messaging";
import {ToastrModule} from 'ngx-toastr';
import {
  SubscriptionExpiryDialogContainerModule
} from '@components/subscription-expiry-dialog-container/subscription-expiry-dialog-container.module';
import {AlmShareButtonsModule} from '@components/alm-share-buttons/alm-share-buttons.module';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {ErrorSncakBarModule} from '@components/error-sncak-bar/error-sncak-bar.module';
import {
  CertificateDialogContainerModule
} from '@components/certificate-dialog-container/certificate-dialog-container.module';
import {AddEmailDialogModule} from '@components/add-email-dialog/add-email-dialog.module';
import {InfoSnackBarModule} from '@components/info-snack-bar/info-snack-bar.module';
import {VerificationInputDialogModule} from '@components/verification-input-dialog/verification-input-dialog.module';
import {
  SettingsDeviceManagementDialogContainerModule
} from '@components/settings-device-management-dialog-container/settings-device-management-dialog-container.module';
import {ChangeMobileDialogModule} from '@components/change-mobile-dialog/change-mobile-dialog.module';
import {ConnectorV2Service} from '@services/connector/connector-v2.service';
import {SimplifiedFooterModule} from '@components/simplified-footer/simplified-footer.module';
import {AuthDialogContainerModule} from '@components/auth-dialog-container/auth-dialog-container.module';
import {UserManagerService} from "@services/user/user-manager.service";
import {AngularFirestoreModule} from "@angular/fire/compat/firestore";
import {AppAnnouncementBottomSheetModule} from '@components/app-announcement-bottom-sheet/app-announcement-bottom-sheet.module';
import {
  CourseNotAllowedDialogContainerModule
} from '@components/course-not-allowed-dialog-container/course-not-allowed-dialog-container.module';
import {CookieModule} from 'ngx-cookie';
import * as Sentry from '@sentry/angular-ivy';
import {Router, UrlSerializer} from '@angular/router';
import {CustomUrlSerializerService} from "../../../../src/app/interceptors/custom-url-serializer.class";
import {NotificationSoftRequestModule} from "@components/notification-soft-request/notification-soft-request.module";
import {HTTP_INTERCEPTORS} from "@angular/common/http";
import {ApiGatewayInterceptor} from "../../../../src/app/interceptors/api-gateway.interceptor";
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import {REQUESTER_APP_HEADER_VALUE} from "@constants/http.constants";

function beforeAppInitFactory(connector: ConnectorV2Service, customHttp: CustomHttpService, userManagerService: UserManagerService) {
  return () => {
    connector.initConnector();
    customHttp.platformCode = environment.platforms.b2c.code;
    userManagerService.userPlatform = environment.platforms.b2c.code;
  };
}

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    RequestTokenModule,
    TranslateModule.forRoot(TranslateConfig),
    AlmTranslateCutModule.forRoot(),
    FooterModule,
    CategoryPageModule,
    MainHeaderModule,
    VideoPlayerPreviewModule,
    AppContainerModule,
    LessonPreviewModule,
    FiltersModule,
    MatTooltipModule,
    QuillModule.forRoot(),
    UiLoaderModule,
    CookieModule.withOptions(),
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireMessagingModule,
    AngularFirestoreModule,
    ToastrModule.forRoot({
      autoDismiss: true,
      closeButton: true,
      enableHtml: true,
      newestOnTop: true,
      tapToDismiss: true,
      preventDuplicates: true,
      maxOpened: 4,
    }),
    ChangeMobileDialogModule,
    SubscriptionExpiryDialogContainerModule,
    AlmShareButtonsModule,
    SettingsDeviceManagementDialogContainerModule,
    MatSnackBarModule,
    ErrorSncakBarModule,
    CertificateDialogContainerModule,
    AddEmailDialogModule,
    VerificationInputDialogModule,
    InfoSnackBarModule,
    SimplifiedFooterModule,
    AuthDialogContainerModule,
    AppAnnouncementBottomSheetModule,
    CourseNotAllowedDialogContainerModule,
    NotificationSoftRequestModule,
  ],
  providers: [
    CustomHttpService,
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    }, {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: beforeAppInitFactory,
      deps: [ConnectorV2Service, CustomHttpService, UserManagerService, Sentry.TraceService],
      multi: true
    },
    {
      provide: UrlSerializer,
      useClass: CustomUrlSerializerService
    },
    {provide: HTTP_INTERCEPTORS, useClass: ApiGatewayInterceptor, multi: true},
    { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { subscriptSizing: 'dynamic' } },
    { provide: REQUESTER_APP_HEADER_VALUE, useValue: 'B2C_WEB' },
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
