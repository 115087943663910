import {Component} from '@angular/core';
import {LanguageControlService} from "@services/language/language-control.service";
import ar from "@components/notification-soft-request/i18n/ar.json";
import en from "@components/notification-soft-request/i18n/en.json";
import {MatDialog} from "@angular/material/dialog";

@Component({
  selector: 'alm-root-notification-soft-request',
  templateUrl: './notification-soft-request.component.html',
  styleUrls: ['./notification-soft-request.component.scss']
})
export class NotificationSoftRequestComponent {

  constructor(
              private languageControl: LanguageControlService,
              private matDialog: MatDialog,) {
    this.languageControl.setTranslations('ar', ar);
    this.languageControl.setTranslations('en', en);
  }

  closeDialogWithStatus(accept: boolean): void {
    this.matDialog.getDialogById(NotificationSoftRequestComponent.name)?.close(accept);
  }
}
