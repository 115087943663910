import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {LessonPreviewComponent} from './lesson-preview.component';
import {LessonPreviewMainSectionComponent} from './lesson-preview-main-section/lesson-preview-main-section.component';
import {LessonPreviewVideoPlayerComponent} from './lesson-preview-video-player/lesson-preview-video-player.component';
import {VideoPlayerModule} from "@components/video-player/video-player.module";
import {MatDialogModule} from "@angular/material/dialog";


@NgModule({
  declarations: [LessonPreviewComponent, LessonPreviewMainSectionComponent, LessonPreviewVideoPlayerComponent],
    imports: [
        CommonModule,
        VideoPlayerModule,
        MatDialogModule,
    ]
})
export class LessonPreviewModule { }
