import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CoursesMenuComponent } from './courses-menu.component';
import { MainMenuComponent } from './main-menu/main-menu.component';
import { SubMenuComponent } from './sub-menu/sub-menu.component';
import {MatIconModule} from '@angular/material/icon';
import {RouterModule} from "@angular/router";
import {TranslateModule} from '@ngx-translate/core';
import {ImageLoaderModule} from "@directives/image-loader/image-loader.module";



@NgModule({
    declarations: [CoursesMenuComponent, MainMenuComponent, SubMenuComponent],
    exports: [
        CoursesMenuComponent
    ],
    imports: [
        CommonModule,
        MatIconModule,
        RouterModule,
        TranslateModule,
        ImageLoaderModule
    ]
})
export class CoursesMenuModule { }
