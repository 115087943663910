import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CoursesMenuV2Component } from './courses-menu-v2.component';
import { CoursesMenuSubMenuComponent } from './courses-menu-sub-menu/courses-menu-sub-menu.component';
import { CoursesMenuMainMenuComponent } from './courses-menu-main-menu/courses-menu-main-menu.component';
import {TranslateModule} from "@ngx-translate/core";
import {RouterModule} from "@angular/router";
import {ImageLoaderModule} from "@directives/image-loader/image-loader.module";
import {AlmTranslateCutModule} from '@alm-translate-cut/alm-translate-cut.module';
import {CustomHrefDirective} from "@directives/custom-href/custom-href.directive";



@NgModule({
    declarations: [CoursesMenuV2Component, CoursesMenuSubMenuComponent, CoursesMenuMainMenuComponent],
    exports: [
        CoursesMenuV2Component
    ],
    imports: [
        CommonModule,
        TranslateModule,
        RouterModule,
        ImageLoaderModule,
        AlmTranslateCutModule,
        CustomHrefDirective
    ]
})
export class CoursesMenuV2Module { }
