import {Injectable} from '@angular/core';
import {FirebaseNotificationsService} from "@services/firebase/firebase-notifications.service";
import {ToastrService} from "ngx-toastr";
import {LanguageControlService} from "@services/language/language-control.service";
import {NotificationService} from "@services/integrations/notification/notification.service";
import {AuthV2Service} from "@services/integrations/auth-v2/auth-v2.service";
import {UserAfterLoginActionsService} from "@services/user/user-after-login-actions.service";

@Injectable({
  providedIn: 'root'
})
export class NotificationManagerService {

  constructor(private firebaseNotificationService: FirebaseNotificationsService, private toasterService: ToastrService,
              private languageControl: LanguageControlService, private notificationService: NotificationService,
              private auth2Service: AuthV2Service, private userAfterLoginActions: UserAfterLoginActionsService) {
  }

  initPushNotification() {
    this.auth2Service.addNotificationToken('app', 'DEFAULT').finally(() => {
      this.firebaseNotificationService.requestNotificationPermissions().then((token) => {
        this.userAfterLoginActions.isFirstLogin().then((isFirstLogin) => {
          if (isFirstLogin || !this.checkIfTokenSentToBackend()) {
            this.auth2Service.addNotificationToken('app', token).then(({result}) => {
              if (result) {
                this.addTokenSentToBackendFlag();
              }
            })
          }
        });
        this.onMessaging();
      }).catch((e) => {
        console.log(e);
      })
    });
  }

  deleteNotificationToken(): Promise<boolean> {
    return new Promise<boolean>(resolve => {
      this.firebaseNotificationService.removeNotificationTokenForUser().then((token) => {
        if (token) {
          this.auth2Service.removeNotificationToken('app', token).finally(() => {
            this.removeTokenSentToBackendFlag();
            resolve(true);
          });
        }
      }).catch((e) => {
        resolve(true);
        console.log(e);
      })
    });
  }

  private onMessaging() {
    this.firebaseNotificationService.onMessaging().subscribe((message) => {
      if (message.notification) {
        let notification = message.notification;
        this.toasterService.show(notification.body, notification.title, {
          closeButton: true,
          enableHtml: true,
          progressBar: false,
          timeOut: 3000,
          titleClass: 'notification-toaster-title',
          toastClass: 'notification-toaster alm-toaster',
          tapToDismiss: false,
          positionClass: `toast-bottom-${this.languageControl.currentLanguage.getValue().direction == 'rtl' ? 'left' : 'right'}`
        });
        this.notificationService.onNotificationUpdate.next(true);
      }
    });
  }

  private checkIfTokenSentToBackend() {
    if (localStorage) {
      let sentToken = localStorage.getItem('alm-stb');
      if (sentToken) {
        return true;
      }
    }
    return false;
  }

  private addTokenSentToBackendFlag() {
    if (localStorage) {
      localStorage.setItem('alm-stb', 'true');
    }
  }

  private removeTokenSentToBackendFlag() {
    if (localStorage) {
      localStorage.removeItem('alm-stb');
    }
  }
}
