import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {Observable} from 'rxjs';
import {UserManagerService} from "@services/user/user-manager.service";
import {filter, map, tap} from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class SubscriptionPageGuard implements CanActivate {

  constructor(
    private userManagerService: UserManagerService,
    private router: Router,
  ) {

  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.canOpenSubscriptionPage();
  }

  private canOpenSubscriptionPage() {
    return this.userManagerService.userInfo.pipe(
      filter(userInfo => !!userInfo),
      tap(userInfo => {
        if (userInfo?.subscribed) {
          this.router.navigate(['/settings/subscription-plan']);
        }
      }),
      map((userInfo) => {
        return !userInfo?.subscribed
      })
    );
  }
}
