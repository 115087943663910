<div class="footer-bottom">
  <div class="footer-bottom__container">
    <div class="sub-footer-items">
      <div class="sub-footer-item">
        © {{ today | date:'yyyy' }}        <a href="https://www.almentor.net" target="_blank">
          {{'footer.sub_footer_item' | translate | translateCut: 1}}
        </a>
      </div>
      <div class="sub-footer-item">
        <a (click)="navigationToFooterLinks('/terms-conditions')">
          {{'footer.sub_footer_item' | translate | translateCut: 2}}
        </a></div>
      <div class="sub-footer-item">
        <a (click)="navigationToFooterLinks('/privacy-policy')">
          {{'footer.sub_footer_item' | translate | translateCut: 3}}
        </a></div>
      <div class="sub-footer-item">
        <a class="help-center" (click)="navigationToFooterLinks('', true)">
          {{'footer.sub_footer_item' | translate | translateCut: 4}}
        </a></div>
    </div>
    <div class="social-links-container">
      <div class="social-links">
        <ul class="list-group list-group-horizontal">
          <li>
            <a href="https://www.linkedin.com/company/almentornet" target="_blank">
              <svg>
                <use href="assets/images/sprite.svg#linkedin"/>
              </svg>
            </a>
          </li>
          <li>
            <a href="https://www.facebook.com/almentornet/" target="_blank">
              <svg>
                <use href="assets/images/sprite.svg#facebook"/>
              </svg>
            </a>
          </li>
          <li>
            <a href="https://www.instagram.com/almentornet/" target="_blank">
              <svg>
                <use href="assets/images/sprite.svg#instagram"/>
              </svg>
            </a>
          </li>
          <li>
            <a href="https://www.youtube.com/channel/UCqI0BoVM1i5ZTqWZtkSFtVA" target="_blank">
              <svg>
                <use href="assets/images/sprite.svg#youtube"/>
              </svg>
            </a>
          </li>
          <li>
            <a href="https://twitter.com/almentornet" target="_blank">
              <svg>
                <use href="assets/images/sprite.svg#twitter"/>
              </svg>
            </a>
          </li>
        </ul>
      </div>

    </div>
  </div>
</div>
