<div class="notification-soft-request">
  <h4 class="notification-soft-request_title">
    {{'notification-soft-request.title' | translate}}
  </h4>
  <img class="notification-soft-request_image" src="assets/images/undraw_mailbox.svg"/>
  <p class="notification-soft-request_text">
    {{'notification-soft-request.message' | translate}}
  </p>
  <div class="notification-soft-request_actions">
    <button class="btn btn-alm-filled" (click)="closeDialogWithStatus(true)">
      {{'notification-soft-request.subscribe_to_notification' | translate}}
    </button>
    <button class="btn btn-outline-light" (click)="closeDialogWithStatus(false)">
      {{'notification-soft-request.may_be_later' | translate}}
    </button>
  </div>
</div>

