import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  UserSettingsChangePlanDialogComponent
} from '@pages/user-settings-page/components/user-settings-change-plan-dialog/user-settings-change-plan-dialog.component';
import {TranslateModule} from '@ngx-translate/core';
import {AlmTranslateCutModule} from '@alm-translate-cut/alm-translate-cut.module';
import {MatDialogModule} from '@angular/material/dialog';



@NgModule({
  declarations: [
    UserSettingsChangePlanDialogComponent
  ],
  exports: [
    UserSettingsChangePlanDialogComponent
  ],
  imports: [
    CommonModule,
    TranslateModule,
    AlmTranslateCutModule,
    MatDialogModule,
  ]
})
export class UserSettingsChangePlanDialogModule { }
