import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'alm-root-footer-social-links-b2b',
  templateUrl: './footer-social-links-b2b.component.html',
  styleUrls: ['./footer-social-links-b2b.component.scss']
})
export class FooterSocialLinksB2bComponent implements OnInit {

  @Input() userSubscribed?: boolean;

  constructor() { }

  ngOnInit(): void {
  }

}
