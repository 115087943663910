import {Component, Input, OnInit} from '@angular/core';
import {IUserInfo} from "@interfaces/authorized-user/user.interface";
import {LanguageControlService} from "@services/language/language-control.service";
import ar from "@components/top-ribbon/i18n/ar.json";
import en from "@components/top-ribbon/i18n/en.json";

@Component({
  selector: 'alm-root-top-ribbon-danger',
  templateUrl: './top-ribbon-danger.component.html',
  styleUrls: ['./top-ribbon-danger.component.scss']
})
export class TopRibbonDangerComponent implements OnInit {

  @Input() userInfo?: IUserInfo | null;

  constructor(private languageControl: LanguageControlService) {
    this.languageControl.setTranslations('ar', ar);
    this.languageControl.setTranslations('en', en);
  }

  ngOnInit(): void {
  }

}
