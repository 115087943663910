<div class="courses-menu-v2" *ngIf="allCategories.length > 0">
  <div class="courses-menu-v2__main-menu-section" [class.is-b2b]="isB2bUser && organizationCategories.length > 0">
    <alm-root-courses-menu-main-menu [isB2bUser]="isB2bUser" [categories]="allCategories"
                                     [organizationCategories]="organizationCategories"
                                     [isB2bCategoriesSelected]="isB2bCategoriesSelected"
                                     [b2bOrganizationName]="b2bOrganizationName"
                                     [currLang]="currentLang"
                                     (categoryId)="getCoursesByCategoryId($event)"
                                     (categoryPermanentLink)="getCategoryPermanentLink($event)"
                                     (onViewOrganizationCategories)="viewOrganizationCategories($event)"
    ></alm-root-courses-menu-main-menu>
  </div>
  <div class="courses-menu-v2__sub-menu-section" *ngIf="selectedCategoryId && selectedCategoryPermanentLink && !isB2bCategoriesSelected">
    <alm-root-courses-menu-sub-menu [categoryId]="selectedCategoryId" [categoryPermanentLink]="selectedCategoryPermanentLink"
    [userSubscribed]="userSubscribed" [isB2bUser]="isB2bUser"></alm-root-courses-menu-sub-menu>
  </div>

  <div class="courses-menu-v2__sub-menu-section"
       *ngIf="isB2bCategoriesSelected && isB2bUser"
       (mouseenter)="viewOrganizationCategories(true)"
       (mouseleave)="viewOrganizationCategories(false)">
    <alm-root-courses-menu-sub-menu [allB2bCategories]="organizationCategories"
                                    [isB2bUser]="isB2bUser"
                                    [isB2bCategoriesSelected]="isB2bCategoriesSelected">
    </alm-root-courses-menu-sub-menu>
  </div>
</div>
