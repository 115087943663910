import {Injectable, Injector} from '@angular/core';
import {
  NotificationSoftRequestComponent
} from "@components/notification-soft-request/notification-soft-request.component";
import {CSS_CLASSES} from "../../../css-classes";
import {MatDialog} from "@angular/material/dialog";
import {LanguageControlService} from "@services/language/language-control.service";
import {BrazeService} from "@services/marketing/braze/braze.service";
import {CookieService} from "ngx-cookie";
import {environment} from "@environments/environment";
import {NotificationManagerService} from "@services/notification-manager/notification-manager.service";
import {CustomHttpService} from "@services/request-token/custom-http.service";
import {SimpleStateService} from "@services/simple-store/simple-state.service";
import {debounceTime, filter, take} from "rxjs/operators";


const PUSH_SOFT_REQUEST_LOCALSTORAGE_KEY = 'alm-push-request';

@Injectable({
  providedIn: 'root'
})
export class NotificationSoftRequestService {

  constructor(
    private matDialog: MatDialog,
    private languageControl: LanguageControlService,
    private brazeService: BrazeService,
    private cookieService: CookieService,
    private customHttp: CustomHttpService,
    private simpleStateService: SimpleStateService,
    private injector: Injector
  ) { }

  initWithDelay(seconds: number) {
    if (this.isNotificationNotSupported() || this.IsNotificationPermissionDenied()) return;
    if (this.IsNotificationPermissionGranted()) return this.generateNotificationTokens();

    if (this.isSoftRequestHasToAppear()) {
      setTimeout(() => {
        this.simpleStateService.getState("videoPlaying").pipe(
            debounceTime(5000),
            filter(playing => !playing),
            take(1),
        ).subscribe(playerStopped => {
          this.startSoftNotificationRequest();
        });
      }, 1000 * seconds);
    }
  }

  private isNotificationNotSupported() {
    return !("Notification" in window);
  }

  private IsNotificationPermissionDenied() {
    return Notification.permission == "denied";
  }

  private IsNotificationPermissionGranted(): boolean {
    return Notification.permission === "granted";
  }

  private isSoftRequestHasToAppear(): boolean {
    const softRequestPostponeTime = this.cookieService.get(PUSH_SOFT_REQUEST_LOCALSTORAGE_KEY);
    if (softRequestPostponeTime) {
      return Number(softRequestPostponeTime) <= new Date().getTime();
    }
    return true;
  }

  private startSoftNotificationRequest() {
    this.matDialog.open(NotificationSoftRequestComponent, {
      id: NotificationSoftRequestComponent.name,
      hasBackdrop: true,
      disableClose: true,
      backdropClass: CSS_CLASSES.BLUR_DIALOG_BACKDROP,
      panelClass: CSS_CLASSES.DEFAULT_DIALOG_PANEL,
      direction: this.languageControl.getCurrentLanguage().direction,
    }).afterClosed().subscribe((subscribeToPush: boolean) => {
      this.handleSoftNotificationResponse(subscribeToPush);
    });
  }

  private handleSoftNotificationResponse(subscribeToPush: boolean) {
    if (subscribeToPush) {
      this.startNativeNotificationRequest();
    } else {
      this.postponeTheRequest();
    }
  }

  private async startNativeNotificationRequest() {
    const notificationPermission: NotificationPermission = await Notification.requestPermission();
    if (notificationPermission === 'granted') {
      this.generateNotificationTokens();
    }
  }

  private postponeTheRequest() {
    const currentDate = new Date();
    const softRequestPostponeTime = currentDate.setHours(currentDate.getHours() + 48);
    this.cookieService.put(PUSH_SOFT_REQUEST_LOCALSTORAGE_KEY, softRequestPostponeTime.toString(), {
      domain: environment.platforms.base.link,
      expires: new Date(softRequestPostponeTime),
    });
  }

  private generateNotificationTokens() {
    this.brazeService.requestPushPermission();
    if (this.customHttp.platformCode === environment.platforms.b2c.code) {
      const notificationManagerService = this.injector.get(NotificationManagerService);
      notificationManagerService.initPushNotification();
    }
  }
}
