<div class="container" mat-dialog-content>
  <div class="dialog-auth-header">
    <div class="dialog-close" *ngIf="currentView == 0">
      <button type="button" (click)="closeDialog()">
        <svg aria-hidden="true">
          <use href="assets/images/sprite.svg#times" /></svg>
      </button>
    </div>
    <div class="auth-step-control" *ngIf="currentView != 0">
      <button type="button" class="skip" (click)="changeView(currentView+1)">
        <span>{{'auth_dialog.skip' | translate}}</span>
        <svg aria-hidden="true">
          <use href="assets/images/sprite.svg#arrow-forward" />
        </svg>
      </button>
    </div>
  </div>
  <div class="dialog-auth-content">
    <alm-root-auth-container>
      <alm-root-verify-email *ngIf="currentView == 0"></alm-root-verify-email>
      <alm-root-demographics *ngIf="currentView == 1"
                             (demographicsObject)="saveDemographicsInfo($event)"></alm-root-demographics>
      <alm-root-interests-container *ngIf="currentView == 2"
                                    (sendSelectedInterests)="saveInterests($event)">
      </alm-root-interests-container>
    </alm-root-auth-container>
  </div>
</div>
