import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FilterDropdownComponent} from './filter-dropdown/filter-dropdown.component';
import {FilterMobileBottomSheetComponent} from './filter-mobile-bottom-sheet/filter-mobile-bottom-sheet.component';
import {FilterMobileComponent} from './filter-mobile/filter-mobile.component';
import {MatCheckboxModule} from "@angular/material/checkbox";
import {
  FilterMobileSelectionPageComponent
} from './filter-mobile-selection-page/filter-mobile-selection-page.component';
import {MatBottomSheetModule} from "@angular/material/bottom-sheet";
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatSelectModule} from '@angular/material/select';
import {SortDropdownComponent} from './sort-dropdown/sort-dropdown.component';
import {ReactiveFormsModule} from '@angular/forms';
import {SortMobileBottomSheetComponent} from './sort-mobile-bottom-sheet/sort-mobile-bottom-sheet.component';
import {FilterEmptyResultsComponent} from './filter-empty-results/filter-empty-results.component';
import {TranslateModule} from "@ngx-translate/core";
import {FilterSearchComponent} from './filter-search/filter-search.component';
import {MatInputModule} from '@angular/material/input';


@NgModule({
  declarations: [FilterDropdownComponent, FilterMobileBottomSheetComponent, FilterMobileComponent, FilterMobileSelectionPageComponent, SortDropdownComponent, SortMobileBottomSheetComponent, FilterEmptyResultsComponent, FilterSearchComponent],
  exports: [
    FilterDropdownComponent,
    SortDropdownComponent,
    FilterEmptyResultsComponent,
    FilterSearchComponent
  ],
  imports: [
    CommonModule,
    MatCheckboxModule,
    MatBottomSheetModule,
    MatFormFieldModule,
    MatSelectModule,
    ReactiveFormsModule,
    TranslateModule,
    MatInputModule
  ]
})
export class FiltersModule {
}
