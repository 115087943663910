import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {LookupsService} from "@services/integrations/lookups/lookups.service";
import {ICountry} from "@interfaces/common/auth.interface";
import {MatSelectChange} from "@angular/material/select";
import {AbstractControl, UntypedFormControl, Validators} from "@angular/forms";
import {ReplaySubject, Subscription} from "rxjs";
import {UiLoaderService} from "@services/ui-loader/ui-loader.service";
import {LanguageControlService} from "@services/language/language-control.service";
import ar from "./i18n/ar.json";
import en from "./i18n/en.json";

@Component({
  selector: 'alm-root-phone-input',
  templateUrl: './phone-input.component.html',
  styleUrls: ['./phone-input.component.scss']
})
export class PhoneInputComponent implements OnInit, OnDestroy {

  @Input() required: boolean = true;
  @Input() inPaymentWebsite: boolean = false;
  @Input() phoneNumber?: string;
  @Output() onValidatePhone: EventEmitter<string | null> = new EventEmitter<string | null>();
  countries: ICountry[] = [];
  selectedCountry?: ICountry;
  selectedCountryIndex: number = 0;

  phoneCtrlValidators = [
    Validators.minLength(8),
    Validators.maxLength(12)
  ]
  phoneCtrl: UntypedFormControl = new UntypedFormControl('', Validators.compose(this.phoneCtrlValidators));
  countryFilterCtrl: UntypedFormControl = new UntypedFormControl();
  filteredCountries: ReplaySubject<ICountry[]> = new ReplaySubject<ICountry[]>(1);
  private phonePattern: string = '^[0-9]*$';
  maskPattern = {'0': {pattern: new RegExp('^[0-9]*$')}};
  dir: 'rtl' | 'ltr';
  private subscriptions: Subscription[] = [];


  constructor(private lookupService: LookupsService, private uiLoader: UiLoaderService,
              private languageControl: LanguageControlService) {
    this.dir = this.languageControl.currentLanguage.getValue().direction;
    this.languageControl.setTranslations('ar', ar);
    this.languageControl.setTranslations('en', en);
  }

  ngOnInit(): void {
    if (this.required) {
      this.phoneCtrl.setValidators([Validators.required, ...this.phoneCtrlValidators]);
    }
    this.uiLoader.startUiLoader('countries-loader');
    this.lookupService.getAllCountriesByLanguageId('phone').then((countries) => {
      this.countries = countries;
      this.filteredCountries.next(this.countries);
      this.getCurrentCountry();
    }).catch(() => {
      this.uiLoader.stopUiLoader('countries-loader');
    });
    let langSub = this.languageControl.currentLanguage.subscribe((lang) => {
      this.dir = lang.direction;
    });
    this.subscriptions.push(langSub);
  }

  filterArrayByName(searchString: string, source: any[], filtered: ReplaySubject<any[]>) {
    filtered.next(source.filter((item) => item.name.toLowerCase().startsWith(searchString.toLowerCase())));
  }

  getCurrentCountry() {
    if(this.phoneNumber) {
      this.uiLoader.stopUiLoader('countries-loader');
      const phoneCode = this.phoneNumber.split('-')[0];
      const mobileNumber = this.phoneNumber.split('-')[1];
      this.phoneCtrl.setValue(mobileNumber);
      this.countries.map((value, index) => {
        if (value.phoneCode == phoneCode.split('+')[1]) {
          this.selectedCountryIndex = index;
          this.selectedCountry = this.countries[index];
        }
      });
    } else {
      this.lookupService.getPhoneCode('phone').then(({phoneCode}) => {
        this.countries.map((value, index) => {
          if (value.phoneCode == phoneCode) {
            this.selectedCountryIndex = index;
            this.selectedCountry = this.countries[index];
          }
        })
      }).finally(() => {
        this.uiLoader.stopUiLoader('countries-loader');
      });
    }
  }

  getSelectedIndex(country: ICountry) {
    this.countries.map((value, index) => {
      if (country.code == value.code) {
        this.selectedCountryIndex = index;
      }
    });
  }

  onCountrySelect($event: MatSelectChange) {
    let country = $event.value;
    this.selectedCountry = country;
    this.getSelectedIndex(country);
    this.onPhoneChange();
  }

  onPhoneChange(){
    if(this.phoneCtrl.valid && this.phoneCtrl.value && this.selectedCountry){
      if(this.inPaymentWebsite) {
        this.onValidatePhone.emit(`+${this.selectedCountry.phoneCode}-${Number(this.phoneCtrl.value)}`);
      } else {
        this.onValidatePhone.emit(`+${this.selectedCountry.phoneCode}${Number(this.phoneCtrl.value)}`);
      }
    } else if (this.phoneCtrl.value) {
      this.onValidatePhone.emit(null);
    } else {
      this.onValidatePhone.emit(undefined);
    }
  }

  getFormFieldError(fieldControl: AbstractControl | null): number {
    if (fieldControl?.touched) {
      if (fieldControl?.errors?.required) {
        return 1;
      }
      if (fieldControl?.errors?.pattern?.requiredPattern != this.phonePattern || fieldControl?.errors?.minlength
        || fieldControl?.errors?.maxLength) {
        return 2;
      }
    }
    return 0;
  }

  ngOnDestroy(): void {
    this.subscriptions.map((sub) => {
      sub.unsubscribe();
    });
  }
}
