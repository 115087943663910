import {IPaymentMethod} from "@interfaces/common/payment.interface";
import {EPaymentStatusId, IPaymentStatus} from "@interfaces/payment-website/payment-status.interface";
import {ELoginTypes} from "@interfaces/authorized-user/user.interface";

export enum TrackingEventName {

    //SignUp
    SIGNUP_BUTTON_CLICKED = "Signup Button Clicked",
    SIGN_UP_WITH_SOCIAL_CLICKED = "SignUp With Social Selected",
    SIGNUP_WITH_EMAIL_SELECTED = "signup with email selected",
    SIGNUP_WITH_EMAIL_STARTED = "signup with email started",
    SIGNUP_ACTIVATION = "signup activation",
    SIGNUP_SUCCESSFULLY = "signup successfully",

    //LogIn
    LOGIN_BUTTON_CLICKED = "login Button clicked",
    LOGIN_WITH_EMAIL_CLICKED = "login with email clicked",
    LOGIN_WITH_SOCIAL_CLICKED = "Login with social clicked",
    LOGIN_SUCCESSFULLY = "login successfully",


    //Subscription
    SUBSCRIPTION_PAGE_OPENED = 'Subscription Page Opened',
    SUBSCRIPTION_SELECT_PLAN = 'Subscription Select Plan',
    SUBSCRIPTION_SELECT_PAYMENT_METHOD = 'Subscription Select Payment Method',
    SUBSCRIPTION_PAYMENT_CONFIRMATION = 'Subscription Payment Confirmation',
    SUBSCRIPTION_PAYMENT_STATUS = 'Subscription Payment Status',

    //LMS
    CERTIFICATE_ADDED_TO_LINKEDIN = 'certificate_added_to_linkedin',

    //Progress_Tracker
    PROGRESS_TRACKER_LOADED = 'progress_tracker_loaded',
    PROGRESS_TRACKER_GOAL_CHANGED = 'progress_tracker_goal_changed'
}

export type ITrackingEventsProperties = {
    id: number;
    email: string;
    facebook: string;
    plan_type: 'yearly' | 'monthly';
    payment_method: IPaymentMethod;
    payment_status: IPaymentStatus;
    amount: number;
    discount_percentage?: number;
    discount_coupon?: string;
    transaction_id: string;
    phone_number?: string;

    authentication_type: ELoginTypes;
    status?: boolean;

    course_name: string;
    course_id: number;

    company_name: string;
    company_id: number;
    assigned_skills_count: number;
    assigned_by_admin: 1 | 2;
    streaks: number;
    change_id: IChangeID;

}

type TrackingEvents = [
    {
        name: TrackingEventName.SIGNUP_BUTTON_CLICKED,
        properties?: undefined
    },
    {
        name: TrackingEventName.SIGN_UP_WITH_SOCIAL_CLICKED,
        properties: EventProperties<'authentication_type'>
    },
    {
        name: TrackingEventName.SIGNUP_WITH_EMAIL_SELECTED,
        properties?: undefined
    },
    {
        name: TrackingEventName.SIGNUP_WITH_EMAIL_STARTED,
        properties: EventProperties<'email' | 'status'>
    },
    {
        name: TrackingEventName.SIGNUP_ACTIVATION,
        properties: EventProperties<'authentication_type' | 'status'>
    },
    {
        name: TrackingEventName.SIGNUP_SUCCESSFULLY,
        properties: EventProperties<'authentication_type'>
    },

    {
        name: TrackingEventName.LOGIN_BUTTON_CLICKED,
        properties?: undefined
    },
    {
        name: TrackingEventName.LOGIN_WITH_EMAIL_CLICKED,
        properties?: undefined
    },
    {
        name: TrackingEventName.LOGIN_WITH_SOCIAL_CLICKED,
        properties: EventProperties<'authentication_type'>
    },
    {
        name: TrackingEventName.LOGIN_SUCCESSFULLY,
        properties: EventProperties<'authentication_type'>
    },

    {
        name: TrackingEventName.SUBSCRIPTION_PAGE_OPENED,
        properties?: undefined,
    },
    {
        name: TrackingEventName.SUBSCRIPTION_SELECT_PLAN,
        properties: EventProperties<'plan_type'>,
    },
    {
        name: TrackingEventName.SUBSCRIPTION_SELECT_PAYMENT_METHOD,
        properties: EventProperties<'payment_method'>,
    },
    {
        name: TrackingEventName.SUBSCRIPTION_PAYMENT_CONFIRMATION,
        properties: EventProperties<
            'amount'
            | 'payment_method'
            | 'discount_coupon'
            | 'discount_percentage'
            | 'phone_number'
        >
    },
    {
        name: TrackingEventName.SUBSCRIPTION_PAYMENT_STATUS,
        properties: EventProperties<
            'payment_status'
            | 'amount'
            | 'payment_method'
            | 'discount_coupon'
            | 'discount_percentage'
            | 'phone_number'
            | 'transaction_id'
        >
    },
    {
        name: TrackingEventName.CERTIFICATE_ADDED_TO_LINKEDIN,
        properties: EventProperties<'course_name' | 'course_id'>
    },
    {
        name: TrackingEventName.PROGRESS_TRACKER_LOADED,
        properties: EventProperties<
            'company_name' 
            | 'company_id'
            | 'assigned_skills_count'
            | 'assigned_by_admin'
            | 'streaks'
        >,
    },
    {
        name: TrackingEventName.PROGRESS_TRACKER_GOAL_CHANGED,
        properties: EventProperties<
            'company_name' 
            | 'company_id'
            | 'assigned_skills_count'
            | 'change_id'
        >,
    }

];

type ArrayElement<ArrayType extends readonly unknown[]> =
    ArrayType extends readonly (infer ElementType)[] ? ElementType : never;

export type TrackingEvent = ArrayElement<TrackingEvents>


type EventProperties<K extends keyof ITrackingEventsProperties> = Pick<ITrackingEventsProperties, K>

export interface ITrackingOptions {
  navigationEvent?: boolean
}

export enum IAssignedByAdmin {
    Yes = 1,
    No
}

export enum IChangeID {
    AddGoal = 1,
    RemoveGoal,
    UpgradeGoal,
    DowngradeGoal
}