import { Injectable } from '@angular/core';
import {EncryptionService} from "@services/request-token/encryption.service";
import {environment} from "@environments/environment";

@Injectable({
  providedIn: 'root'
})
export class FilesManagerService {


  constructor(private encryptionService: EncryptionService) { }

  getHashedImage(name: string): string {
    let hashedImageName = this.encryptionService.hashStringWithSHA256(name);
    return `${environment.cdnUrl}/images/${hashedImageName}`;
  }

  getHashedCertImage(name: string): string {
    let hashedImageName = this.encryptionService.hashStringWithSHA256(name);
    return `${environment.cdnUrl}/certificates/certificates/${hashedImageName}.jpg`;
  }

  getFileURL(name: string): string{
    return `${environment.cdnUrl}/resources/${name}`;
  }

  getImage(name: string): string {
    return `${environment.cdnUrl}/images/${name}`;
  }

  getCourseSmallImageURL(courseId: number) {
    return this.getHashedImage(`course-${courseId}-cover-m`);
  }
}
