import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {CampaignRibbonComponent} from "@components/campaign-ribbon/campaign-ribbon.component";
import {TranslateModule} from "@ngx-translate/core";
import {AlmTranslateCutModule} from '@alm-translate-cut/alm-translate-cut.module';



@NgModule({
  declarations: [CampaignRibbonComponent],
  imports: [
    CommonModule,
    TranslateModule,
    AlmTranslateCutModule
  ],
  exports: [CampaignRibbonComponent]
})
export class CampaignRibbonModule { }
