import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {NotificationService} from '@services/integrations/notification/notification.service';
import {INotificationItem, INotificationList} from '@interfaces/notification/notification.interface';
import {UiLoaderService} from '@services/ui-loader/ui-loader.service';
import {LanguageControlService} from "@services/language/language-control.service";
import ar from './i18n/ar.json';
import en from './i18n/en.json';

@Component({
  selector: 'alm-root-header-notification-menu',
  templateUrl: './header-notification-menu.component.html',
  styleUrls: ['./header-notification-menu.component.scss']
})
export class HeaderNotificationMenuComponent implements OnInit {
  @Input() isInNotificationMenu: boolean = false;
  @Input() unReadNotificationCount: number = 0;
  @Output() onCloseMenu: EventEmitter<any> = new EventEmitter<any>();
  notificationList?: INotificationList;

  constructor(private notificationService: NotificationService,
              private uiLoader: UiLoaderService, private languageService: LanguageControlService) {
    this.languageService.setTranslations("ar", ar);
    this.languageService.setTranslations("en", en);
  }

  ngOnInit(): void {
    this.notificationService.getAllNotifications('notification-menu').then(notificationList => {
      this.notificationList = notificationList;
    });
  }

  markNotificationAsRead(notification: INotificationItem, index: number) {
    this.uiLoader.startUiLoader('notification-menu');
    this.notificationService.markNotificationAsRead('notification-menu', notification.id).then(result => {
      if (result.result && this.notificationList) {
        this.notificationList.notificationViewModels[index].isRead = true;
      }
      this.uiLoader.stopUiLoader('notification-menu');
    }).catch( () => {
      this.uiLoader.stopUiLoader('notification-menu');
    });
  }

  markAllAsRead() {
    this.notificationService.markNotificationAsRead('notification-menu').then(result => {
      if (result.result) {
        this.notificationList?.notificationViewModels.map( notification => {
          notification.isRead = true;
        });
      }
    });
  }
}
