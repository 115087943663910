import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import { environment } from '@environments/environment';
import * as Sentry from "@sentry/angular-ivy";
import packageJson from 'package.json';

const importantUrls = [
    '/subscriptions/plans',
    '/paymentMethods/byCountryId',
    '/PaymentAdapter/pay',
    '/transaction/order/trn',
    '/deepLinking/api/getUserAdOrder',
    '/paymentAdapter/api/subscription-payment',
    '/progress/api/lessonprogress/add',
];
const importantUrlsRegex = new RegExp(`${importantUrls.join('$|')}$`, 'gi');

function processBreadcrumbXHR(breadcrumb: Sentry.Breadcrumb, hint: Sentry.BreadcrumbHint | undefined): Sentry.Breadcrumb | null {
    if (!hint) return breadcrumb;
    // We use regex to have more performance than looping and checking every item in the array.
    // as this check will be used in all XHR requests
    if (importantUrlsRegex.test(breadcrumb.data?.url)) {
        const data = {
            requestBody: hint.input,
            response: hint.xhr.response,
        }
        breadcrumb.data = {...breadcrumb.data, ...data};
        return breadcrumb;
    } else {
        return breadcrumb;
    }
}

export function breadcrumbInterceptor(breadcrumb: Sentry.Breadcrumb, hint: Sentry.BreadcrumbHint | undefined): Sentry.Breadcrumb | null {
    switch (breadcrumb.category) {
        case 'xhr' :
            return processBreadcrumbXHR(breadcrumb, hint);
        default:
            return breadcrumb;
    }
}

if (environment.infrastructure !== 'local') {
    Sentry.init({
        dsn: 'https://eacb483cb38f44b1acef8aeeaeb1b842@o4505516055920640.ingest.sentry.io/4505517194346496',
        environment: environment.environmentName,
        integrations: [
          Sentry.browserTracingIntegration(),
          Sentry.replayIntegration({
            maskAllText: false,
            blockAllMedia: false,
            networkDetailAllowUrls: [environment.platforms.base.link],
            networkCaptureBodies: true
          }),
        ],
        tracesSampleRate: 0.5,
        // Session Replay
        replaysSessionSampleRate: 0.1,
        replaysOnErrorSampleRate: 0.2,
        release: packageJson.sentryRelease,
        beforeBreadcrumb: (breadcrumb, hint) => {
            return breadcrumbInterceptor(breadcrumb, hint);
        },
    });
}

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
