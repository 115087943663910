<div class="courses-menu_main-menu">
  <div class="courses-menu_main-menu_section">
    <div class="courses-menu_main-menu_section__organization-categories" *ngIf="isB2bUser && organizationCategories.length > 0">
      <p class="courses-menu_main-menu_section_title">
        <span *ngIf="currLang == 'ar'">{{'courses_menu.main_menu_title_b2b' | translate}}</span>
        {{b2bOrganizationName}}
        <span *ngIf="currLang == 'en'">{{'courses_menu.main_menu_title_b2b' | translate}}</span>
      </p>
      <button class="courses-menu_main-menu_section__organization-categories_link btn btn-link"
              [class.active]="isB2bCategoriesSelected"
              (mouseenter)="getOrganizationCategories($event)">
        {{'courses_menu.main_menu_b2b_link' | translate}}
      </button>
      <hr class="courses-menu_main-menu_section__organization-categories_separator"/>
    </div>
    <p class="courses-menu_main-menu_section_title">
      {{'courses_menu.main_menu_title' | translate}}
    </p>
    <div class="courses-menu_main-menu_section_categories-container"
         [class.courses-menu_main-menu_section_categories-container__is-b2b]="isB2bUser && organizationCategories.length > 0">
      <div
        class="courses-menu_main-menu_section_item {{(activeMenuItem == category.id) && !isB2bCategoriesSelected ? 'active-menu-item' : ''}}"
           *ngFor="let category of categories;let index=index"
           (mouseenter)="getCoursesByCategoryId($event, category.id, category.permanentLink)"
           (click)="navigateToCategoryPage($event, category.id, category.permanentLink)">
        <a>
          {{category.name}}
          <svg>
            <use href="assets/images/sprite.svg#arrow-next"/>
          </svg>
        </a>
      </div>
    </div>
  </div>
  <div class="courses-menu_main-menu_browse-btn">
    <a routerLink="/courses" class="btn btn-danger browse-btn">
      {{'courses_menu.main_menu_button_browse' | translate}}
      <svg>
        <use href="assets/images/sprite.svg#arrow-forward"/>
      </svg>
    </a>
  </div>
</div>
