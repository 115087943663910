<div class="main-dialog-container" mat-dialog-content *ngIf="currentPlan && nextPlan">
  <div class="dialog-header">
    <div class="dialog-close">
      <button type="button" (click)="closeDialog()" class="btn-alm-close">
        <svg aria-hidden="true">
          <use href="assets/images/sprite.svg#times"/>
        </svg>
      </button>
    </div>
  </div>
  <div *ngIf="userInfo && userInfo?.subscribed && userInfo?.subscriptionStatus != 3"
       class="dialog-change-subscription-content">
    <div class="dialog-change-subscription-content__title">
      <h3>{{currentPlan.duration > 30 ? ('settings_change_plan_dialog.downgrade_plan' | translate | translateCut:0) : ('settings_change_plan_dialog.upgrade_plan' | translate | translateCut:0)}} {{discountPercentage}}</h3>
    </div>

    <div *ngIf="currentPlan.duration > 30">

      <div class="dialog-change-subscription-content__plan">
        <p>
          {{'settings_change_plan_dialog.downgrade_plan' | translate | translateCut:1}} {{currentPlan.name}}
          {{'settings_change_plan_dialog.downgrade_plan' | translate | translateCut:2}} {{currentPlan.amountPerMonth?.toFixed(2)}} {{currentPlan.currency}}
          {{'settings_change_plan_dialog.downgrade_plan' | translate | translateCut:3}}
          - {{currentPlan.finalPrice}} {{currentPlan.currency}}
          {{'settings_change_plan_dialog.downgrade_plan' | translate | translateCut:4}}
          {{'settings_change_plan_dialog.downgrade_plan' | translate | translateCut:5}}
        </p>
      </div>

      <div class="dialog-change-subscription-content__plan">
        <p>
          {{'settings_change_plan_dialog.downgrade_plan' | translate | translateCut:6}} {{nextPlan.amountPerMonth?.toFixed(2)}} {{nextPlan.currency}}
          {{'settings_change_plan_dialog.downgrade_plan' | translate | translateCut:7}}
        </p>
      </div>

    </div>
    <div *ngIf="currentPlan.duration <= 30">

      <div class="dialog-change-subscription-content__plan">
        <p>
          {{'settings_change_plan_dialog.upgrade_plan' | translate | translateCut:1}} {{currentPlan.name}} {{'settings_change_plan_dialog.upgrade_plan' | translate | translateCut:2}}
          {{currentPlan.amountPerMonth}} {{currentPlan.currency}}
          {{'settings_change_plan_dialog.upgrade_plan' | translate | translateCut:3}}
        </p>
      </div>

      <div class="dialog-change-subscription-content__plan">
        <p>{{'settings_change_plan_dialog.upgrade_plan' | translate | translateCut:4}} {{nextPlan.amountPerMonth?.toFixed(2)}} {{nextPlan.currency}}
          {{'settings_change_plan_dialog.upgrade_plan' | translate | translateCut:5}}
          {{'settings_change_plan_dialog.upgrade_plan' | translate | translateCut: 7}} {{nextPlan.finalPrice}} {{nextPlan.currency}}
          {{'settings_change_plan_dialog.upgrade_plan' | translate | translateCut: 6}}
        </p>
      </div>

    </div>

    <div class="dialog-change-subscription-content__note">
      <p>{{'settings_change_plan_dialog.note' | translate}}</p>
    </div>
    <div class="dialog-change-subscription-content__footer">
      <button class="btn btn-alm-outline"
              (click)="closeDialog()">{{'settings_change_plan_dialog.buttons_text' | translate | translateCut:0}}</button>
      <button *ngIf="currentPlan.duration > 30" class="btn btn-alm-filled"
              (click)="changePlan()">{{'settings_change_plan_dialog.buttons_text' | translate | translateCut:1}}</button>
      <button *ngIf="currentPlan.duration <= 30" class="btn btn-alm-filled"
              (click)="changePlan()">{{'settings_change_plan_dialog.buttons_text' | translate | translateCut:2}}</button>
    </div>
  </div>
  <div *ngIf="userInfo && userInfo?.subscribed && userInfo?.subscriptionStatus == 3"
       class="dialog-change-subscription-content">
    <div class="dialog-change-subscription-content__title">
      <h3>{{'settings_change_plan_dialog.grace_title' | translate}}</h3>
    </div>

    <div class="dialog-change-subscription-content__plan">
      <p>
        {{'settings_change_plan_dialog.grace_description' | translate}}
      </p>
    </div>

    <div class="dialog-change-subscription-content__grace-info">
      <p>{{'settings_change_plan_dialog.grace_info' | translate}}</p>
      <svg>
        <use href="assets/images/sprite.svg#info"/>
      </svg>
    </div>

    <div class="dialog-change-subscription-content__footer">
      <button class="btn btn-alm-outline"
              (click)="closeDialog()">{{'settings_change_plan_dialog.buttons_text' | translate | translateCut:0}}</button>
    </div>
  </div>
</div>
