<div class="gtm-ribbon-container">
  <div class="gtm-ribbon-text">
    <h2>{{'gtm_page.banner_text' | translate | translateCut:0 }}</h2>
    <p>{{'gtm_page.banner_text' | translate | translateCut:1 }}</p>
  </div>
  <div class="close-icon" (click)="closeGtmRibbon()">
    <svg aria-hidden="true">
      <use href="assets/images/sprite.svg#times" />
    </svg>
  </div>
</div>
