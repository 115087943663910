import {Component, EventEmitter, OnInit, Output} from '@angular/core';

@Component({
  selector: 'alm-root-gtm-ribbon',
  templateUrl: './gtm-ribbon.component.html',
  styleUrls: ['./gtm-ribbon.component.scss']
})
export class GtmRibbonComponent implements OnInit {
  @Output() onClosingGtmRibbon: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor() { }

  ngOnInit(): void {
  }

  closeGtmRibbon() {
    this.onClosingGtmRibbon.emit(true);
  }
}
