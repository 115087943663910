import {Inject, Injectable, PLATFORM_ID} from '@angular/core';
import {isPlatformBrowser} from "@angular/common";

@Injectable({
  providedIn: 'root'
})
export class UserStorageService {

  constructor(@Inject(PLATFORM_ID) private platformId: any) {
  }

  getUserFromStorage(): string | null {
    if(isPlatformBrowser(this.platformId)){
      let ssUser = sessionStorage.getItem('uuid');
      if (ssUser) {
        return ssUser
      }
      return localStorage.getItem('uuid');
    }
    return null;
  }

  deleteUserFromStorage(): void {
    if(isPlatformBrowser(this.platformId)){
      localStorage.removeItem('uuid');
      sessionStorage.removeItem('uuid');
    }
  }

  saveUserToStorage(uuid: string, remember: boolean): void {
    if(isPlatformBrowser(this.platformId)){
      this.deleteUserFromStorage();
      if (remember)
        localStorage.setItem('uuid', uuid);
      else
        sessionStorage.setItem('uuid', uuid);
    }
  }
}
