export interface IHttpResponse {
  success: boolean;
  data: any;
  error: IHttpError;
}

export interface IHttpError {
  code: number;
  message: string;
  status: number;
}

/**
 * This enum used to unify cluster Names across application.
 * it used after the api gateway base url
 * FullUrl: apiGatewayUrl + ClusterName + endpoint
 * @see `environment.apiGatewayUrl`
 */
export enum ClusterNames {
  JavaOrch = `orchestrator`,
  NewOrch = 'new-orchestrator',
  B2BOrch = 'b2b-orchestrator',
  AdminOrch = 'admin-orchestrator',
}

export type ServiceUrls = Record<ClusterNames, string>;
