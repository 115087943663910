import {Inject, Injectable, PLATFORM_ID} from '@angular/core';
import {isPlatformServer} from "@angular/common";

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {

  constructor(
    @Inject(PLATFORM_ID) private platformId: any
  ) { }

  getItem(key: string): string | null {
    if (isPlatformServer(this.platformId)) return null;
    return localStorage.getItem(key);
  }

  setItem(key: string, value: string) {
    if (isPlatformServer(this.platformId)) return;
    localStorage.setItem(key, value);
  }
  removeItem(key: string) {
    if (isPlatformServer(this.platformId)) return;
    localStorage.removeItem(key);
  }
}
