<div class="courses-menu_sub-menu">
  <div class="courses-menu_sub-menu__courses" *ngIf="courses.length > 0 && !isB2bCategoriesSelected">
    <div *ngFor="let course of courses;">
      <a class="courses-menu_sub-menu__item" [routerLink]="'/courses/' + course.permanentLink">
        <div class="courses-menu_sub-menu__item_img">
          <img [imageLoader]="getCardImage(course.id)" [isLazyLoadedImage]="true"
               errorImage="assets/images/placeholder.png"
               [alt]="course.name"/>
        </div>
        <div class="courses-menu_sub-menu__item_text">
          <p class="courses-menu_sub-menu__item_text-title">{{course.name}}</p>
          <p class="courses-menu_sub-menu__item_text-author">
          <span *ngFor="let mentor of course.mentors | slice:0 : 3; let last = last;">
            {{mentor.name}}{{last ? '' : ' - '}}
          </span>
          </p>
        </div>
      </a>
    </div>
    <div class="text-center">
      <a [routerLink]="'/categories/' + categoryPermanentLink" class="btn btn-link">
        {{'courses_menu.sub_menu_button_view_all' | translate}}
      </a>
    </div>
  </div>
  <div class="courses-menu_sub-menu__custom-categories"
       *ngIf="isB2bCategoriesSelected && allB2bCategories && allB2bCategories.length > 0 && isB2bUser">
    <p class="courses-menu_sub-menu__custom-categories_title">
      {{'courses_menu.main_menu_b2b_view_all' | translate}}
    </p>
    <div *ngFor="let category of allB2bCategories">
      <a class="courses-menu_sub-menu__item" [routerLink]="'/categories/' + category.permanentLink">
        {{category.name}}
      </a>
    </div>
  </div>
  <div class="courses-menu_sub-menu__quick-links">
    <a routerLink="/courses/popular" class="popular-courses-link">
      <svg class="premium-icon">
        <use href="assets/images/sprite.svg#star"/>
      </svg>
      <p>{{'courses_menu.sub_menu_button_courses' | translate | translateCut:0}}</p>
    </a>
    <a routerLink="/courses/new" class="new-courses-link">
      <svg class="new-courses-icon">
        <use href="assets/images/sprite.svg#plus"/>
      </svg>
      <p>{{'courses_menu.sub_menu_button_courses' | translate | translateCut:1}}</p>
    </a>
  </div>

</div>
