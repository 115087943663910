import {PaymentMethods} from "@interfaces/common/payment.interface";

export interface ISubscriptionVariant {
  annualPlan: {
    enabled: boolean;
    paymentMethods: PaymentMethods[];
  };
  monthlyPlanPlan: {
    enabled: boolean;
    paymentMethods: PaymentMethods[];
  };
  embeddedPaymobIframe?: boolean;
}


const onlinePaymentMethods = [
  PaymentMethods.PAYMOB,
  PaymentMethods.CHECKOUT,
];

const cashPaymentMethods = [
  PaymentMethods.FAWRY,
  PaymentMethods.MOBILE_WALLET,
];

const allPaymentMethods = [
  ...onlinePaymentMethods,
  ...cashPaymentMethods,
];

const allPaymentExceptMobileWallets = allPaymentMethods
  .filter((plan) => {
    return plan !== PaymentMethods.MOBILE_WALLET
  });

export const AnnualPlanOnly: ISubscriptionVariant = {
  annualPlan: {
    enabled: true,
    paymentMethods: allPaymentExceptMobileWallets
  },
  monthlyPlanPlan: {
    enabled: false,
    paymentMethods: [],
  }
}

export const embeddedPaymobIframe: ISubscriptionVariant = {
  annualPlan: {
    enabled: true,
    paymentMethods: allPaymentExceptMobileWallets
  },
  monthlyPlanPlan: {
    enabled: true,
    paymentMethods: allPaymentExceptMobileWallets,
  },
  embeddedPaymobIframe: true,
}
export const allAvailableOptions: ISubscriptionVariant = {
  annualPlan: {
    enabled: true,
    paymentMethods: allPaymentMethods,
  },
  monthlyPlanPlan: {
    enabled: true,
    paymentMethods: allPaymentMethods,
  },
}
export const allOptionsExceptMobileWallets: ISubscriptionVariant = {
  annualPlan: {
    enabled: true,
    paymentMethods: allPaymentExceptMobileWallets,
  },
  monthlyPlanPlan: {
    enabled: true,
    paymentMethods: allPaymentExceptMobileWallets,
  },
}
