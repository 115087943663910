import { Injectable } from '@angular/core';
import {IStore, IStoreKeys} from "@services/simple-store/simple-state.interface";
import {BehaviorSubject, Observable, of} from "rxjs";
import {map} from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class SimpleStateService {
  private store: IStore = {
    videoPlaying: false,
  }
  private state: BehaviorSubject<IStore> = new BehaviorSubject<IStore>(this.store);
  constructor() { }

  getState<T extends IStoreKeys>(key: T): Observable<IStore[T]> {
    return this.state.pipe(map(state => state[key]));
  }

  setState<T extends IStoreKeys>(key: T, value: IStore[T]): void {
    this.store[key] = value;
    this.state.next(this.store);
  }
}
