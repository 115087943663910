import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InfoSnackBarComponent } from './info-snack-bar.component';
import {TranslateModule} from "@ngx-translate/core";



@NgModule({
  declarations: [InfoSnackBarComponent],
    imports: [
        CommonModule,
        TranslateModule
    ]
})
export class InfoSnackBarModule { }
