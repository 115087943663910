import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {INotificationItem} from '@interfaces/notification/notification.interface';
import {Router} from "@angular/router";
import {FilesManagerService} from "@services/files/files-manager.service";
import {UserService} from "@services/integrations/user/user.service";
import {NotificationService} from "@services/integrations/notification/notification.service";
import {UiLoaderService} from "@services/ui-loader/ui-loader.service";

@Component({
  selector: 'alm-root-header-notification-menu-discussion-card',
  templateUrl: './header-notification-menu-discussion-card.component.html',
  styleUrls: ['./header-notification-menu-discussion-card.component.scss']
})
export class HeaderNotificationMenuDiscussionCardComponent implements OnInit {

  @Input() notification?: INotificationItem;
  @Output() markAsRead: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() closeMenu: EventEmitter<boolean> = new EventEmitter<boolean>();
  isSelected: boolean = false;
  dateToday = new Date();

  constructor(private router: Router, private filesManager: FilesManagerService, private userService: UserService,
              private notificationService: NotificationService, private uiLoader: UiLoaderService) {
  }

  ngOnInit(): void {
  }

  onNotificationMenuClosing() {
    this.isSelected = false;
  }

  markNotificationAsRead(): void {
    this.markAsRead.emit(true);
  }

  goToDiscussion($event: MouseEvent) {
    $event.stopPropagation();
    if (this.notification && this.notification.courseId && (this.notification.announcementId || (this.notification.sectionId && this.notification.lessonId))) {
      let state = {};
      if (this.notification.announcementId) {
        state = {announcement: true, announcementId: this.notification.announcementId};
      } else if (this.notification.sectionId && this.notification.lessonId) {
        state = {discussion: true, lessonId: this.notification.lessonId, sectionId: this.notification.sectionId};
      }
      this.uiLoader.startUiLoader('get-permanentLink');
      this.notificationService.getCoursePermanentLinkById(this.notification.courseId).then(({permanentLink}) => {
        if (this.notification) {
          this.router.navigate([`/viewer/${permanentLink}`], {state: state});
          this.markAsRead.emit();
          this.closeMenu.emit();
        }
      }).finally(() => {
        this.uiLoader.stopUiLoader('get-permanentLink');
      });
    }
  }

  getUserImage(id: string | undefined) {
    return this.filesManager.getHashedImage(`user-${id}-profile`)
  }
}
