<div class="auth-content">
  <div class="auth-header">
    <h5 class="auth-title text-center text-capitalize">
      {{'interests_container.page_title' | translate}}
    </h5>
  </div>

  <div class="auth-body">
    <p class="text-center interests-subtitle">{{'interests_container.helper_content' | translate}}</p>
    <p class="pick-interests">*{{'interests_container.pick_several' | translate}}</p>
    <div class="btn-group-toggle form-group">
      <alm-root-interest-checkbox *ngFor="let interest of interests"
                                  [isSelected]="false"
                                  [checkedValue]="interest"
                                  (selectionChange)="onSelect(interest)">
      </alm-root-interest-checkbox>
    </div>
    <div class="form-group">
      <button class="btn btn-danger w-100 mt-3 button-font" [disabled]="selectedInterestsIds.size < 1"
              (click)="saveUserInterests()">{{'interests_container.finish' | translate}}</button>
    </div>
  </div>

</div>
