import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ChangeMobileDialogComponent} from './change-mobile-dialog.component';
import {
  ChangeMobileActivateNumberComponent
} from './change-mobile-activate-number/change-mobile-activate-number.component';
import {ChangeMobileEnterNumberComponent} from './change-mobile-enter-number/change-mobile-enter-number.component';
import {MatDialogModule} from '@angular/material/dialog';
import {TranslateModule} from '@ngx-translate/core';
import {OtpInputModule} from '@components/otp-input/otp-input.module';
import {AlmTranslateCutModule} from '@alm-translate-cut/alm-translate-cut.module';
import {PhoneInputModule} from '@components/phone-input/phone-input.module';
import {ReactiveFormsModule} from '@angular/forms';


@NgModule({
  declarations: [ChangeMobileDialogComponent, ChangeMobileActivateNumberComponent, ChangeMobileEnterNumberComponent],
  imports: [
    CommonModule,
    MatDialogModule,
    TranslateModule,
    OtpInputModule,
    AlmTranslateCutModule,
    PhoneInputModule,
    ReactiveFormsModule
  ]
})
export class ChangeMobileDialogModule { }
