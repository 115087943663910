import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {NotificationSoftRequestComponent} from './notification-soft-request.component';
import {TranslateModule} from "@ngx-translate/core";
import {MatDialogModule} from "@angular/material/dialog";


@NgModule({
  declarations: [
    NotificationSoftRequestComponent
  ],
  imports: [
    CommonModule,
    TranslateModule,
    MatDialogModule
  ]
})
export class NotificationSoftRequestModule {
}
