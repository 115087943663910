import {Component, EventEmitter, Inject, Input, OnDestroy, OnInit, Output, PLATFORM_ID} from '@angular/core';
import {IUserInfo} from '@interfaces/authorized-user/user.interface';
import {environment} from '@environments/environment';
import {Subscription} from 'rxjs';
import {UiLoaderService} from '@services/ui-loader/ui-loader.service';
import ar from './i18n/ar.json';
import en from './i18n/en.json';
import {LanguageControlService} from '@services/language/language-control.service';
import {UserService} from '@services/integrations/user/user.service';
import {ISubscriptionPlan} from '@interfaces/subscription/subscription.interface';
import {isPlatformBrowser} from "@angular/common";
import {AuthV2Service} from "@services/integrations/auth-v2/auth-v2.service";
import {AppRedirectsService} from "@services/app-redirects/app-redirects.service";
import {ConnectorV2Service} from "@services/connector/connector-v2.service";
import {UserManagerService} from "@services/user/user-manager.service";
import {NotificationManagerService} from "@services/notification-manager/notification-manager.service";
import {
  UserSettingsChangePlanDialogComponent
} from '@pages/user-settings-page/components/user-settings-change-plan-dialog/user-settings-change-plan-dialog.component';
import {Router} from '@angular/router';
import {DialogService} from '@services/custom-dialogs/dialog.service';

@Component({
  selector: 'alm-root-header-user-menu',
  templateUrl: './header-user-menu.component.html',
  styleUrls: ['./header-user-menu.component.scss']
})
export class HeaderUserMenuComponent implements OnInit, OnDestroy {

  @Input() userInfo?: IUserInfo | null;
  @Input() userAvatar?: string;
  @Input() numberOfUnreadMessages?: number;
  @Input() isB2bUser: boolean = false;
  @Output() onItemSelect: EventEmitter<any> = new EventEmitter<any>();
  plan?: ISubscriptionPlan;
  private subscriptions: Subscription[] = [];

  constructor(private uiLoader: UiLoaderService, @Inject(PLATFORM_ID) private platformId: any, private userService: UserService,
              private languageControl: LanguageControlService, private appRedirects: AppRedirectsService,
              private authV2Service: AuthV2Service, private connectorService: ConnectorV2Service,
              private userManagerService: UserManagerService, private notificationManager: NotificationManagerService,
              private router: Router,
              private dialogService: DialogService,
              ) {
    this.languageControl.setTranslations('ar', ar);
    this.languageControl.setTranslations('en', en);
  }

  ngOnInit(): void {
    // if(this.userInfo && this.numberOfUnreadMessages === undefined) {
    //   this.chatService.getUnreadMsg("main-header", true).then((unreadMessages) => {
    //     this.numberOfUnreadMessages = unreadMessages.count;
    //   });
    // }
    if (!this.isB2bUser) {
      this.userService.getUserSubscriptionPlan('header-user-menu').then(plan => {
        this.plan = plan;
      });
    }
  }

  itemClicked() {
    this.onItemSelect.emit();
  }

  logOutUser() {
    this.uiLoader.startUiLoader('logout-loader');
    this.notificationManager.deleteNotificationToken().finally(() => {
      this.authV2Service.logUserOut('profile-menu').then(() => {
        if (this.userManagerService.userPlatform?.toLowerCase() === environment.platforms.b2c.code.toLowerCase()) {
          this.connectorService.removeUser();
        } else {
          this.appRedirects.redirectToSSO(this.userManagerService.userPlatform, '/home', 'redirect-center', undefined, 'prevErr=000');
        }
      }).catch(() => {
        this.uiLoader.stopUiLoader('logout-loader');
      });
    })
  }

  ngOnDestroy(): void {
    this.subscriptions.map((sub) => {
      sub.unsubscribe();
    });
  }

  switchToMentorLogin() {
    if (isPlatformBrowser(this.platformId)) {
      window.open(environment.platforms.dashboard.link);
      this.onItemSelect.emit();
    }
  }

  onUpgradeClick() {
    this.router.navigate(['/settings/subscription-plan']).then(() => {
      this.openChangePlanPopup();
    });
  }

  private openChangePlanPopup() {
    this.dialogService.openDialog(UserSettingsChangePlanDialogComponent, 'change-subscription-plan', {plan: this.plan});
  }
}
