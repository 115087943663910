import {Injectable} from '@angular/core';
import {CustomHttpService} from "@services/request-token/custom-http.service";
import {IChatResponse, INewThreadChatResponse} from "@interfaces/chat/chat.interface";
import {ClusterNames} from "@interfaces/common/http.interface";

@Injectable({
  providedIn: 'root'
})
export class ChatHttpService {

  constructor(private customHttpService: CustomHttpService) {
  }

  getAllChatsForLearner(sender: string, languageId: number, courseId: number): Promise<IChatResponse[]> {
    return this.customHttpService.sendRequest({
      clusterName: ClusterNames.JavaOrch,
      endpoint: 'api/chats/course',
      sender: sender,
      receiver: 'chatsOfLearner',
      body: {
        courseID: courseId,
        languageId: languageId,
        isLearner: true
      }
    });
  }

  createThreadChat(sender: string, mentorId: number, mentorUUID: number
    , courseId: number, isLearner: boolean): Promise<INewThreadChatResponse> {
    return this.customHttpService.sendRequest({
      clusterName: ClusterNames.JavaOrch,
      endpoint: 'api/chats/createThreadChat',
      sender: sender,
      receiver: 'createThreadChat',
      body: {
        mentorID: mentorId,
        mentorUUID: mentorUUID,
        courseID: courseId,
        isLearner: isLearner
      }
    });
  }

  getThreadChatById(sender: string, threadId: string, languageId: number) {
    return this.customHttpService.sendRequest({
      clusterName: ClusterNames.JavaOrch,
      endpoint: 'api/chats/thread/chat',
      sender: sender,
      receiver: 'getThreadChatById',
      body: {
        id: threadId,
        languageId: languageId
      }
    })
  }

  addChatMsg(sender: string, threadChatID: string, message: string, isLearner: boolean): Promise<{result: boolean}> {
    return this.customHttpService.sendRequest({
      clusterName: ClusterNames.JavaOrch,
      endpoint: 'api/chats/addChatMsg',
      sender: sender,
      receiver: 'addChatMsg',
      body: {
        threadChatID: threadChatID,
        message: message,
        isLearner: isLearner
      }
    });
  }

  updateChatStatus(sender: string, threadChatID: string, isLearner: boolean): Promise<{result: boolean}> {
    return this.customHttpService.sendRequest({
      clusterName: ClusterNames.JavaOrch,
      endpoint: 'api/chats/updateChatStatus',
      sender: sender,
      receiver: 'updateChatStatus',
      body: {
        threadChatID: threadChatID,
        isLearner: isLearner,
      }
    });
  }

  getUnreadMsg(sender: string, isLearner: boolean) {
    return this.customHttpService.sendRequest({
      clusterName: ClusterNames.JavaOrch,
      endpoint: 'api/chats/getUnreadMsg',
      sender: sender,
      receiver: 'getUnreadMsg',
      body: {
        isLearner: isLearner,
      }
    });
  }

  getChatDetails(sender: string, languageId: number, isLearner: boolean): Promise<IChatResponse[]> {
    return this.customHttpService.sendRequest({
      clusterName: ClusterNames.JavaOrch,
      endpoint: 'api/chats/details',
      sender: sender,
      receiver: 'getChatDetails',
      body: {
        languageId: languageId,
        isLearner: isLearner
      }
    })
  }
}
