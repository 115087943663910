import {Inject, Injectable, PLATFORM_ID} from '@angular/core';
import {DynamicLoadService} from "@services/dynamic-load/dynamic-load.service";
import {LanguageControlService} from "@services/language/language-control.service";
import {isPlatformServer} from "@angular/common";
import {UAParser} from "ua-parser-js";

type zESettings = {};

@Injectable({
  providedIn: 'root'
})
export class ZendeskService {

  constructor(private dynamicLoad: DynamicLoadService,
              private languageControl: LanguageControlService,
              @Inject(PLATFORM_ID) private platformId: any
  ) {
  }

  loadZendeskWidget(id: string): void {
    if(isPlatformServer(this.platformId)) return;
    let ua = new UAParser();
    if(ua.getDevice().type == 'mobile') return;
    this.dynamicLoad.externalScript('ze-snippet', `https://static.zdassets.com/ekr/snippet.js?key=${id}`).then((loaded) => {
      this.languageControl.currentLanguage.subscribe((lang) => {
        (<any>window).zESettings = {
          webWidget: {
            position: {horizontal: lang.id == 2 ? 'left' : 'right'},
            zIndex: 900,
          }
        };
        (<any>window).zE('webWidget', 'updateSettings', {
          webWidget: {
            position: {horizontal: lang.id == 2 ? 'left' : 'right'}
          }
        });
      });
    });
  }
}
