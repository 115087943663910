<div *ngIf="notification" class="header-notification-menu-announcement-card selected"
     [class.is-selected]="isSelected" (click)="goToAnnouncement($event)">
  <img [imageLoader]="getMentorImage(notification.mentorUUID)"
       errorImage="assets/images/default-avatar-placeholder-dark.jpg" [isLazyLoadedImage]="true"
       alt="mentor-avatar" class="header-notification-menu-announcement-card__image profile-image-dark"/>
  <img [imageLoader]="getMentorImage(notification.mentorUUID)"
       errorImage="assets/images/default-avatar-placeholder-light.jpg" [isLazyLoadedImage]="true"
       alt="mentor-avatar" class="header-notification-menu-announcement-card__image profile-image-light"/>
  <div class="header-notification-menu-announcement-card__mentor-info" [class.in-notification-menu]="isInNotificationMenu">
    <p class="header-notification-menu-announcement-card__title">
      {{notification.body}}
    </p>
    <img [imageLoader]="getAnnouncementCover(notification.announcementId)"
         errorImage="assets/images/placeholder.png" [isLazyLoadedImage]="true"
         class="header-notification-menu-announcement-card__notification-img" alt="announcement-cover">
  </div>
  <div class="header-notification-menu-announcement-card__message-history-info">
    <button *ngIf="!notification.isRead" class="header-notification-menu-announcement-card__menu-icon btn btn-icon" [matMenuTriggerFor]="notificationControlsMenu" mat-button [disableRipple]="true" (click)="isSelected = true; $event.stopPropagation()">
      <svg>
        <use href="assets/images/sprite.svg#menu"/>
      </svg>
    </button>
    <mat-menu #notificationControlsMenu="matMenu" yPosition="below" xPosition="before" backdropClass="notification-controls-menu" (closed)="onNotificationMenuClosing()">
      <button mat-menu-item (click)="$event.stopPropagation();markNotificationAsRead()">
        {{'notification_card.mark_as_read' | translate}}
      </button>
    </mat-menu>
    <div class="d-flex flex-column">
      <div *ngIf="!notification.isRead" class="header-notification-menu-announcement-card__red-dot"></div>
      <div *ngIf="notification.createdAt" class="header-notification-menu-announcement-card__message-date">
        {{notification.createdAt.substring(0, 10) == dateToday.toISOString().substring(0, 10) ?
        ('notification_card.today' | translate) : notification.createdAt | date: 'dd/MM/yyyy'}}
      </div>
    </div>
  </div>
</div>

