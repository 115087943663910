import { InjectionToken, ModuleWithProviders, NgModule } from '@angular/core';
import {AlmTranslateCutPipe} from "./alm-translate-cut.pipe";
import {NgxTranslateCutOptions} from "./alm-translate-cut.options.interface";
import {AlmTranslateCutOptionsService} from "./ngx-translate-cut.options.service";
import {almTranslateCutOptionsFactory} from "./alm-translate-cut.options.factory";


export let FOR_ROOT_OPTIONS_TOKEN = new InjectionToken<NgxTranslateCutOptions>(
  'forRoot() AlmTranslateCutOptionsService configuration.'
);

@NgModule({
  declarations: [AlmTranslateCutPipe],
  exports: [AlmTranslateCutPipe],
  providers: [AlmTranslateCutOptionsService],
})
export class AlmTranslateCutModule {
  public static forRoot(
    options?: NgxTranslateCutOptions
  ): ModuleWithProviders<AlmTranslateCutModule> {
    return {
      ngModule: AlmTranslateCutModule,
      providers: [
        {
          provide: FOR_ROOT_OPTIONS_TOKEN,
          useValue: options,
        },
        {
          provide: AlmTranslateCutOptionsService,
          useFactory: almTranslateCutOptionsFactory,
          deps: [FOR_ROOT_OPTIONS_TOKEN],
        },
      ],
    };
  }
}
