export enum EPrivilegesPermissions {
  AllowAll = '00',
  BlockAll = '01',
  AllowAllExcept = '02',
  BlockAllExcept = '03'
}

export enum EPrivilegesPlatform {
  ALMENTOR = '00',
  B2bBase = 'XX'
}

export interface IPrivileges {
  functions: { [key: string]: string },
  actions: { [key: string]: string }
}

export interface IUserPrivileges {
  website: IFunctionActions,
  homePage: IFunctionActions
}

export interface IFunctionActions {
  access: boolean,
  create: boolean,
  edit: boolean,
  delete: boolean
}
