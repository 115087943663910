<div class="header-notification">
  <div *ngIf="notificationList && notificationList.notificationViewModels.length > 0" class="header-notification-menu"
       (click)="$event.preventDefault();$event.stopPropagation();">
    <div class="header-notification-menu__title-container" [class.view-all]="unReadNotificationCount == 0">
      <a *ngIf="unReadNotificationCount > 0" class="header-notification-menu__title btn btn-link"
         (click)="markAllAsRead()">
        {{'user_control_menu.notification_mark_all_read' | translate}}</a>
      <a class="header-notification-menu__subtitle btn btn-link" (click)="onCloseMenu.emit()"
         [routerLink]="'/notifications'">{{'user_control_menu.notification_view_all' | translate}}</a>
    </div>
    <div class="header-notification-menu__cards-container">
      <div class="header-notification-menu__cards-wrapper">
        <div *ngFor="let notification of notificationList.notificationViewModels; index as index">
          <div *ngIf="notification.templateId == 1">
            <alm-root-header-notification-menu-announcement-card
              (closeMenu)="onCloseMenu.emit()"
              [notification]="notification"
              [isInNotificationMenu]="isInNotificationMenu"
              (markAsRead)="markNotificationAsRead(notification, index)"
            ></alm-root-header-notification-menu-announcement-card>
          </div>
          <div *ngIf="notification.templateId == 2 || notification.templateId == 3">
            <alm-root-header-notification-menu-discussion-card [notification]="notification"
                                                               (closeMenu)="onCloseMenu.emit()"
                                                               (markAsRead)="markNotificationAsRead(notification, index)"
            ></alm-root-header-notification-menu-discussion-card>
          </div>
          <div *ngIf="notification.templateId == 5">
            <alm-root-header-notification-menu-card [notification]="notification"
                                                    (markAsRead)="markNotificationAsRead(notification, index)"
                                                    (closeMenu)="onCloseMenu.emit()"
            ></alm-root-header-notification-menu-card>
          </div>
          <div *ngIf="notification.templateId == 6">
            <alm-root-header-notification-menu-system-card [notification]="notification"
                                                           (markAsRead)="markNotificationAsRead(notification, index)"
            ></alm-root-header-notification-menu-system-card>
          </div>
          <div *ngIf="notification.templateId == 7">
            <alm-root-header-notification-menu-system-card [notification]="notification" [templateId]="7"
                                                           (markAsRead)="markNotificationAsRead(notification, index)"
            ></alm-root-header-notification-menu-system-card>
          </div>
          <div *ngIf="notification.templateId == 8">
            <alm-root-header-notification-menu-organization-note-card [notification]="notification"
                                                                      (markAsRead)="markNotificationAsRead(notification, index)"
            ></alm-root-header-notification-menu-organization-note-card>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="!notificationList || notificationList.notificationViewModels.length <= 0"
       class="header-notification-empty">
    <img class="image-dark" src="assets/images/notification-empty-dark.svg" loading="lazy"/>
    <img class="image-light" src="assets/images/notification-empty-light.svg" loading="lazy">
    <h3 class="header-notification-empty_title">{{'notification_menu.empty' | translate | translateCut: 0}}</h3>
    <p class="header-notification-empty_description">{{'notification_menu.empty' | translate | translateCut: 1}}</p>
  </div>
</div>
