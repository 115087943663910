import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {DialogService} from '@services/custom-dialogs/dialog.service';
import {LanguageControlService} from '@services/language/language-control.service';
import ar from './i18n/ar.json';
import en from './i18n/en.json';
import {IDemographics} from '@interfaces/common/auth.interface';
import {UserService} from '@services/integrations/user/user.service';
import {IHttpError} from '@interfaces/common/http.interface';
import {UiLoaderService} from '@services/ui-loader/ui-loader.service';
import {SnackBarControlService} from '@services/snack-bar/snack-bar-control.service';
import {AmplitudeService} from '@services/marketing/amplitude.service';
import {UserManagerService} from "@services/user/user-manager.service";
import { CustomContentService } from '@services/custom-content/custom-content.service';

enum AuthViews {
  verify,
  demographics,
  interests,
  close
}

@Component({
  selector: 'alm-root-auth-dialog-container',
  templateUrl: './auth-dialog-container.component.html',
  styleUrls: ['./auth-dialog-container.component.scss']
})
export class AuthDialogContainerComponent implements OnInit {

  currentView: AuthViews = AuthViews.verify;
  hasCustomContent: boolean = false;
  constructor(@Inject(MAT_DIALOG_DATA) private viewData: { type: AuthViews }, private dialogService: DialogService,
              private languageControl: LanguageControlService, private userService: UserService,
              private uiLoader: UiLoaderService, private snackBarControl: SnackBarControlService,
              private amplitudeService: AmplitudeService, private userManagerService: UserManagerService, 
              public customContentService: CustomContentService) {
    this.languageControl.setTranslations('ar', ar);
    this.languageControl.setTranslations('en', en);
  }

  ngOnInit(): void {
    if (this.viewData) {
      this.currentView = this.viewData.type;
    }
    this.customContentService.hasCustomContent$.subscribe(value => {
      this.hasCustomContent = value;
    });

  }

  changeView(view: AuthViews) {
    if (view == AuthViews.close) {
      this.closeDialog();
    } else {
      if (view == AuthViews.interests && (this.userManagerService.userInfo.getValue()?.interests || this.hasCustomContent)) {
        this.closeDialog();
      } else {
        this.currentView = view;
      }
    }
  }

  closeDialog() {
    this.dialogService.closeDialog('auth');
  }

  saveDemographicsInfo($event: IDemographics) {
    this.uiLoader.startUiLoader('http-loader');
    this.userService.saveUserDemographics('demographics', $event).then((response) => {
      this.amplitudeService.profileInfoEnteredEvent();
      let currentUser = this.userManagerService.userInfo.getValue();
      if (currentUser && currentUser.interests) {
        this.closeDialog();
      } else {
        this.changeView(2);
      }
    }).catch((e: IHttpError) => {
      this.snackBarControl.openHttpErrorSnackbar(e.code);
    }).finally(() => {
      this.uiLoader.stopUiLoader('http-loader');
    });
  }

  saveInterests($event: number []): void {
    if ($event.length > 0) {
      this.uiLoader.startUiLoader('http-loader');
      this.userService.saveUserInterests('interests-container', $event).then(({result}) => {
        this.amplitudeService.interestsSelectedEvent();
        if (result) {
          let currentUser = this.userManagerService.userInfo.getValue();
          if (currentUser) {
            currentUser.interests = true;
            this.userManagerService.userInfo.next(currentUser);
          }
          this.closeDialog();
        } else {
          this.snackBarControl.openErrorSnackbar(`http_error.0`);
        }
      }).catch((e: IHttpError) => {
        this.snackBarControl.openHttpErrorSnackbar(e.code);
      }).finally(() => {
        this.uiLoader.stopUiLoader('http-loader');
      });
    }
  }
}
