import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {IInterest} from '@interfaces/common/auth.interface';

@Component({
  selector: 'alm-root-interest-checkbox',
  templateUrl: './interest-checkbox.component.html',
  styleUrls: ['./interest-checkbox.component.scss']
})
export class InterestCheckboxComponent implements OnInit {

  @Input() isSelected: boolean = false;
  @Input() checkedValue: IInterest = {rankedTagId: 0, name: '', isSelected: false};
  @Output() selectionChange: EventEmitter<boolean> = new EventEmitter<boolean>(false);
  constructor() { }

  ngOnInit(): void {
  }

  setSelectionChange() {
    this.isSelected = !this.isSelected;
    this.checkedValue.isSelected = !this.checkedValue.isSelected;
    this.selectionChange.emit(this.isSelected);
  }
}
