import {Injectable} from '@angular/core';
import {
  EPrivilegesPermissions,
  EPrivilegesPlatform,
  IPrivileges,
  IUserPrivileges
} from '@interfaces/privileges/privileges.interface';
import {allowAllPrivileges, blockAllPrivileges, privileges} from '@services/privileges/privileges';

@Injectable({
  providedIn: 'root'
})
export class PrivilegesControlService {

  private allPrivileges: IPrivileges = privileges;

  constructor() {
  }

  getUserPrivileges(userToken: string): IUserPrivileges {
    let parsedUserToken: string[] = userToken.split('-');
    let permission = parsedUserToken[0];
    parsedUserToken.splice(0, 1);
    let userPrivileges = blockAllPrivileges;
    if (permission === EPrivilegesPermissions.AllowAll || permission === EPrivilegesPermissions.AllowAllExcept) {
      userPrivileges = allowAllPrivileges;
    }
    if (permission === EPrivilegesPermissions.AllowAllExcept || permission === EPrivilegesPermissions.BlockAllExcept) {
      userPrivileges.website = {access: true, edit: true, delete: true, create: true};
      userPrivileges = this.setFunctionActionStatus(userPrivileges, parsedUserToken, (permission === EPrivilegesPermissions.BlockAllExcept));
    }
    // console.log("userPrivileges", userPrivileges)
    return userPrivileges;
  }

  private setFunctionActionStatus(userPrivileges: IUserPrivileges, parsedToken: string[], status: boolean): IUserPrivileges {
    type userPrivilegeKey = keyof typeof userPrivileges;
    parsedToken.map((pffa) => {
      let platform = pffa.substring(0, 2);
      let func = pffa.substring(2, 6);
      let action = pffa.substring(6);
      let functionKey: userPrivilegeKey = this.allPrivileges.functions[func] as userPrivilegeKey;
      if (functionKey) {
        if (userPrivileges.hasOwnProperty(functionKey)) {
          let functionActions = userPrivileges[functionKey];
          type userPrivilegeActionKey = keyof typeof functionActions;
          let actionKey: userPrivilegeActionKey = this.allPrivileges.actions[action] as userPrivilegeActionKey;
          if (actionKey) {
            if (userPrivileges[functionKey].hasOwnProperty(actionKey)) {
              userPrivileges[functionKey][actionKey] = status;
            }
          }
        }
      }
    });
    return userPrivileges;
  }
}
