<div mat-dialog-content class="main-dialog-container" *ngIf="newSelectedPlan">
  <div class="main-dialog-container__close">
    <button type="button" (click)="closeDialog()" class="btn-alm-close">
      <svg aria-hidden="true">
        <use href="assets/images/sprite.svg#times"/>
      </svg>
    </button>
  </div>
  <div *ngIf="newSelectedPlan" class="main-dialog-container__body">

    <svg class="logo" aria-hidden="true">
      <use href="assets/images/sprite.svg#check"/>
    </svg>
    <div class="main-dialog-container__header">
      {{'settings_subscription_plan_details.call_to_action' | translate | translateCut: 2}}</div>
    <div *ngIf="newSelectedPlan.duration<31">
      {{'settings_confirm_change_plan_dialog'|translate|translateCut:1}}{{this.date |  date :'dd MMMM yyyy' }}
    </div>
    <div *ngIf="newSelectedPlan.duration>31">
      {{'settings_confirm_change_plan_dialog'|translate|translateCut:0}}   {{this.date |  date :'dd MMMM yyyy' }}
    </div>
    <div class="main-dialog-container__actions">
      <button class="btn btn-danger regular-button btn-alm-filled"
              (click)="closeDialog()">{{'settings_subscription_plan_details.call_to_action' | translate | translateCut: 3}}</button>
    </div>
  </div>
</div>
