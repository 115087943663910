import {Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation} from '@angular/core';
import {ISimplifiedCategory} from "@interfaces/course/category.interface";
import {BrazeCoursesEventsService} from "@services/marketing/braze/events/braze-courses-events.service";
import {Router} from "@angular/router";

@Component({
  selector: 'alm-root-courses-menu-main-menu',
  templateUrl: './courses-menu-main-menu.component.html',
  styleUrls: ['./courses-menu-main-menu.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CoursesMenuMainMenuComponent implements OnInit {

  @Input() categories?: ISimplifiedCategory[];
  @Input() organizationCategories: ISimplifiedCategory[] = [];
  @Input() isB2bUser?: boolean | null;
  @Input() isB2bCategoriesSelected: boolean = false;
  @Input() b2bOrganizationName?: string;
  @Input() currLang: string = 'en';
  @Output() categoryId: EventEmitter<number> = new EventEmitter<number>();
  @Output() onViewOrganizationCategories: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() categoryPermanentLink: EventEmitter<string> = new EventEmitter<string>();
  activeMenuItem?: number;

  constructor( private brazeCoursesEventsService: BrazeCoursesEventsService,
               private router: Router) { }

  ngOnInit(): void {
  }

  getCoursesByCategoryId(e: MouseEvent, categoryId: number, permanentLink: string) {
    e.stopPropagation();
    setTimeout(() => {
      this.activeMenuItem = categoryId;
      this.categoryId.emit(categoryId);
      this.categoryPermanentLink.emit(permanentLink);
    }, 100)
  }
  getOrganizationCategories(e: MouseEvent) {
    e.stopPropagation();
    this.onViewOrganizationCategories.emit(true);
  }
  navigateToCategoryPage($event: MouseEvent, categoryId: number, permanentLink: string) {
    this.brazeCoursesEventsService.categoryClick(categoryId);
    this.router.navigate([`/categories/${permanentLink}`])
  }
}
