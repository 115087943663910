import {Component, Input, OnInit} from '@angular/core';
import {LanguageControlService} from '@services/language/language-control.service';
import ar from './i18n/ar.json';
import en from './i18n/en.json';
import {environment} from "@environments/environment";

@Component({
  selector: 'alm-root-simplified-footer',
  templateUrl: './simplified-footer.component.html',
  styleUrls: ['./simplified-footer.component.scss']
})
export class SimplifiedFooterComponent implements OnInit {
  @Input() inPaymentWebsite?: boolean;
  today: Date = new Date();

  constructor(private languageControl: LanguageControlService) {
    this.languageControl.setTranslations('ar', ar);
    this.languageControl.setTranslations('en', en);
  }

  ngOnInit(): void {
  }

  navigationToFooterLinks(route: string, isHelpCenter?: boolean) {
    if(this.inPaymentWebsite) {
      if(isHelpCenter) {
        window.open('https://almentorbusiness.zendesk.com/hc/en-us')
      } else {
        window.open(`${environment.platforms.marketing.link}${route}`);
      }
    } else {
      if(isHelpCenter) {
        window.open('https://support.almentor.net')
      } else {
        window.open(`https://about.almentor.net${route}`);
      }
    }
  }
}
