import { Injectable } from '@angular/core';
import {SharedSessionStorageService} from "@services/shared-session-storage/shared-session-storage.service";
import {NavigationStart, PRIMARY_OUTLET, Route, Router, UrlSegment, UrlSegmentGroup, UrlTree} from "@angular/router";
import {LocalStorageService} from "@services/local-storage/local-storage.service";
import {CookieService} from "ngx-cookie";
import {environment} from "@environments/environment";
import {baseLinkCookieOptions, SUBSCRIPTION_INITIATOR_URL} from "@constants/cookies.constants";
@Injectable({
  providedIn: 'root'
})
export class SubscriptionRedirectService {

  constructor(
    private localStorageService: LocalStorageService,
    private cookieService: CookieService,
    private router: Router) { }

  storeSubscriptionInitiatorUrl(navigationStartEvent: NavigationStart) {
    if (navigationStartEvent.url !== '/subscription-plans') return;
    const previousUrl = this.router.routerState.snapshot.url;
    const decodedUrl = decodeURIComponent(previousUrl);

    let cookieDate = new Date();
    cookieDate.setDate(cookieDate.getDate() + 1);
    this.cookieService.put(SUBSCRIPTION_INITIATOR_URL, decodedUrl, {
      ...baseLinkCookieOptions,
      expires: cookieDate,
    });

    this.localStorageService.setItem(SUBSCRIPTION_INITIATOR_URL, decodedUrl);
  }

  getSubscriptionInitiatorUrl(): string | undefined {
    const storedUrl = this.cookieService.get(SUBSCRIPTION_INITIATOR_URL);
    if (storedUrl) {
      this.cookieService.remove(SUBSCRIPTION_INITIATOR_URL, baseLinkCookieOptions);
      return storedUrl;
    } else {
      return undefined;
    }
  }
}
