import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {
  SettingsDeviceManagementDialogContainerComponent
} from './settings-device-management-dialog-container.component';
import {DeviceManagementModule} from '@components/device-management/device-management.module';
import {MatDialogModule} from '@angular/material/dialog';


@NgModule({
  declarations: [SettingsDeviceManagementDialogContainerComponent],
  imports: [
    CommonModule,
    DeviceManagementModule,
    MatDialogModule
  ]
})
export class SettingsDeviceManagementDialogContainerModule { }
