import {Injectable} from '@angular/core';
import {CustomHttpService} from '@services/request-token/custom-http.service';
import {ClusterNames} from '@interfaces/common/http.interface';
import {IB2BBanner, IOrganizationInfo} from '@interfaces/b2b-home-banner/b2b-home-banner.interface';

@Injectable({
  providedIn: 'root'
})
export class B2bHomeBannerHttpService {

  constructor(private customHttpService: CustomHttpService) {
  }

  getB2BBannerData(): Promise<IB2BBanner> {
    return this.customHttpService.sendRequest({
      clusterName: ClusterNames.NewOrch,
      endpoint: 'banner/api/get-banner',
      sender: 'banner',
      receiver: 'get-banner',
      body: {},
    });
  }

  getB2bOrganizationInfo(sender: string): Promise<IOrganizationInfo> {
    return this.customHttpService.sendRequest({
      clusterName: ClusterNames.NewOrch,
      endpoint: 'getOrganization/api/organization-info',
      sender: sender,
      receiver: 'organization-info',
      body: {},
    })
  }
}
