import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {GtmRibbonComponent} from "@components/gtm-ribbon/gtm-ribbon.component";
import {TranslateModule} from "@ngx-translate/core";
import {AlmTranslateCutModule} from '@alm-translate-cut/alm-translate-cut.module';



@NgModule({
  declarations: [GtmRibbonComponent],
  imports: [
    CommonModule,
    TranslateModule,
    AlmTranslateCutModule
  ],
  exports: [GtmRibbonComponent]
})
export class GtmRibbonModule { }
