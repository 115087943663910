import {Component, OnInit, ViewEncapsulation} from '@angular/core';

@Component({
  selector: 'alm-root-footer-almentor-v2',
  templateUrl: './footer-almentor-v2.component.html',
  styleUrls: ['./footer-almentor-v2.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class FooterAlmentorV2Component implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
