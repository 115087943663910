import {AfterViewInit, Component, Inject, PLATFORM_ID} from '@angular/core';
import {environment} from '@environments/environment';
import {UserBackService} from '@services/demo/user-back.service';
import {ActivatedRoute, ChildActivationEnd, NavigationStart, Router} from '@angular/router';
import {IUserInfo} from '@interfaces/authorized-user/user.interface';
import {BehaviorSubject} from 'rxjs';
import {ToastrService} from 'ngx-toastr';
import {DialogService} from '@services/custom-dialogs/dialog.service';
import ar from './i18n/ar.json';
import en from './i18n/en.json';
import {LanguageControlService} from '@services/language/language-control.service';
import {NotificationService} from '@services/integrations/notification/notification.service';
import {ILoyaltyInfo} from '@interfaces/user/user-common.interface';
import {ChatService} from '@services/integrations/chat/chat.service';
import {GoogleTagManagerService} from '@services/marketing/google-tag-manager.service';
import {ZendeskService} from '@services/zendesk/zendesk.service';
import {AmplitudeService} from '@services/marketing/amplitude.service';
import {ConnectorV2Service} from '@services/connector/connector-v2.service';
import {isPlatformBrowser} from '@angular/common';
import {UiLoaderService} from '@services/ui-loader/ui-loader.service';
import {AppRedirectsService} from '@services/app-redirects/app-redirects.service';
import {skip} from 'rxjs/operators';
import {BrazeService} from '@services/marketing/braze/braze.service';
import {UserManagerService} from '@services/user/user-manager.service';
import {NotificationManagerService} from '@services/notification-manager/notification-manager.service';
import {UserAfterLoginActionsService} from '@services/user/user-after-login-actions.service';import {MatBottomSheet} from '@angular/material/bottom-sheet';
import {SentryService} from "@services/sentry/sentry.service";
import {MixpanelService} from "@services/mixpanel/mixpanel.service";
import {NotificationSoftRequestService} from "@components/notification-soft-request/notification-soft-request.service";
import {EncryptionService} from "@services/request-token/encryption.service";
import {getAccumulatedRouteData} from "@services/routing/routing-helper/routing-helper";
import {IRoutingData} from "@services/routing/routing-helper/routing.interface";
import {RouterEventsService} from "@services/routing/router-events/router-events.service";
import {SubscriptionRedirectService} from "@services/routing/subscription-redirect/subscription-redirect.service";

@Component({
  selector: 'alm-authorized-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements AfterViewInit {

  hideMainFooter: boolean = false;
  showSimplifiedHeader: boolean = true;
  userInfoSub: BehaviorSubject<IUserInfo | null>;
  unreadNotificationCount: number = 0;
  numberOfUnreadMessages: number = 0;
  onNavigationChange: boolean = false;
  loyaltyInfo?: ILoyaltyInfo = {
    loyal: false,
    inviteesCap: 0,
    prizeCap: 0,
    prizeCode: '',
    referralCode: '',
    referralCount: 0
  };
  showAppAnnouncement: boolean = false;

  constructor(private userBackService: UserBackService, private router: Router, private userManagerService: UserManagerService,
              private connectorService: ConnectorV2Service, private toasterService: ToastrService, private dialogService: DialogService,
              private gtmService: GoogleTagManagerService, private languageControl: LanguageControlService,
              private zendeskService: ZendeskService, private chatService: ChatService, private amplitudeService: AmplitudeService,
              private appRedirects: AppRedirectsService, private uiLoader: UiLoaderService, private brazeService: BrazeService,
              private notificationService: NotificationService, @Inject(PLATFORM_ID) private platformId: any,
              private notificationManagerService: NotificationManagerService, private afterLoginUserActions: UserAfterLoginActionsService,
              private sentryService: SentryService,
              private mixpanelService: MixpanelService,
              private notificationSoftRequestService: NotificationSoftRequestService,
              private encryptionService: EncryptionService,
              private routerEventsService: RouterEventsService,
              private subscriptionRedirectService: SubscriptionRedirectService
              ) {
    this.userInfoSub = this.userManagerService.userInfo;
    this.languageControl.setTranslations('ar', ar);
    this.languageControl.setTranslations('en', en);
  }

  ngAfterViewInit(): void {

    this.checkCourseViewerPresent();

    // if (environment.production) {
    //   this.userBackService.loadUBScript().then((userBack) => {
    //   }).catch((e) => {
    //     console.log(e);
    //   });
    // }

    this.userInfoSubscriber();

    this.notificationNumberUpdater();

    this.scriptsLoader();

    this.connectorInitializer();

    this.afterLoginUserActions.handleDemographicsPopup();

    this.routerEventsService.navigationStart.subscribe((navigationStartEvent: NavigationStart) => {
      this.subscriptionRedirectService.storeSubscriptionInitiatorUrl(navigationStartEvent);
    });
  }

  private userInfoSubscriber() {
    if (isPlatformBrowser(this.platformId)) {
      this.brazeService.initBraze();
      this.mixpanelService.initMixpanel(environment.mixpanel.b2cToken);
      this.notificationSoftRequestService.initWithDelay(300);
      this.userManagerService.userInfo.subscribe(user => {
        if (user) {
          this.getUnreadNotificationCount();
          this.getUnreadMessagesCount();
          // this.userService.isUserLoyal().then((loyal) => {
          //   this.loyaltyInfo = loyal;
          // }).catch()
          this.brazeService.setUserId(user.uuid);
          this.sentryService.setUser({
            id: user.uuid,
            email: user.email,
            username: user.name
          });
          this.mixpanelService.setUser(user);
          // GTM set hashed name and mail for DataHash to use them for PII
          this.gtmService.setGlobalProperties({
            th_capi_fn: this.encryptionService.normalizeAndHashString(user.name),
            th_capi_em: this.encryptionService.normalizeAndHashString(user.email),
          });
        }
      });
    }
  }

  private notificationNumberUpdater() {
    this.notificationService.onNotificationUpdate.subscribe((value) => {
      if (this.userManagerService.userInfo.getValue()) {
        this.getUnreadNotificationCount();
      }
    });

    this.chatService.onChatStatusUpdate.subscribe((value) => {
      if (this.userManagerService.userInfo.getValue()) {
        this.getUnreadMessagesCount();
      }
    });
  }

  private scriptsLoader() {
    this.gtmService.loadGtm(environment.gtmID);
    this.amplitudeService.loadAmplitude(environment.amplitudeApiKey, this.userInfoSub.getValue()?.uuid);
    this.zendeskService.loadZendeskWidget('ca70126b-03f9-45fb-8bb3-daead16e4adf');
  }

  private connectorInitializer() {
    this.connectorService.connectorLoaded.pipe(skip(1)).toPromise().then((loaded) => {
      this.connectorService.connectorUser.subscribe((user) => {
        if (!user && isPlatformBrowser(this.platformId)) {
          setTimeout(() => {
            window.location.replace(`${environment.platforms.anonymous.link}/home`);
          }, 500);
        }
      });
    });
  }

  checkCourseViewerPresent() {
    this.router.events.subscribe((event) => {
      if (event instanceof ChildActivationEnd) {
        const data = getAccumulatedRouteData(event);
        this.onNavigationDataChanged(data);
      }
    });
  }

  onNavigationDataChanged(data: IRoutingData) {
    this.onNavigationChange = true;
    this.hideMainFooter = data.hideMainFooter;
    this.showSimplifiedHeader = data.showSimplifiedHeader;
    if (this.hideMainFooter) {
      let support = document.getElementById('launcher');
      if (support) {
        support.style.display = 'none';
      }
    } else {
      let support = document.getElementById('launcher');
      if (support) {
        support.style.display = 'block';
      }
    }
    setTimeout(() => {
      this.onNavigationChange = false;
    }, 200);
    window.scroll(0, 0);
  }

  private getUnreadNotificationCount() {
    this.notificationService.getUnreadNotificationsCount('header-notification').then((unreadNotificationCount) => {
      this.unreadNotificationCount = unreadNotificationCount.count;
    });
  }

  private getUnreadMessagesCount() {
    this.chatService.getUnreadMsg('header-messages', true).then((unreadMessagesCount) => {
      this.numberOfUnreadMessages = unreadMessagesCount.count;
    });
  }
}
