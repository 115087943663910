import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'alm-root-footer-business',
  templateUrl: './footer-business.component.html',
  styleUrls: ['./footer-business.component.scss']
})
export class FooterBusinessComponent implements OnInit {

  @Input() isB2bUser?: boolean = false;

  constructor() { }

  ngOnInit(): void {
  }

}
